/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(2),
  },
}));

const NumberFormatCustom = ({ inputRef, onChange, ...other }) =>
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value
        }
      });
    }}
    isNumericString
    thousandSeparator
  />

const BlueTextInput = props => {
  const { className, onChange, normalize, numberMask, ...rest } = props;
  const classes = useStyles();

  const handleChange = e => {
    if (normalize) {
      return onChange(normalize(e.target.value))
    } else {
      return onChange(e.target.value)
    }
  }

  return (
    <StyledTextField
      className={clsx(classes.textField, className)}
      variant="outlined"
      onChange={handleChange}
      InputProps={numberMask && {
        inputComponent: NumberFormatCustom,
      }}
      {...rest}
    />
  );
};

BlueTextInput.propTypes = {
  className: PropTypes.string,
  normalize: PropTypes.func,
  numberMask: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 3px;
    background: #e5e9ff;
    color: #142bad !important;

    input: {
      color: #142bad !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #142bad;
      border-width: 2px;
    }

    & .Mui-focused fieldset: {
      border-color: #142bad;
    }
  }

  .MuiInputLabel-root {
    color: #142bad;
  }
`;

export default BlueTextInput;
