import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import {
  maintenanceSelector,
  officeLookupSelector,
} from 'data/selectors/global'
import { useGlobalActions } from 'hooks/useActions'

export const useMaintenance = () => {
  const timerRef = useRef()
  const globalActions = useGlobalActions([])
  const office = useSelector(officeLookupSelector)
  const maintenance = useSelector(maintenanceSelector)
  const showMaintenanceProgress =
  maintenance && maintenance.maintenanceInProgress.toLowerCase() === 'yes'
  const showMaintenanceWarning =
    !showMaintenanceProgress && maintenance &&
    maintenance.showWarningMessage.toLowerCase() === 'yes'

  const timeRemaining = maintenance && maintenance.startTime
    ? moment.utc(maintenance.startTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'minutes')
    : null

  console.log('render')
  const refreshMaintenanceData = () => {
    console.log('refresh')
    globalActions.fetchMaintenanceSchedule({ office })
  }

  useEffect(() => {
    window.addEventListener('focus', refreshMaintenanceData)

    return () => {
      window.removeEventListener('focus', refreshMaintenanceData)
    }
  })

  useEffect(() => {
    if (office && timeRemaining < 75 && !timerRef.current) {
      console.log('Maintenance < 75min out, polling...')
      timerRef.current = 'set'
      const interval = setInterval(async () => {
        console.log('tick')
        const { results } = await globalActions.fetchMaintenanceSchedule({
          office,
        })

        const timeTillEnd = results && results.endTime
          ? moment.utc(results.endTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'minutes')
          : null

        console.log('minutes remaining: ', timeTillEnd)

        if (timeTillEnd < 0) {
          console.log('Passed end of maintenance window.  Stopping polling.')
          clearInterval(interval)
          timerRef.current = null
        }
      }, 5000)

      // return () => {
      //   clearInterval(interval)
      // }
    }
  }, [office, timeRemaining])

  return { maintenance, showMaintenanceProgress, showMaintenanceWarning }
}
