import React, { useEffect } from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';

import { PrivateRouteWithLayout, RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout, NoBackground as NoBackgroundLayout } from './layouts';
import { useQuery } from 'hooks/useQuery';
import { useUserActions } from 'hooks/useActions';

import {
  Home as HomeView,
  MyPropertiesList as MyPropertiesListView,
  // PropertySearch as PropertySearchView,
  AppraisalNotices as AppraisalNoticesView,
  Applications as ApplicationsView,
  Protest as ProtestView,
  ProtestWalkthru as ProtestWalkthruView,
  AgentCreateProtest as AgentCreateProtestView,
  AgentMassCreateProtest as AgentMassCreateProtestView,
  AddEvidence as AddEvidenceView,
  CadEvidence as CadEvidenceView,
  CaseActivity as CaseActivityView,
  ReviewRecommendation as ReviewRecommendationView,
  ManualProtestSchedule as ManualProtestScheduleView,
  AccountMaintenance as AccountMaintenanceView,
  Renditions as RenditionsView,
  CadCommunication as CadCommunicationView,
  SignUp as SignUpView,
  SignIn as SignInView,
  ResetPassword as ResetPasswordView,
  NotFound as NotFoundView,
  Notification as NotificationView,
} from './views';

const Routes = () => {
  const query = useQuery();
  const userActions = useUserActions();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (query.has('key') && !window.location.href.includes('reset-password')) {
        const token = query.get('key');
        const email = query.get('email');
        await userActions.vaildatePublicUser(token);
        history.push(`/sign-in?validated=true&email=${email}`)
      }
    })()
  }, [query]);

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/home"
      />
      <PrivateRouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/home"
      />
      <PrivateRouteWithLayout
        component={MyPropertiesListView}
        exact
        layout={MainLayout}
        path="/my-properties"
      />
      {/* <PrivateRouteWithLayout
        component={PropertySearchView}
        exact
        layout={MainLayout}
        path="/property-search"
      /> */}
      <PrivateRouteWithLayout
        component={AppraisalNoticesView}
        exact
        layout={MainLayout}
        path="/appraisal-notices"
      />
      <PrivateRouteWithLayout
        component={ApplicationsView}
        exact
        layout={MainLayout}
        path="/applications"
      />
      <PrivateRouteWithLayout
        component={ProtestView}
        exact
        layout={MainLayout}
        path="/protest"
      />
      <PrivateRouteWithLayout
        component={ProtestWalkthruView}
        exact
        layout={MainLayout}
        path="/protest-walkthru/:propertyId?/:pin?"
      />
      <PrivateRouteWithLayout
        component={AgentCreateProtestView}
        exact
        layout={MainLayout}
        path="/my-properties-agent-protest"
      />
      <PrivateRouteWithLayout
        component={AgentMassCreateProtestView}
        exact
        layout={MainLayout}
        path="/efile-protest-agent"
      />
      <PrivateRouteWithLayout
        component={ManualProtestScheduleView}
        exact
        layout={MainLayout}
        path="/protest-manual-schedule/:appealId"
      />
      <PrivateRouteWithLayout
        component={AddEvidenceView}
        exact
        layout={MainLayout}
        path="/protest-add-evidence/:propertyId"
      />
      <PrivateRouteWithLayout
        component={CadEvidenceView}
        exact
        layout={MainLayout}
        path="/protest-cad-evidence"
      />
      <PrivateRouteWithLayout
        component={CaseActivityView}
        exact
        layout={MainLayout}
        path="/protest-case-activity/:propertyId/:appealId"
      />
      <PrivateRouteWithLayout
        component={ReviewRecommendationView}
        exact
        layout={MainLayout}
        path="/protest-review-recommendation"
      />
      <PrivateRouteWithLayout
        component={AccountMaintenanceView}
        exact
        layout={MainLayout}
        path="/account-maintenance"
      />
      <PrivateRouteWithLayout
        component={RenditionsView}
        exact
        layout={MainLayout}
        path="/renditions"
      />
      <PrivateRouteWithLayout
        component={CadCommunicationView}
        exact
        layout={MainLayout}
        path="/cad-communication"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={ResetPasswordView}
        exact
        layout={MinimalLayout}
        path="/reset-password"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={NoBackgroundLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={NotificationView}
        exact
        layout={NoBackgroundLayout}
        path="/notification"
      />
      <Redirect to="/sign-in" />
    </Switch>
  );
};

export default Routes;
