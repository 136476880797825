
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import validate from 'validate.js';
import { get } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';

import useCodefiles from 'hooks/useCodefiles';
import { FlexRow, RedAsterisk } from 'components/Styled/Common';
import { PropertyCard, BlueCheckboxGroup, PleaseWaitDialog, Alert } from 'components';
import { BasicDialog } from 'components/HoverContent';
import { StyledCard } from 'components/Styled/Common';
import BlueTextInput from 'components/TextInputs/BlueTextInput';
import RoundedButton from 'components/Buttons/RoundedButton';
import { onlyNumbersNormalizer } from 'lib/utils';

const schema = {
  value: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  reasons: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const CreateProtest = ({
  property,
  maxCommentLength,
  insertAppeal,
}) => {
  const [value, setValue] = useState('');
  const [comments, setComments] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorOpen, setErrorOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const getOptions = useCodefiles();

  useEffect(() => {
    const errors = validate({ value, reasons }, schema);
    setIsValid(errors ? false : true )
    setErrors(errors || {});
  }, [reasons, value]);

  const handleSubmit = async () => {
    setSaving(true);
    try {
      await insertAppeal({
        claimantOpinionOfValue: value,
        claimantComments: comments,
        appealReasons: reasons,
      })
    } catch (err) {
      setApiError(
        get(err, 'body.warning', 'There was an error submitting your request.')
      )
      setErrorOpen(true);
    }
    setSaving(false);
  }

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return
    setErrorOpen(false);
  };

  return (
    <WalkthruContent>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          {apiError}
        </Alert>
      </Snackbar>
      Enter below your reasons for protest of value,
      opinion of value and comment.
      <FlexRow style={{ margin: '20px 50px', width: '100%', alignItems: 'stretch' }}>
        <PropertyCard property={property} />
        <StyledCard
          lightBorder
          style={{ flex: 1, padding: '10px', marginLeft: 30, textAlign: 'left' }}
        >
          <em>
            Record your reasons for filing a protest on your property. <br/>
            You may select multiple reasons.
          </em><br/>
          <FlexRow 
            style={{ alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: 10 }}
          >
            <h4 style={{ marginRight: 10 }}>REASONS<RedAsterisk /></h4>
            <BlueCheckboxGroup
              options={getOptions('Reasons')}
              setValues={setReasons}
              values={reasons}
            />
          </FlexRow>
          <FlexRow style={{ justifyContent: 'flex-start' }}>
            What is your opinion of your property's value?<RedAsterisk />
            <BlueTextInput
              autoFocus
              numberMask
              value={value}
              onChange={setValue}
              normalize={onlyNumbersNormalizer}
              style={{ maxWidth: 150, margin: 10 }}
              inputProps={{ style: { fontSize: 20, padding: 12 } }}
              helperText={errors.value ? errors.value[0] : ''}
            />
          </FlexRow>
        </StyledCard>
      </FlexRow>
      <StyledCard
        lightBorder
        style={{ flex: 1, padding: '10px', marginTop: 0, textAlign: 'left' }}
      >
        <h4 style={{ width: '100%', textAlign: 'left' }}>
          COMMENTS (OPTIONAL)
        </h4>
        Please record any comments you have regarding your property’s value. 
        You will have the opportunity to attach documents and evidence in step 4.
        <BlueTextInput
          multiline
          maxRows={3}
          value={comments}
          onChange={value => {
            if (value.length === maxCommentLength) {
              return setAlertOpen(true);
            }
            setComments(value);
          }}
          style={{ width: '100%' }}
          inputProps={{ style: { fontSize: 16, padding: 2 } }}
        />
        <CharacterCounter>
          {comments.length} of {maxCommentLength} 
        </CharacterCounter>  
      </StyledCard>
      <SubmitContainer>
        <RoundedButton
          onClick={handleSubmit}
          color="info"
          style={{ width: '15%', margin: 0 }}
          disabled={saving || !isValid}
        >
          SUBMIT PROTEST
        </RoundedButton>
        <label>
          <em>
            Fields marked with an asterisk<RedAsterisk />{' '}
            are required to be answered.
          </em>
        </label>
      </SubmitContainer>
      <BasicDialog
        open={alertOpen}
        handleClose={() => setAlertOpen(false)}
        title="YOU REACHED YOUR CHARACTER LIMIT"
        message={
          'No worries, additional comments can be documented '+
          'and attached in step 4'
        }
        titleStyle={{ color: '#c64545' }}
      />

      <PleaseWaitDialog 
        message="We are creating your protest." 
        open={saving}
      />
    </WalkthruContent>
  )
}

CreateProtest.propTypes = {
  goToNext: PropTypes.func.isRequired,
  insertAppeal: PropTypes.func.isRequired,
  maxCommentLength: PropTypes.number,
  property: PropTypes.object,
}

const WalkthruContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  align-items: center;
`;

const CharacterCounter = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: 0.54;
  margin-top: 5px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  > label {
    position: absolute;
    top: 25%;
    right: 0;
    width: 38%;
  }
`;

export default CreateProtest;
