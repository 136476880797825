import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Badge from '@material-ui/core/Badge';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ScalesIcon from 'assets/images/Scales.svg';
import ContractIcon from 'assets/images/Contract.svg';
// import ScheduleIcon from 'assets/images/Schedule.svg';
import LedgerIcon from 'assets/images/Ledger.svg';
import SheetIcon from 'assets/images/Sheet.svg';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const ProtestActionMenu = ({
  data,
  history,
  withdrawAppeal,
  openRecommendation,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const badgeColor = theme.palette.success.main;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!data) return;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      { data.showNewRecommendationFlag === 'Yes' && 
        <div><StyledArrowIcon /></div>
      }
      <ActionMenuDropdown
        onClick={handleClick}
        enabled={Boolean(anchorEl)}
        aria-controls="action-menu"
      >
        <span>Action Menu...</span>
        <KeyboardArrowDownIcon style={{ color: 'black' }} />
      </ActionMenuDropdown>
      <StyledMenu
        id="action-menu"
        variant="menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        disableAutoFocusItem
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          disabled={
            data.protestState !== 'Not Filed' ||
            data.eFileEligible === 'No' ||
            data.pinStatus !== 'Valid PIN'
          }
          onClick={() => {
            let url = data.publicUserPropertyID || data.pID
              ? `/protest-walkthru/${data.publicUserPropertyID || data.pID}`
              : '/protest-walkthru'
            if (data.pin) {
              url = `${url}/${data.pin}`;
            }
            history.push(url);
          }}
        >
          <StyledListItemIcon>
            <StyledIcon
              alt="Protest Property"
              src={ScalesIcon} 
            />
          </StyledListItemIcon>
          <ListItemText primary="Protest Property" />
        </StyledMenuItem>
        {/* <StyledMenuItem
          disabled={
            data.canSchedule !== 'Yes' ||
            data.agentStatus !== 'Active'
          }
          onClick={() => {
            handleClose();
            setScheduleDialogOpen(data.appealID);
          }}
        >
          <StyledListItemIcon>
            <StyledIcon alt="Schedule Hearing" src={ScheduleIcon} />
          </StyledListItemIcon>
          <ListItemText primary="Schedule Hearing" />
        </StyledMenuItem> */}
        
        <StyledMenuItem
          disabled={
            data.protestState !== 'Protest Filed' ||
            data.pinStatus !== 'Valid PIN'
          }
          onClick={() =>
            history.push(`/protest-add-evidence/${data.publicUserPropertyID}`)
          }
        >
          <StyledListItemIcon>
            <StyledIcon alt="View My Evidence" src={ContractIcon} />
          </StyledListItemIcon>
          <ListItemText primary="View My Evidence" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={
            data.protestState === 'Protest Complete' ||
            data.pinStatus !== 'Valid PIN' || 
            data.hasRecommendation !== 'Yes'
          }
          onClick={openRecommendation}
        >
          <StyledBadge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeColor={badgeColor}
            badgeContent="NEW"
            invisible={data.showNewRecommendationFlag === 'No'}
          >
            <StyledListItemIcon>
              <StyledIcon alt="Review Recommendation" src={LedgerIcon} />
            </StyledListItemIcon>
          </StyledBadge>
          <ListItemText primary="Review Recommendation" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={data.presentationPublished !== 'Yes'}
          onClick={() => history.push(
            `/protest-cad-evidence?propertyId=${data.pID}&appealId=${data.appealID}`
          )}
        >
          <StyledListItemIcon>
            <StyledIcon alt="View CAD Evidence" src={SheetIcon} />
          </StyledListItemIcon>
          <ListItemText primary="View CAD Evidence" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={
            data.protestState !== 'Protest Filed' ||
            data.pinStatus !== 'Valid PIN'
          }
          onClick={() => {
            withdrawAppeal(data.appealID, data.pID);
            handleClose();
          }}
        >
          <StyledListItemIcon>
            <StyledIcon alt="Withdraw Protest" src={ScalesIcon} />
          </StyledListItemIcon>
          <ListItemText primary="Withdraw Protest" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={data.viewCaseActivity !== 'Yes'}
          onClick={() => 
            history.push(`/protest-case-activity/${data.pID}/${data.appealID}`)
          }
        >
          <StyledListItemIcon>
            <StyledIcon alt="Case Activity" src={SheetIcon} />
          </StyledListItemIcon>
          <ListItemText primary="Case Activity" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

ProtestActionMenu.propTypes = {
  appealsActions: PropTypes.object,
  data: PropTypes.object,
  history: PropTypes.object,
  openRecommendation: PropTypes.func.isRequired,
  setScheduleDialogOpen: PropTypes.func.isRequired,
  withdrawAppeal: PropTypes.func.isRequired,
};

const ActionMenuDropdown = styled.div`
  width: 200px;
  border: 1px solid #142bad;
  padding-left: 10px;
  background: #d4d9fc;
  color: #142bad;
  cursor: pointer;
  z-index: 51;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 45px;
`;

const StyledIcon = styled.img`
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.2s;

  :hover {
    transform: scale(1.5);
  }
`;

const StyledArrowIcon = styled(ArrowForwardIcon)`
  /* right bounce */
  @-webkit-keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -webkit-transform: translateX(-30px);
      transform: translateX(-30px);
    }
    60% {
      -webkit-transform: translateX(-15px);
      transform: translateX(-15px);
    }
  }
  @-moz-keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(-30px);
    }
    60% {
      transform: translateX(-15px);
    }
  }
  @keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -ms-transform: translateX(-30px);
      transform: translateX(-30px);
    }
    60% {
      -ms-transform: translateX(-15px);
      transform: translateX(-15px);
    }
  }
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
  float: right;
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-anchorOriginTopRightRectangle {
    right: 17px;
    top: 1px;
    background: ${({ badgeColor }) => badgeColor};
  }
`;

export default ProtestActionMenu;
