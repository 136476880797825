import React from 'react';
import PropTypes from 'prop-types';
import { FlexRow } from 'components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

const MassAcceptTopModal = ({
  open,
  pids,
  user,
  handleClose,
  massAcceptTop,
}) => {

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
        AGENT | {user.agentID} | {user.companyName}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FlexRow style={{ alignItems: 'stretch' }}>
          <PidContainer>
            <b>Selected PIDs:</b><br/>
            {pids.map(pid => <span key={pid}>{pid}</span>)}
          </PidContainer>
          <RightColumn>
            <h3>Do you wish to <b>ACCEPT WITH TOP LINE AGREEMENT</b> the District’s recommendation of value on the selected properties?</h3>
            <span>
            The Formal ARB Hearing to approve this Top Line Agreement will be scheduled
            for the next available docket designated for the Top Lines. It is possible that the 
            hearing may occur within the next fifteen (15) days. The CAD is required to notify 
            property owners of a hearing at least fourteen (14) days in advance, and make 
            certain information and evidence available.<br/><br/>

            By clicking below, you acknowledge that you are entitled to receive a hearing notice 
            and evidence at least fourteen (14) days in advance, and YOU AGREE TO WAIVE
            YOUR RIGHTS to receive a notice and evidence at least fourteen (14) days in 
            advance.
            </span>
          </RightColumn>
        </FlexRow>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
          style={{ marginRight: 20 }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={massAcceptTop}
        >
          Accept with top line agreement
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MassAcceptTopModal.propTypes = {
  appealsActions: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  massAcceptTop: PropTypes.func.isRequired,
  open: PropTypes.bool,
  pids: PropTypes.array,
  user: PropTypes.object,
}

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 10px;

  h3 {
    font-weight: normal;
  }

  > span {
    margin-top: 20px;
    line-height: 20px;
    color: black;
    opacity: 0.54;
  }
`;

const PidContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  max-height: 45vh;
  max-width: 20%;
  min-height: 400px;
  width: 100%;
  overflow: scroll;
`;

export default MassAcceptTopModal