import _ from 'lodash';
import * as actions from '../actions/appeals';
import { START, SUCCESS, ERROR } from '../actions/global';
import { fetchingState } from '../../constants';
// import { updateStateArrayItem, upsertStateArrayItem } from './utils';

const initialState = {
  appeals: { data: [], state: null },
  appealClaimant: { data: {}, state: null },
  appealRecommendations: { data: {}, state: null },
  agentSelectedProperties: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.INSERT_APPEAL[START]:
      return {
        ...state,
        appeals: {
          data: state.appeals.data,
          state: fetchingState.SAVING,
        }
      }
    case actions.INSERT_APPEAL[SUCCESS]:
      return {
        ...state,
        appeals: {
          data: [
            ...state.appeals.data,
            ..._.get(action, 'data.results', []),
          ],
          state: fetchingState.LOADED,
        }
      }
    
    case actions.UPDATE_CLAIMANT_FILE_DESCRIPTION[SUCCESS]:
    case actions.DELETE_CLAIMANT_FILE[SUCCESS]:
    case actions.INSERT_CLAIMANT_FILE[SUCCESS]:
    case actions.FETCH_APPEAL_CLAIMANT[SUCCESS]:
      return {
        ...state,
        appealClaimant: {
          data: _.get(action, 'data.results[0]'),
          state: fetchingState.LOADED,
        },
      }

    case actions.ACCEPT_TOPLINE[START]:
    case actions.REJECT_APPEAL_RECOMMENDATION[START]:
    case actions.ACCEPT_APPEAL_RECOMMENDATION[START]:
      return {
        ...state,
        appealRecommendations: {
          data: state.appealRecommendations.data,
          state: fetchingState.SAVING,
        }
      }
    case actions.ACCEPT_TOPLINE[SUCCESS]:
    case actions.REJECT_APPEAL_RECOMMENDATION[SUCCESS]:
    case actions.FETCH_APPEAL_RECOMMENDATIONS[SUCCESS]:
    case actions.ACCEPT_APPEAL_RECOMMENDATION[SUCCESS]:
      return {
        ...state,
        appealRecommendations: {
          data: _.get(action, 'data.results[0]'),
          state: fetchingState.LOADED,
        },
      }
    case actions.ACCEPT_TOPLINE[ERROR]:
    case actions.REJECT_APPEAL_RECOMMENDATION[ERROR]:
    case actions.FETCH_APPEAL_RECOMMENDATIONS[ERROR]:
    case actions.ACCEPT_APPEAL_RECOMMENDATION[ERROR]:
      return {
        ...state,
        appealRecommendations: {
          data: state.appealRecommendations.data,
          state: fetchingState.ERROR,
        },
      }
    
    case actions.AGENT_SELECT_PROPERTIES:
      return {
        ...state,
        agentSelectedProperties: action.payload,
      }

    default:
      return state;
  }
};
