import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as appealsActions from 'data/actions/appeals';
import PropertyCard from './PropertyCard';

class ItemRenderer extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    fetchAppealRecommendation: PropTypes.func.isRequired,
    history: PropTypes.object,
    index: PropTypes.number,
    setRecommendationPortalView: PropTypes.func.isRequired,
    style: PropTypes.object,
  }

  openRecommendation = async (recommendationId, publicUserPropertyId) => {
    await this.props.setRecommendationPortalView({ recommendationId });
    await this.props.fetchAppealRecommendation({
      recommendationId,
    });
    this.props.history.push(
      `/protest-review-recommendation?publicUserPropertyId=${publicUserPropertyId}`,
    );
  }

  render() {
    const item = this.props.data.content[this.props.index];
    return (
      <div style={{ ...this.props.style, paddingLeft: 5, paddingRight: 5 }}>
        <PropertyCard
          key={`${item.pID}-${item.appealID}`}
          index={this.props.index}
          property={item}
          history={this.props.history}
          openScheduleDialog={() => this.props.data.setScheduleDialogOpen(item.appealID)}
          openRecommendation={() => this.openRecommendation(
            item.recommendationID,
            item.publicUserPropertyID,
          )}
          onWithdraw={() => this.props.data.withdrawAppeal(item.appealID)}
        />
      </div>
    );
  }
}

const enhance = compose(
  withRouter,
  connect(
    null,
    {
      setRecommendationPortalView: appealsActions.setRecommendationPortalView,
      fetchAppealRecommendation: appealsActions.fetchAppealRecommendation,
    },
  ),
)

export default enhance(ItemRenderer);
