import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const BasicDialog = ({
  open,
  handleClose,
  title,
  titleStyle = {},
  message,
  buttons,
}) =>
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle disableTypography id="alert-dialog-title" style={titleStyle}>
      {title}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        style={{ position: 'absolute', right: 0, top: 0 }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {!buttons
        ? (
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        ) : (
          buttons.map(button => (
            <Button onClick={button.action} color="primary">
              {button.title}
            </Button>
          ))
        )}
    </DialogActions>
  </Dialog>

BasicDialog.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
}

export default BasicDialog