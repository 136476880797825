import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { Topbar } from './components';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 28,
    height: '100%'
  },
}));

const NoBackground = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <Main>
        <TopSection>
          <div>
            <Title>Public Portal</Title><br/>
            <SubTitle>For Owners and Agents</SubTitle>
          </div>
          <SectionTitle>
            Online Services
          </SectionTitle>
        </TopSection>
        {children}
      </Main>
    </div>
  );
};

NoBackground.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const Main = styled.main`
  position: relative;
  background: #272933;
  background-size: cover;
  min-height: 100%;
  backdrop-filter: blur(30px);
  color: white;
  position: relative;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 25%;
  padding: 3em 5em;
  text-transform: uppercase;
  z-index: 1;
`;

const Title = styled.span`
  font-size: 40px;
  font-weight: bold;
`;

const SubTitle = styled.span`
  font-size: 25px;
  opacity: 0.46;
`;

const SectionTitle = styled.span`
  font-size: 25px;
  opacity: 0.46;
`;


export default NoBackground;
