import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContentHeader = ({ title, children }) => (
  <Container>
    <Title>{title}</Title>
    {children}
  </Container>
);

ContentHeader.propTypes = {
  children: PropTypes.object,
  title: PropTypes.any,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  background: #eff3f8;
  height: 56px;
  padding: 17px 28px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: black;
`;

export default ContentHeader;
