import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Document, Page, pdfjs } from 'react-pdf';
import queryString from 'query-string';
import styled from 'styled-components';

import { ContentHeader, ContentSection } from 'components';
import { FlexRow } from 'components/Styled/Common';
import { useAppealsActions } from 'hooks/useActions'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CadEvidenceView = ({ history, location, isAgent }) => {
  const { propertyId, appealId } = queryString.parse(location.search);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState();
  const [docUrl, setDocUrl] = useState();
  const appealsActions = useAppealsActions([]);

  if (!propertyId || !appealId) close();

  const close = () => history.push(isAgent ? '/my-properties' : '/protest');

  useEffect(() => {
    (async () => {
      if (propertyId && appealId) {
        const pdfUrl = await appealsActions.downloadCadPresentation({
          appealId,
          displayInPage: true,
        });
        setDocUrl(pdfUrl);
      }
    })()
  }, [])

  return (
    <>
      <ContentHeader title={`PROPERTY ${propertyId} | CAD EVIDENCE`}>
        <div>
          <Tooltip title="Download CAD Evidence">
            <IconButton
              onClick={() => appealsActions.downloadCadPresentation({
                appealId,
                displayInPage: false,
              })}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              onClick={close}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ContentHeader>
      <ContentSection style={{ alignItems: 'center', height: 'calc(100vh - 90px)' }}>
        {docUrl && (
          <>
            <StyledDocument
              file={docUrl}
              onLoadSuccess={e => {
                console.log(e);
                setNumPages(e.numPages)
              }}
              onLoadError={console.log}
            >
              <Page pageNumber={page} />
            </StyledDocument>
            <FlexRow>
              <IconButton 
                onClick={() => setPage(prev => prev === 1 ? 1 : prev - 1)}
                style={{ marginRight: 20 }}
                disabled={page === 1}
              >
                <ChevronLeft />
              </IconButton>
              <p>Page {page} of {numPages}</p>
              <IconButton
                onClick={() => setPage(prev => prev === numPages ? prev : prev + 1)}
                style={{ marginLeft: 20 }}
                disabled={page === numPages}
              >
                <ChevronRight />
              </IconButton>
            </FlexRow>
          </>
        )}
      </ContentSection>
    </>
  );
};

CadEvidenceView.propTypes = {
  history: PropTypes.object,
  isAgent: PropTypes.bool,
  location: PropTypes.object,
}

const StyledDocument = styled(Document)`
  height: calc(100% - 50px) !important;

  .react-pdf__Page {
    height: 100%;

    canvas {
      width: unset !important;
      height: 100% !important;
    }
  }
`;

export default CadEvidenceView;
