import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  border-top-left-radius: 5px; 
  border-top-right-radius: 5px; 
  border-top: 4px solid #142bad;
  border-color: ${({ lightBorder }) => (lightBorder ? '#acbad3' : '#142bad')};
  margin: 20px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const RedAsterisk = () =>
  <span style={{ color: '#ff5f5d', marginBottom: 5 }}>*</span>