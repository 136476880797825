import React from 'react';
import PropTypes from 'prop-types';
import { FlexRow } from 'components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

const MassWithdrawModal = ({
  open,
  pids,
  user,
  handleClose,
  massWithdraw,
}) => {

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
        AGENT | {user.agentID} | {user.companyName}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FlexRow style={{ alignItems: 'stretch' }}>
          <PidContainer>
            <b>Selected PIDs:</b><br/>
            {pids.map(pid => <span key={pid}>{pid}</span>)}
          </PidContainer>
          <RightColumn>
            <h3>Are you sure you wish to withdraw the selected protests?</h3>
            <span>
              By clicking the withdrawal button, you acknowledge that you are 
              withdrawing your protest and waive all rights to any further proceedings 
              on the above-referenced property for the tax year in question, including 
              without limitation, all available administrative and legal remedies. Once 
              submitting this withdrawal you will not be able to file another protest on 
              this property for this year.
            </span>
          </RightColumn>
        </FlexRow>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
          style={{ marginRight: 20 }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={massWithdraw}
        >
          Withdraw selected protests
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MassWithdrawModal.propTypes = {
  appealsActions: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  massWithdraw: PropTypes.func.isRequired,
  open: PropTypes.bool,
  pids: PropTypes.array,
  user: PropTypes.object,
}

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 10px;

  > span {
    margin-top: 20px;
    line-height: 20px;
    color: black;
    opacity: 0.54;
  }
`;

const PidContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  max-height: 45vh;
  max-width: 20%;
  min-height: 400px;
  width: 100%;
  overflow: scroll;
`;

export default MassWithdrawModal