import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DownloadIcon from '@material-ui/icons/GetApp'
import Tooltip from '@material-ui/core/Tooltip'
import ChevronRight from '@material-ui/icons/ChevronRight'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { Document, Page, pdfjs } from 'react-pdf'
import { get } from 'lodash'

import AgGrid, { ActionCellRenderer } from 'components/AgGrid'
import { useAppealsActions } from 'hooks/useActions'
import { ContentHeader, ContentSection, FlexRow } from 'components'
import PageIcon from 'assets/images/PageIcon.svg'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const CaseActivity = ({ match }) => {
  const { appealId, propertyId } = match.params
  const [portalActivity, setPortalActivity] = useState([])
  const [viewAttachment, setViewAttachment] = useState(false)
  const [selectedAttachment, setSelectedAttachment] = useState()
  const [docUrl, setDocUrl] = useState()
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState()
  const [pageWidth, setPageWidth] = useState()
  const appealsActions = useAppealsActions([])
  const activityRef = useRef()
  const containerRef = useRef()
  activityRef.current = portalActivity

  useEffect(() => {
    appealsActions
      .getPortalActivity({ appealId })
      .then(({ results } = {}) => setPortalActivity(results))
  }, [])

  useEffect(() => {
    setPageWidth(containerRef.current ? containerRef.current.offsetWidth : 0)
  }, [containerRef.current])

  const downloadActivityFile = async (activityId, s3Id) => {
    const selection = activityRef.current.find(
      a => a.activityID === Number(activityId)
    )
    setSelectedAttachment(selection)
    const pdfUrl = await appealsActions.downloadPortalActivityFile({
      activityId,
      s3Id,
      displayInPage: true,
    })
    setDocUrl(pdfUrl)
    setViewAttachment(true)
  }

  const columnDefs = [
    {
      headerName: 'Date',
      field: 'activityDt',
      maxWidth: 180,
    },
    {
      headerName: 'Activity',
      field: 'activity',
    },
    {
      headerName: 'Attachment',
      field: '',
      maxWidth: 140,
      cellRendererFramework: ActionCellRenderer,
      cellRendererParams: {
        icons: [
          {
            icon: () => PageIcon,
            tooltip: 'Download Case Activity',
            isVisible: record => !!record.s3ID,
            onClick: (_, record) =>
              downloadActivityFile(record.activityID, record.s3ID),
          },
        ],
      },
    },
  ]

  return (
    <div ref={containerRef}>
      <ContentHeader
        title={
          viewAttachment ? (
            <span>
              ATTACHMENT | PID {propertyId} |{' '}
              {get(selectedAttachment, 'activityType')}
            </span>
          ) : (
            <span>
              CASE ACTIVITY | <Link to="#">PID {propertyId}</Link>
            </span>
          )
        }
      >
        {viewAttachment && (
          <div>
            <Tooltip title="Download Attachment">
              <IconButton
                onClick={() =>
                  appealsActions.downloadPortalActivityFile({
                    activityId: selectedAttachment.activityID,
                    s3Id: selectedAttachment.s3ID,
                    displayInPage: false,
                  })
                }
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                onClick={() => setViewAttachment(false)}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </ContentHeader>
      <ContentSection style={{ minHeight: 200 }}>
        {!viewAttachment ? (
          <AgGridWrapper className="ag-theme-custom">
            <AgGrid
              disablePreferenceSave
              autoResizeToFit
              columnDefs={columnDefs}
              name="userProperties"
              rowData={portalActivity}
              domLayout="autoHeight"
            />
          </AgGridWrapper>
        ) : (
          docUrl && (
            <>
              <StyledDocument
                file={docUrl}
                onLoadSuccess={e => {
                  console.log(e)
                  setNumPages(e.numPages)
                }}
                onLoadError={console.log}
              >
                <Page pageNumber={page} width={pageWidth - 30} />
              </StyledDocument>
              <FlexRow style={{ justifyContent: 'center' }}>
                <IconButton
                  onClick={() => setPage(prev => (prev === 1 ? 1 : prev - 1))}
                  style={{ marginRight: 20 }}
                  disabled={page === 1}
                >
                  <ChevronLeft />
                </IconButton>
                <p>
                  Page {page} of {numPages}
                </p>
                <IconButton
                  onClick={() =>
                    setPage(prev => (prev === numPages ? prev : prev + 1))
                  }
                  style={{ marginLeft: 20 }}
                  disabled={page === numPages}
                >
                  <ChevronRight />
                </IconButton>
              </FlexRow>
            </>
          )
        )}
      </ContentSection>
    </div>
  )
}

CaseActivity.propTypes = {
  match: PropTypes.object,
}

const AgGridWrapper = styled.div`
  margin-top: 10px;
  height: calc(100vh - 140px);
`

const StyledDocument = styled(Document)`
  height: calc(100% - 50px) !important;

  .react-pdf__Page {
    height: 100%;

    // canvas {
    //   width: 100% !important;
    //   height: unset !important;
    // }
  }
`

export default CaseActivity
