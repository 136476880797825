
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import validate from 'validate.js';

import useCodefiles from 'hooks/useCodefiles';
import { useAppealsActions, useGlobalActions } from 'hooks/useActions';
import { getSessionConfig } from 'data/selectors/global';
import { getAgentSelectedProperties } from 'data/selectors/appeals';
import { userSelector } from 'data/selectors/user';
import { FlexRow, RedAsterisk } from 'components/Styled/Common';
import { BlueCheckboxGroup, PleaseWaitDialog } from 'components';
import { BasicDialog } from 'components/HoverContent';
import { StyledCard } from 'components/Styled/Common';
import BlueTextInput from 'components/TextInputs/BlueTextInput';
import RoundedButton from 'components/Buttons/RoundedButton';

const schema = {
  reasons: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const AgentCreateProtest = ({ history }) => {
  const [comments, setComments] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const appealsActions = useAppealsActions([]);
  const globalActions = useGlobalActions([]);
  const getOptions = useCodefiles();
  const user = useSelector(userSelector)
  const config = useSelector(getSessionConfig);
  const agentSelectedProperties = useSelector(getAgentSelectedProperties);

  if (!agentSelectedProperties || !agentSelectedProperties.length) {
    history.push('/my-properties')
  }

  useEffect(() => {
    globalActions.fetchCodeFile({ type: 'Appeals', name: 'Reasons' })
  }, [])

  useEffect(() => {
    const errors = validate({ reasons }, schema);
    setIsValid(errors ? false : true )
    setErrors(errors || {});
  }, [reasons]);

  const handleSubmit = async () => {
    setSaving(true);
    await appealsActions.massCreateAgentProtest({
      agentId: user.agentID,
      payload: {
        pIDList: agentSelectedProperties.map(p => p.pID),
        reasonCodes: reasons,
        claimantComments: comments,
      },
    })
    setTimeout(() => history.push('/my-properties'), 5000);
    
  }

  return (
    <WalkthruContent>
      <div style={{ width: '100%', fontWeight: 'bold' }}>
        Properties Selected:{' '}
        <span style={{ color: '#142bad' }}>
          {agentSelectedProperties && agentSelectedProperties.length}
        </span>
      </div>
      <FlexRow style={{ margin: '20px', width: '100%', alignItems: 'stretch' }}>
        <StyledCard
          lightBorder
          style={{ flex: 1, padding: '10px', margin: 0, marginRight: 30, textAlign: 'left' }}
        >
          <em>
            Record your reasons for filing a protest on your property. <br/>
            You may select multiple reasons.
          </em><br/>
          <FlexRow 
            style={{ alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: 10 }}
          >
            <h4 style={{ marginRight: 10 }}>REASONS<RedAsterisk /></h4>
            <BlueCheckboxGroup
              options={getOptions('Reasons')}
              setValues={setReasons}
              values={reasons}
            />
          </FlexRow>
        </StyledCard>
        <StyledCard
          lightBorder
          style={{ flex: 1, padding: '10px', margin: 0, textAlign: 'left' }}
        >
          <h4 style={{ width: '100%', textAlign: 'left' }}>
            COMMENTS (OPTIONAL)
          </h4>
          Please record any comments you have regarding your property’s value.
          You will have the opportunity to attach documents and evidence.
          <BlueTextInput
            multiline
            maxRows={4}
            rows={4}
            value={comments}
            onChange={value => {
              if (value.length === config.maxCommentLength) {
                return setAlertOpen(true);
              }
              setComments(value);
            }}
            style={{ width: '100%' }}
            inputProps={{ style: { fontSize: 16, padding: 2 } }}
          />
          <CharacterCounter>
            {comments.length} of {config.maxCommentLength} 
          </CharacterCounter>  
        </StyledCard>
      </FlexRow>
      <SubmitContainer>
        <RoundedButton
          onClick={handleSubmit}
          color="info"
          style={{ width: '15%', margin: 0 }}
          disabled={saving || !isValid}
        >
          SUBMIT PROTEST
        </RoundedButton>
      </SubmitContainer>
      <BasicDialog
        open={alertOpen}
        handleClose={() => setAlertOpen(false)}
        title="YOU REACHED YOUR CHARACTER LIMIT"
        message={
          'No worries, additional comments can be documented '+
          'and attached later'
        }
        titleStyle={{ color: '#c64545' }}
      />

      <PleaseWaitDialog 
        message={
          'Protests are being created. This could take several minutes. ' +
          'You can see the status of the protest in your My Properties list.'
        } 
        open={saving}
      />
    </WalkthruContent>
  )
}

AgentCreateProtest.propTypes = {
  history: PropTypes.object,
}

const WalkthruContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  align-items: center;
`;

const CharacterCounter = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: 0.54;
  margin-top: 5px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  > label {
    position: absolute;
    top: 25%;
    right: 0;
    width: 38%;
  }
`;

export default AgentCreateProtest;
