import * as actions from '../actions/user';
import { SUCCESS } from '../actions/global';

const initialState = {
  user: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN[SUCCESS]:
      return {
        ...state,
        user: action.data.user,
      };

    case actions.LOGOUT[SUCCESS]:
      return {
        ...state,
        user: {},
      };

    default:
      return state;
  }
};
