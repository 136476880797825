
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledCard, FlexRow } from 'components/Styled/Common';
import GreenCheck from 'assets/images/GreenCheck.svg';
import RoundedButton from 'components/Buttons/RoundedButton';

const ProtestFiled = ({ addEvidenceLater, addEvidenceNow, appealSummary }) => (
  <WalkthruContent>
    <StyledCard lightBorder style={{ minWidth: 500, width: 'unset', padding: 10 }}>
      <h4 style={{ width: '100%', textAlign: 'left' }}>
        YOUR PROTEST HAS BEEN FILED.
      </h4>
      <FlexRow style={{ alignItems: 'center' }}>
        <div style={{ padding: 30 }}>
          <img src={GreenCheck} alt="green-check" style={{ width: 100 }} />
        </div>
        <Column>
          {appealSummary && (
            <>
              <div>
                <label>Protest ID:</label>
                <span>{appealSummary.appealID}</span>
              </div>
              <div>
                <label>Property ID:</label>
                <span>{appealSummary.pID}</span>
              </div>
              <div>
                <label>Owner Name:</label>
                <span>{appealSummary.propertyOwner}</span>
              </div>
              <div>
                <label>Property Address:</label>
                <span>{appealSummary.situsAddress}</span>
              </div>
            </>
          )}
        </Column>
      </FlexRow>
    </StyledCard>

    <div style={{ maxWidth: 800 }}>
      You will be able to make changes to this protest record up until the 
      date of the hearing, however, it is best to record your comments 
      and upload your evidence as soon as possible to allow for our appraisers
      to review this information.<br/><br/>
      Do you wish to continue to upload evidence now or later?
    </div>
    <FlexRow>
      <RoundedButton
        onClick={addEvidenceNow}
        color="info"
        style={{ width: 200, marginRight: 10 }}
      >
        ADD EVIDENCE NOW
      </RoundedButton>
      <RoundedButton
        onClick={addEvidenceLater}
        color="info"
        style={{ width: 200 }}
      >
        ADD EVIDENCE LATER
      </RoundedButton>
    </FlexRow>
  </WalkthruContent>
)

ProtestFiled.propTypes = {
  addEvidenceLater: PropTypes.func.isRequired,
  addEvidenceNow: PropTypes.func.isRequired,
  appealSummary: PropTypes.object,
}

const WalkthruContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > div {
    display: inline-flex;
    margin-bottom: 5px;

    > label {
      min-width: 150px;
      text-align: left;
    }
    > span {
      color: #142bad;
    }
  }
`;

export default ProtestFiled;
