import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';

import { 
  usePropertiesActions, 
  useGlobalActions,
  useAppealsActions,
} from 'hooks/useActions';
import { getProperties } from 'data/selectors/properties';
import { getProtestStatusList, getAgentStatusList } from 'data/selectors/global';
import { ContentHeader, ContentSection, Alert, PleaseWaitDialog } from 'components';
import UserContent from './UserContent';
import AgentContent from './AgentContent';
import { fetchingState } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const MyPropertiesList = ({ history, isAgent, user }) => {
  const classes = useStyles()
  const [errorOpen, setErrorOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const properties = useSelector(getProperties);
  const protestStatuses = useSelector(getProtestStatusList);
  const agentStatuses = useSelector(getAgentStatusList);
  const propertiesActions = usePropertiesActions([]);
  const globalActions = useGlobalActions([]);
  const appealsActions = useAppealsActions([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (isAgent) {
      propertiesActions.getAgentProperties({ agentId: user.agentID });
      if (!protestStatuses) {
        globalActions.fetchProtestStatusList();
      }
      if (!agentStatuses) {
        globalActions.fetchAgentStatusList();
      }
    } else {
      propertiesActions.getUserProperties({ userId: user.id })
    }
  }

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return
    setErrorOpen(false);
  };

  const refreshAgentProperties = params =>
    propertiesActions.getAgentProperties({ agentId: user.agentID, ...params })

  return (
    <div className={classes.root}>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          {apiError}
        </Alert>
      </Snackbar>
      {properties.state === fetchingState.FETCHING &&
        <PleaseWaitDialog 
          open
          message="We are retrieving your properties." 
        />
      }
      <ContentHeader title="MY PROPERTIES">
        <Tooltip title="Refresh">
          <IconButton onClick={fetchData}><RefreshIcon /></IconButton>
        </Tooltip>
      </ContentHeader>
      <ContentSection style={{ minHeight: 200 }}>
        {isAgent
          ? (
            <AgentContent
              properties={properties}
              propertiesActions={propertiesActions}
              appealsActions={appealsActions}
              protestStatuses={protestStatuses}
              agentStatuses={agentStatuses}
              refreshAgentProperties={refreshAgentProperties}
              history={history}
              user={user}
            />
          ) : (
            <UserContent
              properties={properties}
              setApiError={setApiError}
              propertiesActions={propertiesActions}
              user={user}
              setErrorOpen={setErrorOpen}
            />
          )}
      </ContentSection>
      
    </div>
  );
};

MyPropertiesList.propTypes = {
  history: PropTypes.object,
  isAgent: PropTypes.bool,
  user: PropTypes.object,
}

export const AgGridWrapper = styled.div`
  margin-top: 10px;
  height: ${({ fixedHeight }) => fixedHeight ? 'calc(100vh - 240px)' : '100%'};
`;

export default MyPropertiesList;
