import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { PrivateRoute } from '../PrivateRoute';
import { userSelector } from 'data/selectors/user';

const PrivateRouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  const user = useSelector(userSelector);
  const isAgent = user && user.agentID !== 0;

  return (
    <PrivateRoute
      {...rest}
      component={matchProps => (
        <Layout>
          <Component {...matchProps} user={user} isAgent={isAgent} />
        </Layout>
      )}
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default PrivateRouteWithLayout;
