
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import AgGrid from 'components/AgGrid';
// import { userSelector } from 'data/selectors/user';
import { usePropertiesActions } from 'hooks/useActions';
import {
  getAgentPropertySearch,
  getPropertySearchConfig,
} from 'data/selectors/properties';
import { ContentHeader, PidImportModal } from 'components';
import { FlexRow } from 'components/Styled/Common';
import { getUsdFormat } from 'lib/utils';

const PropertySearch = () => {
  const [loading, setLoading] = useState(false);
  const [searchValues, setSearchValues] = useState({
    name: '',
    situs: '',
    dba: '',
  });
  const [lastSearchValues, setLastSearchValues] = useState();
  const [propId, setPropId] = useState('');
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [propIds, setPropIds] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    page: 1,
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
  });
  const propertiesActions = usePropertiesActions([]);
  const properties = useSelector(getAgentPropertySearch);
  const propSearchConfig = useSelector(getPropertySearchConfig);
  const agGridApi = useRef();

  useEffect(() => {
    if (!propSearchConfig.state) {
      propertiesActions.fetchPropertySearchConfig();
    }
  }, []);

  const columnDefs = [
    {
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 80,
    },
    ...(
      propSearchConfig && 
      propSearchConfig.data &&
      propSearchConfig.data.length &&
      propSearchConfig.data.map(col => ({
        headerName: col.displayName,
        field: col.id,
        valueFormatter: col.needFormatting && col.type === 'numeric'
          ? ({ value }) => getUsdFormat(value, 0)
          : null,
      })) || []
    ),
  ];

  const getSortString = () => {
    const sortModel = agGridApi.current.getSortModel();
    if (!sortModel || !sortModel.length) return ['name desc'];
    return [`${sortModel[0].colId} ${sortModel[0].sort}`];
  }

  const performSearch = async ({ page, rowsPerPage, useLast } = {}) => {
    setLoading(true);
    try {
      await propertiesActions.searchProperties({
        ...(useLast ? lastSearchValues : searchValues),
        page: page || pagination.page,
        pageSize: rowsPerPage || pagination.rowsPerPage,
        sortFields: getSortString(),
        pIDList: propIds.map(pid => Number(pid)),
      });
    } catch(err) {
      console.log(err)
    }
    setLoading(false);
  }

  const onChangePage = page => {
    const nextPage = page + 1;
    setPagination({ ...pagination, page: nextPage });
    setSearchValues(lastSearchValues);
    performSearch({ page: nextPage, useLast: true });
  };

  const onChangeRowsPerPage = rows => {
    setPagination({ ...pagination, rowsPerPage: rows });
    setSearchValues(lastSearchValues);
    performSearch({ rowsPerPage: rows, useLast: true });
  }
  
  return (
    <div>
      <PidImportModal
        open={importModalOpen}
        handleClose={(pids = []) => {
          setPropIds([...new Set([...propIds, ...pids])]);
          setImportModalOpen(false);
        }}
      />
      <ContentHeader title="PROPERTY SEARCH">
        {loading ? <CircularProgress /> : null}
      </ContentHeader>
      <Layout>
        <div>
          <Button
            variant="contained" 
            color="primary"
            onClick={() => {
              performSearch({ page: 1 });
              setPagination({ ...pagination, page: 1 })
              setLastSearchValues(searchValues);
            }}
          >
            Search
          </Button>
          <TextField
            autoFocus
            variant="outlined"
            label="Owner"
            placeholder="Owner"
            value={searchValues.name}
            onChange={v => setSearchValues({ ...searchValues, name: v.target.value })}
            style={{ marginTop: 15, marginBottom: 10 }}
          />
          <TextField
            variant="outlined"
            label="Situs"
            placeholder="Situs"
            value={searchValues.situs}
            onChange={v => setSearchValues({ ...searchValues, situs: v.target.value })}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <TextField
            variant="outlined"
            label="DBA"
            placeholder="DBA"
            value={searchValues.dba}
            onChange={v => setSearchValues({ ...searchValues, dba: v.target.value })}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <ImportContainer>
            <FlexRow>
              <TextField
                label="Prop ID"
                placeholder="Enter Prop ID"
                value={propId}
                onChange={v => setPropId(v.target.value)}
                style={{ margin: 10 }}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    setPropIds([...new Set([...propIds, propId])]);
                    setPropId('');
                  }
                }}
              />
              <Button color="primary" onClick={() => setImportModalOpen(true)}>
                Import
              </Button>
            </FlexRow>
            <StyledCard>
              <CardContent style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
                {propIds && propIds.length ? propIds.join(', ') : null}
              </CardContent>
              <CardActions>
                Count:
                <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
                  {propIds.length} Properties
                </span>
              </CardActions>
            </StyledCard>
          </ImportContainer>
        </div>
        <div>
          <AgGridWrapper className="ag-theme-custom">
            <AgGrid
              disablePreferenceSave
              autoResizeToFit
              rowSelection="multiple"
              columnDefs={columnDefs}
              defaultColDef={{ resizable: true, sortable: true }}
              name="userProperties"
              rowData={properties.data}
              onSortChanged={performSearch}
              onGridReady={params => (agGridApi.current = params.api)}
              // onSelectionChanged={onSelectionChanged}
            />
          </AgGridWrapper>
          <TablePagination
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            component="div"
            count={properties.count}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            page={pagination.page - 1}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
            onChangePage={(event, page) => onChangePage(page)}
            onChangeRowsPerPage={event => onChangeRowsPerPage(event.target.value)}
          />
        </div>
      </Layout>
    </div>
  );
};

const Layout = styled.div`
  display: flex;
  height: 100%;
  padding: 10px;

  > div:first-child {
    width: 275px;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
  }

  > div:nth-child(2) {
    flex: 1;
  }
`;

const ImportContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: white;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AgGridWrapper = styled.div`
  height: calc(100vh - 160px);
`;

export default PropertySearch;
