import _ from 'lodash';
import base64 from 'base-64';
import { post, get, put, globalPost } from './request';

export const login = async (api, { username, password, office }) => {
  try {
    const { body } = await post(
      `Basic ${base64.encode(`${username}:${password}`)}`,
      `${api}/trueprodigy/auth/token`,
      { office },
    );
    return body;
  } catch (error) {
    /* eslint-disable no-throw-literal */
    if (error.body.httpStatus === 401) {
      throw 'incorrect';
    }
    throw error;
  }
}

export const getToken = async (api, { office }) => {
  const { body } = await post(
    `Basic ${base64.encode('publicPortal:*MirsWtCq%cr@!1')}`,
    `${api}/trueprodigy/publicportal/auth/token`, 
    { office },
  );
  return _.get(body, 'user.token');
}

export const createPublicUser = async (api, token, payload) => {
  const { body } = await post(
    token,
    `${api}/trueprodigy/users/public`,
    payload,
  );
  return body;
}

export const fetchLegalStatement = async (api, token) => {
  const { body } = await get(token, `${api}/public/config/legal`);
  return body;
}

export const validatePublicUser = async (api, token) => {
  const { body } = await put(token, `${api}/trueprodigy/users/public/validate`);
  return body;
}

export const sendPasswordReset = async (api, payload) => {
  const { body } = await globalPost(
    `${api}/trueprodigy/user/resetpassword`,
    payload,
  );
  return body;
}

export const setPassword = async (api, token, password) => {
  const { body } = await put(
    token,
    `${api}/trueprodigy/user/setpassword`,
    { newPassword: password }
  );
  return body;
}

export const fetchAgentInfo = async (api, token, agentId) => {
  const { body } = await get(token, `${api}/public/agent/${agentId}`);
  return body;
}