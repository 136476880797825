import { get } from 'lodash';

export const getUserProperties = state => get(state, ['properties', 'userProperties']);

export const getUserPropertyHistory = state =>
  get(state, ['properties', 'userPropertyHistory']);

export const getAgentProperties = state => get(state, ['properties', 'agentProperties']);

export const getAgentPropertySearch = state =>
  get(state, ['properties', 'agentPropertySearch']);

export const getPropertySearchConfig = state =>
  get(state, ['properties', 'propertySearchConfig']);

export const getAgentPropertyHistory = state =>
  get(state, ['properties', 'agentPropertyHistory']);

export const getProperties = state =>
  get(state, ['user', 'user', 'agentID']) === 0
    ? get(state, ['properties', 'userProperties'])
    : get(state, ['properties', 'agentProperties'])

export const getPropertyHistory = state =>
  get(state, ['user', 'user', 'agentID']) === 0
    ? get(state, ['properties', 'userPropertyHistory'])
    : get(state, ['properties', 'agentPropertyHistory'])