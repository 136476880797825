
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  getProperties,
  getPropertyHistory,
} from 'data/selectors/properties';
import { ContentHeader } from 'components';
import { usePropertiesActions } from 'hooks/useActions'
import AddEvidence from '../ProtestWalkthru/AddEvidence';

const AddEvidenceView = ({ match, history, isAgent }) => {
  const { propertyId } = match.params;
  const propertyActions = usePropertiesActions([]);
  const properties = useSelector(getProperties);
  const propertyHistory = useSelector(getPropertyHistory);

  const property = properties && properties.data.find(p => isAgent
    ?  p.pID === Number(propertyId)
    :  p.publicUserPropertyID === Number(propertyId)
  );

  if (!property) history.push(isAgent ? '/my-properties' : '/protest');

  useEffect(() => {
    if (isAgent) {
      propertyActions.getAgentPropertyHistory({ propertyId });
    } else {
      propertyActions.getUserPropertyHistory({ publicUserPropertyId: propertyId });
    }
  }, [])

  return (
    <>
      <ContentHeader title="UPLOAD EVIDENCE" />
      {property && (
        <AddEvidence
          appealId={property.appealID}
          property={propertyHistory.data}
          isAgent={isAgent}
        />
      )}
    </>
  );
};

AddEvidenceView.propTypes = {
  history: PropTypes.object,
  isAgent: PropTypes.bool,
  match: PropTypes.object,
}

export default AddEvidenceView;
