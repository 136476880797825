import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import RunIcon from 'assets/images/running-mass-action.svg';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const ActionMenu = ({
  selectedProperties,
  history,
  appealsActions,
  openSubmitAoaModal,
  openRevokeAoaModal,
  openMassWithdrawModal,
  openMassAcceptModal,
  openMassAcceptTopModal,
  openMassRejectModal,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Mass Actions">
        <IconButton
          enabled={Boolean(anchorEl)}
          onClick={handleClick}
        >
          <StyledCogsIcon src={RunIcon} />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          disabled={
            !selectedProperties
              .filter(p => p.protestState === 'Not Filed')
              .length
          }
          onClick={() => {
            appealsActions.agentSelectProperties(
              selectedProperties.filter(p => p.protestState === 'Not Filed')
            );
            history.push('/my-properties-agent-protest')
            handleClose();
          }}
        >
          <ListItemText primary="File Protest(s)" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={selectedProperties.length === 0 || !selectedProperties.reduce((ac, cu) => ac && cu.protestState === 'Protest Filed', true)}
          onClick={() => {
            openMassWithdrawModal();
            handleClose();
          }}
        >
          <ListItemText primary="Withdraw Protest(s)" />
        </StyledMenuItem>
        
        <StyledMenuItem
          disabled
          onClick={() => {
            handleClose();
          }}
        >
          <ListItemText primary="Upload/Review Evidence" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={selectedProperties.length === 0 || !selectedProperties.reduce((ac, cu) => ac && cu.canProcessRecommendation === 'Yes', true)}
          onClick={() => {
            openMassAcceptModal();
            handleClose();
          }}
        >
          <ListItemText primary="Accept Recommendation(s)" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={selectedProperties.length === 0 || !selectedProperties.reduce((ac, cu) => ac && cu.canProcessRecommendation === 'Yes' && cu.topLineEligible  === 'Yes', true)}
          onClick={() => {
            openMassAcceptTopModal();
            handleClose();
          }}
        >
          <ListItemText primary="Accept with Top Line Agreement" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={selectedProperties.length === 0 || !selectedProperties.reduce((ac, cu) => ac && cu.canProcessRecommendation === 'Yes', true)}
          onClick={() => {
            openMassRejectModal();
            handleClose();
          }}
        >
          <ListItemText primary="Reject Recommendation(s)" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!selectedProperties.length}
          onClick={() => {
            openSubmitAoaModal();
            handleClose();
          }}
        >
          <ListItemText primary="Submit AOA(s)" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!selectedProperties.length}
          onClick={() => {
            openRevokeAoaModal();
            handleClose();
          }}
        >
          <ListItemText primary="Revoke AOA(s)" />
        </StyledMenuItem>

      </StyledMenu>
    </div>
  );
};

ActionMenu.propTypes = {
  appealsActions: PropTypes.object,
  history: PropTypes.object,
  openMassAcceptModal: PropTypes.func.isRequired,
  openMassAcceptTopModal: PropTypes.func.isRequired,
  openMassRejectModal: PropTypes.func.isRequired,
  openMassWithdrawModal: PropTypes.func.isRequired,
  openRevokeAoaModal: PropTypes.func.isRequired,
  openSubmitAoaModal: PropTypes.func.isRequired,
  selectedProperties: PropTypes.array,
};

const StyledCogsIcon = styled.img`
  color: ${({ enabled }) => (enabled ? '#ceeb14' : '#fff')};
  cursor: pointer;
  z-index: 51;

  :hover {
    color: #ceeb14;
  }
`;

export default ActionMenu;
