
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';

import { userSelector } from 'data/selectors/user';
import {
  usePropertiesActions,
  useGlobalActions,
  useAppealsActions,
} from 'hooks/useActions';
import { getPropertyHistory } from 'data/selectors/properties';
import { getSessionConfig } from 'data/selectors/global';
import { ContentHeader } from 'components';
import { WalkthruHeader } from './components';
import EnterPropertyPin from './EnterPropertyPin';
import CreateProtest from './CreateProtest';
import ProtestFiled from './ProtestFiled';
import AddEvidence from './AddEvidence';
import { Alert } from 'components';

const steps = [
  'Enter E-FILE PIN',
  'Create a Protest',
  'Protest Filed',
  'Add Evidence (optional)',
];

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ProtestWalkthru = ({ match, history }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [appealSummary, setAppealSummary] = useState();
  const [error, setError] = useState();
  const [errorOpen, setErrorOpen] = useState(false);
  const propertyActions = usePropertiesActions([]);
  const globalActions = useGlobalActions([]);
  const appealsActions = useAppealsActions([]);
  const user = useSelector(userSelector)
  const property = useSelector(getPropertyHistory);
  const config = useSelector(getSessionConfig);
  const propertyRef = useRef();
  const { propertyId, pin } = match.params;
  const isAgent = user.agentID !== 0;
  propertyRef.current = property;

  useEffect(() => {
    if (propertyId) {
      if (isAgent) {
        propertyActions.getAgentPropertyHistory({ propertyId })
      } else {
        propertyActions.getUserPropertyHistory({ publicUserPropertyId: propertyId })
      }
    }
    globalActions.fetchCodeFile({ type: 'Appeals', name: 'Reasons' })
  }, []);

  const insertProperty = (pID, pin) => 
    propertyActions.insertUserProperty({
      userId: user.id,
      payload: { pID, pin },
    })
      .then(resp => propertyActions.getUserPropertyHistory({
        publicUserPropertyId: resp.results[0].publicUserPropertyID,
      }))
      .then(() => setActiveStep(1))
      .catch(error => {
        setError(error.body.warning);
        setErrorOpen(true);
      })

  const updatePin = pin =>
    propertyActions.updateUserPropertyPin({
      publicUserPropertyId: propertyId,
      payload: { pin }
    })
      .then(resp => propertyActions.getUserPropertyHistory({
        publicUserPropertyId: resp.results[0].publicUserPropertyID,
      }))
      .then(() => setActiveStep(1))
      .catch(error => {
        setError(error.body.warning);
        setErrorOpen(true);
      })

  const insertAppeal = ({
    claimantOpinionOfValue,
    claimantComments,
    appealReasons,
  }) => appealsActions.insertAppeal({
    payload: {
      pID: propertyRef.current.data.pID,
      pYear: propertyRef.current.data.pYear,
      appealType: config.newAppealType,
      appealStatus: config.newAppealStatus,
      appealedByID: isAgent
        ? user.agentID
        : propertyRef.current.data.ownerID,
      appealedByType: isAgent ? 'A' : 'O',
      claimantOpinionOfValue,
      claimantComments,
      appealReasons,
    },
  }).then(resp => {
    setActiveStep(2);
    setAppealSummary(resp.results[0])
  })

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return
    setErrorOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <ContentHeader title="PROTEST WALKTHRU" />
      <WalkthruHeader 
        steps={steps}
        activeStep={activeStep}
      />
      {activeStep === 0 && (
        <EnterPropertyPin
          propertyId={propertyId}
          pin={pin}
          property={property.data}
          insertProperty={insertProperty}
          updatePin={updatePin}
          goToNext={() => setActiveStep(1)}
        />
      )}
      {activeStep === 1 && (
        <CreateProtest
          property={property.data}
          goToNext={() => setActiveStep(2)}
          maxCommentLength={config.maxCommentLength}
          insertAppeal={insertAppeal}
        />
      )}
      {activeStep === 2 && (
        <ProtestFiled
          appealSummary={appealSummary}
          addEvidenceNow={() => setActiveStep(3)}
          addEvidenceLater={() => history.push('/protest')}
        />
      )}
      {activeStep === 3 && (
        <AddEvidence
          property={property.data}
          appealId={appealSummary.appealID}
        />
      )}
    </div>
  );
};

ProtestWalkthru.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
}

export default ProtestWalkthru;
