import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import MuiButton from '@material-ui/core/Button';

const Button = withStyles(theme => ({
  root: ({ color = 'primary' }) => ({
    margin: theme.spacing(2, 0),
    background: theme.palette[color].main,
    color: theme.palette[color].contrastText,
    borderRadius: 50,
    '&:hover': {
      background: theme.palette[color].main,
    }
  })
}))(MuiButton);

const RoundedButton = ({ className, color, ...other }) => 
  <Button
    className={clsx(className)}
    variant="contained"
    color={color}
    {...other}
  />

RoundedButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default RoundedButton;
