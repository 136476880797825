export function getUsdFormat(number, fractionDigits) {
  const minimumFractionDigits = fractionDigits === null ? 2 : fractionDigits;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
  });
  return formatter.format(number);
}

export function getCommaFormatted(number, options = {}) {
  if (number === null || number === undefined) return '';
  try {
    if (isNaN(Number(number))) return number;
    if (typeof number === 'string') {
      return Number(number).toLocaleString('en', options);
    }
    if (typeof number === 'number') {
      return number.toLocaleString('en', options);
    }
  } catch (err) {
    console.log(err, 'invalid number: ', number);
    return number;
  }
}

export function getPercentFormat(number, fractionDigits = 2) {
  if (!number) return '';
  if (typeof number !== 'string' && typeof number !== 'number') {
    return new Error('Invalid number');
  }
  const num = typeof number === 'string' ? Number(number) : number;
  return `${parseFloat(Math.round(num * 100) / 100).toFixed(fractionDigits)}%`;
}

export function onlyNumbersNormalizer(value) {
  const onlyNums = value.replace(/[^0-9$.,]/g, '');
  return onlyNums;
}

export function yearNormalizer(value) {
  const onlyNums = value.replace(/[^0-9$]/g, '');
  return onlyNums.substring(0, 4);
}

export function percentNormalizer(value) {
  const onlyNums = value.replace(/[^0-9$.]/g, '');
  if (Number(onlyNums) <= 100) return onlyNums;
  return onlyNums.substring(0, 2);
}

export function getUrlBase() {
  const urlRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^\/\n?]+)/img;
  const url = urlRegex.exec(window.location.href)[0];
  return url;
}

export function isImage(filename) {
  if (!filename || typeof filename !== 'string' || filename.length < 4) {
    throw new Error('Invalid filename')
  }
  const extension = filename.slice(-4);
  return ['jpeg', '.jpg', '.png', '.gif'].includes(extension.toLowerCase());
}

export function isPdf(filename) {
  if (!filename || typeof filename !== 'string' || filename.length < 4) {
    throw new Error('Invalid filename')
  }
  const extension = filename.slice(-4);
  return extension.toLowerCase() === '.pdf';
}