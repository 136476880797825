import _ from 'lodash';
import * as actions from '../actions/properties';
import { START, SUCCESS, ERROR } from '../actions/global';
import { fetchingState } from '../../constants';
import { updateStateArrayItem, upsertStateArrayItem } from './utils';

const initialState = {
  userProperties: { data: [], state: null },
  userPropertyHistory: { data: {}, state: null },
  agentProperties: { data: [], state: null },
  agentPropertySearch: { data: [], count: 0, state: null },
  propertySearchConfig: { data: [], state: null },
  agentPropertyHistory: { data: {}, state: null },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER_PROPERTIES[START]:
      return {
        ...state,
        userProperties: {
          ...state.userProperties,
          state: fetchingState.FETCHING,
        }
      }
    case actions.GET_USER_PROPERTIES[SUCCESS]:
      return {
        ...state,
        userProperties: {
          state: fetchingState.LOADED,
          data: _.get(action, 'data.results', []),
        },
      }
    case actions.GET_USER_PROPERTIES[ERROR]:
      return {
        ...state,
        userProperties: {
          data: state.userProperties.data,
          state: fetchingState.ERROR,
        }
      }

    case actions.INSERT_USER_PROPERTY[SUCCESS]:
      return {
        ...state,
        userProperties: {
          data: [
            ...state.userProperties.data,
            ..._.get(action, 'data.results', []),
          ],
          state: fetchingState.LOADED,
        }
      }

    case actions.GET_USER_PROPERTY[SUCCESS]:
      return upsertStateArrayItem(
        state,
        action,
        'userProperties',
        'publicUserPropertyID',
      )

    case actions.UPDATE_USER_PROPERTY_PIN[SUCCESS]: 
      return updateStateArrayItem(
        state,
        action,
        'userProperties',
        'publicUserPropertyID',
      )

    case actions.DELETE_USER_PROPERTY[SUCCESS]: 
      return {
        ...state,
        userProperties: {
          data: state.userProperties.data.filter(
            p => p.publicUserPropertyID !== action.publicUserPropertyId
          ),
          state: state.userProperties.state,
        },
      }

    case actions.GET_USER_PROPERTY_HISTORY[START]:
      return {
        ...state,
        userPropertyHistory: {
          ...state.userPropertyHistory,
          state: fetchingState.FETCHING,
        }
      }
    case actions.GET_USER_PROPERTY_HISTORY[SUCCESS]:
      return {
        ...state,
        userPropertyHistory: {
          state: fetchingState.LOADED,
          data: _.get(action, 'data.results[0]', {}),
        },
      }
    case actions.GET_USER_PROPERTY_HISTORY[ERROR]:
      return {
        ...state,
        userPropertyHistory: {
          data: state.userPropertyHistory.data,
          state: fetchingState.ERROR,
        }
      }

    case actions.GET_AGENT_PROPERTIES[START]:
      return {
        ...state,
        agentProperties: {
          ...state.agentProperties,
          state: action.noNeedRecordState ? state.agentProperties.state : fetchingState.FETCHING,
        }
      }
    case actions.GET_AGENT_PROPERTIES[SUCCESS]:
      return {
        ...state,
        agentProperties: {
          state: fetchingState.LOADED,
          data: _.get(action, 'data.results', []),
        },
      }
    case actions.GET_AGENT_PROPERTIES[ERROR]:
      return {
        ...state,
        agentProperties: {
          data: state.agentProperties.data,
          state: fetchingState.ERROR,
        }
      }
    case actions.GET_AGENT_PROPERTY[SUCCESS]:
      return updateStateArrayItem(
        state,
        action,
        'agentProperties',
        'pID',
      );

    case actions.SEARCH_PROPERTIES[START]:
      return {
        ...state,
        agentPropertySearch: {
          ...state.agentPropertySearch,
          state: fetchingState.FETCHING,
        }
      }
    case actions.SEARCH_PROPERTIES[SUCCESS]:
      return {
        ...state,
        agentPropertySearch: {
          state: fetchingState.LOADED,
          data: _.get(action, 'data.results', []),
          count: _.get(action, 'data.count', 0),
        },
      }
    case actions.SEARCH_PROPERTIES[ERROR]:
      return {
        ...state,
        agentPropertySearch: {
          data: state.agentPropertySearch.data,
          state: fetchingState.ERROR,
        }
      }

    case actions.FETCH_PROPERTY_SEARCH_CONFIG[START]:
      return {
        ...state,
        propertySearchConfig: {
          ...state.propertySearchConfig,
          state: fetchingState.FETCHING,
        }
      }
    case actions.FETCH_PROPERTY_SEARCH_CONFIG[SUCCESS]:
      return {
        ...state,
        propertySearchConfig: {
          state: fetchingState.LOADED,
          data: _.get(action, 'data.results', {}),
        },
      }
    case actions.FETCH_PROPERTY_SEARCH_CONFIG[ERROR]:
      return {
        ...state,
        propertySearchConfig: {
          data: state.propertySearchConfig.data,
          state: fetchingState.LOADED,
        }
      }

    case actions.GET_AGENT_PROPERTY_HISTORY[START]:
      return {
        ...state,
        agentPropertyHistory: {
          ...state.agentPropertyHistory,
          state: fetchingState.FETCHING,
        }
      }
    case actions.GET_AGENT_PROPERTY_HISTORY[SUCCESS]:
      return {
        ...state,
        agentPropertyHistory: {
          state: fetchingState.LOADED,
          data: _.get(action, 'data.results[0]', {}),
        },
      }
    case actions.GET_AGENT_PROPERTY_HISTORY[ERROR]:
      return {
        ...state,
        agentPropertyHistory: {
          data: state.agentPropertyHistory.data,
          state: fetchingState.LOADED,
        }
      }
    default:
      return state;
  }
};
