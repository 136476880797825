import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { PopperWindow } from 'components/HoverContent';
import AppraisalNotice from 'assets/images/efilePin.png';

const EfilePinHelpPopper = ({ style }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Link
        to="#"
        onClick={e => {
          e.preventDefault();
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
        style={{ fontSize: 12, ...style }}
      >
        Where can I find my E-FILE PIN?
      </Link>
      <PopperWindow 
        anchorEl={anchorEl}
        placement="bottom"
        close={() => setAnchorEl(null)}
      >
        <img
          src={AppraisalNotice} 
          style={{ width: 'calc(30vw)' }}
          alt="appraisal-notice"
        />
        <div style={{ width: 'calc(30vw)', paddingTop: 10 }}>
          The E-File pin is printed in the upper right hand corner of the 
          appraisal notice that was mailed to you. Type this number in the 
          purple box above to activate E-File eligibility for your property.  
        </div>
      </PopperWindow>
    </>
  );
}

export default EfilePinHelpPopper;
