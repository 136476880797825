import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const BackProcessDialog = ({
  open,
  title = 'Please Wait...',
  titleStyle = { textTransform: 'uppercase', fontWeight: 'bold' },
  message,
  onClose,
}) =>
  <Dialog open={open}>
    <DialogTitle disableTypography id="alert-dialog-title" style={titleStyle}>
      {title}
    </DialogTitle>
    <DialogContent style={{ paddingBottom: 20 }}>
      <DialogContentText id="alert-dialog-description">
        {message.split('\n').map((i) => (
          <p key={i} style={{ minHeight: '5px' }}>{i}</p>
        ))}
      </DialogContentText>
      <div style={{ textAlign: 'right' }}>
        <Button color="primary" onClick={onClose}>OK</Button>
      </div>
    </DialogContent>
  </Dialog>

BackProcessDialog.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
}

export default BackProcessDialog