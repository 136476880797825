import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@material-ui/styles'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import validate from 'validate.js'

import * as serviceWorker from './serviceWorker'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import Routes from './Routes'
import validators from './common/validators'
import configureStore from './data/configureStore'
import config from './config'
import { fetchConfig, fetchOffice } from './data/actions/global'

// import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';

validate.validators = {
  ...validate.validators,
  ...validators,
}

LicenseManager.setLicenseKey(
  'CompanyName=True Prodigy,LicensedApplication=True Prodigy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-009750,ExpiryDate=16_September_2021_[v2]_MTYzMTc0NjgwMDAwMA==0270c542f4bc5a7321c20092c7f6a814'
)
async function boot() {
  console.log('Starting up public portal app...')
  const browserHistory = createBrowserHistory()
  const store = configureStore(config)
  store.dispatch(fetchOffice())
  const tpToken = localStorage.getItem('TPToken')
  if (tpToken) {
    await store.dispatch(fetchConfig())
  }

  const App = () => (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  )

  ReactDOM.render(<App />, document.getElementById('root'))
}
boot()
serviceWorker.unregister()
