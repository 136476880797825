import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 0,
    background: theme.palette.background.default,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    padding: '5px 10px',
    maxHeight: 300,
    minHeight: 200,
    minWidth: 200,
    overflow: 'scroll',
  },
}));

const BlueCheckboxGroup = ({
  options,
  values = [],
  setValues,
  disabled,
}) => {
  const classes = useStyles();

  const handleChange = event => {
    if (values.includes(event.target.name)) {
      return setValues([...values.filter(i => i !== event.target.name)]);
    }
    setValues([...values, event.target.name]);
  };

  return (
    <PerfectScrollbar className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {options.map(({ label, value }, idx) => 
            <FormControlLabel
              key={`${label}-${idx}`}
              control={
                <Checkbox
                  disabled={disabled}
                  checked={values.includes(value)}
                  onChange={handleChange}
                  name={value}
                  style={{ paddingTop: 2, paddingBottom: 2 }}
                />
              }
              label={label}
            />
          )}
        </FormGroup>
      </FormControl>
    </PerfectScrollbar>
  );
};

BlueCheckboxGroup.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  setValues: PropTypes.func,
  values: PropTypes.array,
};

export default BlueCheckboxGroup;
