
let config;

const currentUser = localStorage.getItem('TPToken');
const sessionConfig = localStorage.getItem('SessionConfig');
console.log('session: ', sessionConfig);

if (process.env.REACT_APP_MY_ENV === 'dev') {
  config = require('./dev/config.json');
} else if (process.env.REACT_APP_MY_ENV === 'staging') {
  config = require('./staging/config.json');
} else if (process.env.REACT_APP_MY_ENV === 'production') {
  config = require('./production/config.json');
}

const urlTokens = window.location.href
  .replace(/http:\/\/|https:\/\//gi, '')
  .split('.');
const idx = urlTokens.findIndex(token => token === 'trueprodigy-publicportal');

let office = 'True Prodigy'; 

if (idx > 0 && !['dev', 'stage', 'prod'].includes(urlTokens[idx - 1])) {
  office = urlTokens[idx - 1];
}

const initialState = { 
  global: {
    config,
    office,
    sessionConfig: {
      data: sessionConfig && sessionConfig !== 'undefined' ? JSON.parse(sessionConfig) : {},
    }
  },
  user: currentUser ? JSON.parse(currentUser) : {},
};

export default initialState;