import React from 'react';
import styled from 'styled-components';

import ScheduleImage from 'assets/images/ManualSchedule.svg';

const Header = () => (
  <Container>
    <StepperWrapper>
      <img src={ScheduleImage} style={{ width: 75 }} alt="header"/>
      <br />
      <span style={{ fontWeight: 'bold', fontSize: 16, textTransform: 'uppercase' }}>
        Schedule a formal hearing with the appraisal review board
      </span>
    </StepperWrapper>
  </Container>
);

const StepperWrapper = styled.div`
  background-color: #f8f9ff;
  padding-top: 10px;

  > div {
    background-color: #f8f9ff !important;
    width: 50%;
    min-width: 400px;
  }
`;

const Container = styled.header`
  width: 100%;

  > div:first-child {
    padding: 17px 26px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    box-shadow: 3px 5px 6px 0 rgba(0, 0, 0, 0.16);
  }
`;

export default Header;
