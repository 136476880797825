import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogTitle from '@material-ui/core/DialogTitle';

const PleaseWaitDialog = ({
  open,
  title = 'Please Wait...',
  titleStyle = { textTransform: 'uppercase', fontWeight: 'bold' },
  message,
  showLinearProgress = true,
}) =>
  <Dialog open={open}>
    <DialogTitle disableTypography id="alert-dialog-title" style={titleStyle}>
      {title}
    </DialogTitle>
    <DialogContent style={{ paddingBottom: 20 }}>
      <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
        {message.split('\n').map((i) => (
          <p>{i}</p>
        ))}
      </DialogContentText>
      {showLinearProgress && (
        <LinearProgress style={{ width: '100%' }} />
      )}
    </DialogContent>
  </Dialog>

PleaseWaitDialog.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  showLinearProgress: PropTypes.bool,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
}

export default PleaseWaitDialog