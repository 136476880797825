export const actionCards = [
  {
    title: 'Manage Properties',
    content: `Add, remove or attach a PIN to your properties.
      You can also view and print your Appraisal Notices.`,
    link: '/my-properties',
    userTypes: ['owner', 'agent'],
  },
  // {
  //   title: 'Applications',
  //   content: `File your exemption applications here. 
  //   (such as Homestead, Over 65, Disable Vet, Disability, AG and others.)`,
  //   link: '/applications',
  // },
  {
    title: 'File A Protest',
    content: 'File a protest, submit evidence and receive comparable sales.',
    link: '/protest',
    userTypes: ['owner'],
  },
  // {
  //   title: 'Account Maintenance',
  //   content: 'Submit name, address and ownership corrections with supporting evidence.',
  //   link: '/account-maintenance',
  // },
  // {
  //   title: 'Renditions',
  //   content: 'File or view prior renditions of the value of your property.',
  //   link: '/renditions',
  // },
  // {
  //   title: 'CAD Communication',
  //   content: 'Receive and have record of messages from CAD.',
  //   link: '/cad-communication',
  // },
  // {
  //   title: 'Activity Log',
  //   content: 'View Activity Log.',
  //   link: '/activity',
  // },
]