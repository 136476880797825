
import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { StyledCard } from 'components/Styled/Common';
import { getUsdFormat } from 'lib/utils';
// import AppraisalNoticeIcon from 'assets/images/surface1.svg';
import MissingPropertyImage from 'assets/images/image-light.svg';

const PropertyCard = ({ property, blurImage = false, blurMessage, style }) => 
  <StyledCard lightBorder style={{ flex: 1, padding: '5px 10px', ...style }}>
    <h4 style={{ width: '100%', textAlign: 'left' }}>
      PROPERTY SELECTED
    </h4>
    {property && Object.keys(property) && (
      <PropertyCardContent>
        <PropertyCardImage>
          <Image
            src={blurImage || !property.signedURL
              ? MissingPropertyImage
              : property.signedURL
            }
            alt="property-pic"
            style={{ width: blurImage || !property.signedURL ? '50%' : '100%' }}
          />
          {blurImage && <div>{blurMessage}</div>}
          {!property.signedURL && !blurImage &&
            <div style={{ textAlign: 'center', fontSize: '1em' }}>
              Valid image not available<br/>
              for this property
            </div>
          }
        </PropertyCardImage>
        <PropertyCardDetails>
          PID: <span>{property.pID}</span><br/>
          {property.appealId && (<>Protest ID: <span>{property.appealId}</span><br/></>)}
          <span>{property.name}</span><br/><br/>
          <span>{property.streetPrimary}</span><br/><br/>
          Last Year Value:
          <span>
            {' '}{getUsdFormat(property.prevMarketValue, 0)}
          </span><br/>
          This Year Value:
          <span>
            {' '}{getUsdFormat(property.marketValue, 0)}
          </span><br/>
          {/*<div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
            <img
              src={AppraisalNoticeIcon} 
              alt="appraisal-notice-icon"
              style={{ margin: 8 }}
            />
             <Link to="">View Appraisal Notice</Link> 
          </div>*/}
        </PropertyCardDetails>
      </PropertyCardContent>
    )}
  </StyledCard>

PropertyCard.propTypes = {
  property: PropTypes.object,
};

const PropertyCardContent = styled.div`
  display: flex;
`;

const PropertyCardImage = styled.div`
  flex: 5;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    margin: 0;
  }

  > div {
    margin-top: 20px;
    font-size: 0.8em;
  }
`;

const PropertyCardDetails = styled.div`
  flex: 4;
  text-align: left;

  span {
    color: #142bad;
  }
`;

const Image = styled.img`
  margin: 15px;
`;

export default PropertyCard;
