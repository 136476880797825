export const breadcrumbSpec = {
  '/my-properties': [
    {
      label: 'Home',
      path: '/home'
    },
    {
      label: 'My Properties'
    }
  ],
  '/protest': [
    {
      label: 'Home',
      path: '/home'
    },
    {
      label: 'Protest',
    }
  ],
  '/protest-manual-schedule/:appealId': [
    {
      label: 'Home',
      path: '/home'
    },
    {
      label: { owner: 'Protest', agent: 'My Properties' },
      path: { owner: '/protest', agent: '/my-properties' },
    },
    {
      label: 'Schedule',
    }
  ],
  '/protest-add-evidence/:propertyId': [
    {
      label: 'Home',
      path: '/home'
    },
    {
      label: { owner: 'Protest', agent: 'My Properties' },
      path: { owner: '/protest', agent: '/my-properties' },
    },
    {
      label: 'Evidence',
    }
  ],
  '/protest-cad-evidence': [
    {
      label: 'Home',
      path: '/home'
    },
    {
      label: { owner: 'Protest', agent: 'My Properties' },
      path: { owner: '/protest', agent: '/my-properties' },
    },
    {
      label: 'CAD Evidence',
    }
  ],
  '/protest-review-recommendation': [
    {
      label: 'Home',
      path: '/home'
    },
    {
      label: { owner: 'Protest', agent: 'My Properties' },
      path: { owner: '/protest', agent: '/my-properties' },
    },
    {
      label: 'Settlement',
    }
  ],
  '/protest-case-activity/:propertyId/:appealId': [
    {
      label: 'Home',
      path: '/home'
    },
    {
      label: { owner: 'Protest', agent: 'My Properties' },
      path: { owner: '/protest', agent: '/my-properties' },
    },
    {
      label: 'Case Activity',
    }
  ],
}