import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const PidImportModal = ({ open, handleClose }) => {
  const [pids, setPids] = useState('');

  useEffect(() => setPids(''), [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose([])}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
        Mass Import
        <IconButton
          aria-label="close"
          onClick={() => handleClose([])}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        Cut and paste property IDs from spreadsheet below:
        <TextField
          variant="outlined"
          fullWidth
          label=""
          value={pids}
          onChange={v => setPids(v.target.value)}
          style={{ marginTop: 10, marginBottom: 10 }}
          rows={10}
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => handleClose(pids.split(/[\n ,]+/).filter(Boolean) || [])}
        >
          IMPORT
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PidImportModal.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
}

export default PidImportModal