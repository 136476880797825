/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import Snackbar from '@material-ui/core/Snackbar'

import { getAppealClaimant } from 'data/selectors/appeals';
import { getSessionConfig } from 'data/selectors/global';
import { useAppealsActions } from 'hooks/useActions';
import { StyledCard, FlexRow } from 'components/Styled/Common';
import { FileUpload, PropertyCard, confirm, Alert } from 'components';
import RoundedButton from 'components/Buttons/RoundedButton';
import AgGrid, { ActionCellRenderer } from 'components/AgGrid';
import { invalidUploadExtensions } from '../../constants';
import { isImage, isPdf } from 'lib/utils';

const MAX_UPLOAD_SIZE_MB = 50;

const AddEvidence = ({ property, appealId, isAgent }) => {
  const [descriptions, setDescriptions] = useState({});
  const appealsActions = useAppealsActions([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const appealsClaimant = useSelector(getAppealClaimant).data;
  const config = useSelector(getSessionConfig);
  const history = useHistory();
  const appealsClaimantRef = useRef(appealsClaimant)
  appealsClaimantRef.current = appealsClaimant;
  const images = (appealsClaimant &&
    appealsClaimant.claimantEvidence &&
    appealsClaimant.claimantEvidence.filter(i => isImage(i.uploadedFileName))) || [];

  const documents = (appealsClaimant &&
    appealsClaimant.claimantEvidence &&
    appealsClaimant.claimantEvidence.filter(i => !isImage(i.uploadedFileName))) || [];

  const columnDefs = [
    {
      headerName: 'File',
      field: 'uploadedFileName',
      cellRendererFramework: ActionCellRenderer,
      cellRendererParams: {
        icons: [
          {
            type: 'link',
            title: v => v,
            onClick: (_, record) => handleDownload(record.s3ID),
          },
        ],
      },
    },
    {
      headerName: 'Uploaded Date/Time',
      field: 'dateAdded',
      valueFormatter: ({ value }) => value.slice(0, 16)
    },
    {
      headerName: 'Delete',
      field: '',
      maxWidth: 80,
      cellRendererFramework: ActionCellRenderer,
      cellRendererParams: {
        icons: [
          {
            icon: 'trash',
            tooltip: 'Delete',
            isVisible: () => true,
            onClick: (_, record) => handleDelete(record.s3ID)
          },
        ],
      },
    },
  ];

  useEffect(() => {
    appealsActions.fetchAppealClaimant({ appealId });
  }, []);

  const onAdd = async files => {
    const ext = files[0].name.split('.').pop();
    if (invalidUploadExtensions.includes(ext)) {
      setApiError(`Files of type ${ext} are not permitted.`)
      return setAlertOpen(true);
    }
    if (isImage(files[0].name)) {
      if (images.length === config.maxPhotos) {
        setApiError(`Maximum number of images (${config.maxPhotos}) reached.`)
        return setAlertOpen(true);
      }
    } else {
      if (documents.length === config.maxDocuments) {
        setApiError(`Maximum number of documents (${config.maxDocuments}) reached.`)
        return setAlertOpen(true);
      }
    }
    if (files[0].size > MAX_UPLOAD_SIZE_MB * 1024 * 1024) {
      setApiError('File size is too large.  The cap is 50 MB')
      return setAlertOpen(true);
    }

    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('lastUpdate', appealsClaimant.updateDt);
    formData.append('description', files[0].path);
    await appealsActions.insertClaimantFile({
      appealId: appealId,
      payload: formData,
    });
  }

  const onUpdate = (s3Id, description) =>
    appealsActions.updateClaimantFileDescription({
      appealId,
      s3Id,
      payload: {
        description,
        lastUpdate: appealsClaimant.updateDt,
      }
    })

  const handleDelete = s3Id => {
    confirm('evidence').then(() =>
      appealsActions.deleteClaimantFile({
        appealId,
        s3Id,
        payload: {
          lastUpdate: appealsClaimantRef.current.updateDt,
        },
      }),
    );
  };

  const handleDownload = s3Id => {
    appealsActions.downloadClaimantFile({
      appealId,
      s3Id,
    });
  };

  const renderItem = item => {
    const extension = item.uploadedFileName.slice(-4);
    if (isImage(item.uploadedFileName)) {
      return (
        <Image
          src={item.url}
          onClick={() => handleDownload(item.s3ID)}
          style={{ cursor: 'pointer' }}
        />
      ); 
    }
    if (isPdf(item.uploadedFileName)) return;
    return (
      <ImagePlaceholder onClick={() => handleDownload(item.s3ID)}>
        {extension} {'File'}
      </ImagePlaceholder>
    );
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlertOpen(false);
  };

  return (
    <WalkthruContent>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {apiError}
        </Alert>
      </Snackbar>
      Upload images documents that support your opinion of value: closing
      statements, repair estimates, condition photos.
      <FlexRow style={{ width: '100%', alignItems: 'stretch' }}>
        <PropertyCard
          property={property}
          style={{ marginRight: 20 }}
        />
        <UploadWrapper>
          <FileUpload 
            onChange={onAdd}
            additionalMessage={`(Maximum ${MAX_UPLOAD_SIZE_MB}MB per file)`}
          />
        </UploadWrapper>
      </FlexRow>
      <FlexRow style={{ width: '100%', alignItems: 'stretch' }}>
        <StyledCard
          style={{ 
            flex: 2,
            marginRight: 20,
            minHeight: 200,
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h4 style={{ width: '100%', textAlign: 'left' }}>
            UPLOADED IMAGES
          </h4>
          <ImagesPanel>
            {images.map(item => (
              <ItemWrapper key={item.url}>
                {renderItem(item)}
                <div style={{ textAlign: 'left', margin: 5 }}>
                  <label style={{ margin: 5 }}>
                    {item.dateAdded.slice(0, 16)}
                  </label>
                  <TextField
                    style={{ width: '100%', marginTop: 10 }}
                    id={item.s3ID}
                    label="Comments"
                    variant="outlined"
                    value={Object.keys(descriptions).includes(item.s3ID)
                      ? descriptions[item.s3ID]
                      : item.description
                    }
                    onChange={({ target: { value } }) =>
                      setDescriptions({ ...descriptions, [item.s3ID]: value })
                    }
                    onKeyPress={({ key }) => {
                      if (key === 'Enter') {
                        onUpdate(item.s3ID, descriptions[item.s3ID])
                      }
                    }}
                    inputProps={{
                      onBlur: () => onUpdate(item.s3ID, descriptions[item.s3ID]),
                    }}
                  />
                  <ButtonWrapper onClick={() => handleDelete(item.s3ID)}>
                    <ClearIcon style={{ color: '#464646' }} />
                  </ButtonWrapper>
                </div>
              </ItemWrapper>
            ))}
            {appealsClaimant &&
            appealsClaimant.claimantEvidence &&
            appealsClaimant.claimantEvidence.length === 0 && (
              <div style={{ color: '#aaa' }}>
                No evidence has been uploaded...
              </div>
            )}
          </ImagesPanel>
          <ItemCountContainer>
            {images.length} of {config.maxPhotos} uploaded
          </ItemCountContainer>
        </StyledCard>
        <StyledCard style={{ flex: 1, padding: 10, display: 'flex', flexDirection: 'column' }}>
          <h4 style={{ width: '100%', textAlign: 'left', paddingBottom: 10 }}>
            UPLOADED DOCUMENTS
          </h4>
          <AgGridWrapper className="ag-theme-custom">
            <AgGrid
              disablePreferenceSave
              autoResizeToFit
              columnDefs={columnDefs}
              name="uploadedDocuments"
              rowData={documents}
              domLayout="autoHeight"
            />
          </AgGridWrapper>
          <ItemCountContainer>
            {documents.length} of {config.maxDocuments} uploaded
          </ItemCountContainer>
        </StyledCard>
      </FlexRow>
      <RoundedButton
        onClick={() => history.push(isAgent ? '/my-properties' : '/protest')}
        color="info"
        style={{ width: 200, margin: 0 }}
      >
        DONE
      </RoundedButton>
    </WalkthruContent>
  )
}

AddEvidence.propTypes = {
  appealId: PropTypes.number,
  appealSummary: PropTypes.object,
  isAgent: PropTypes.bool,
  property: PropTypes.object,
}

const WalkthruContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  align-items: center;
  width: 100%;
`;

const UploadWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImagesPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  > div > div:first-child {
    max-width: 200px;
    border: 1px solid #eee;
    cursor: pointer;

    > div > canvas {
      padding-right: 2px;
    }
  }
`;

const ItemWrapper = styled.div`
  position: relative;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 12px;

  div > .timestamp {
    margin: 10px 0;
  }
`;

const Image = styled.img`
  max-width: 200px;
  border: 1px solid #eee;
`;


const ImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  font-size: 12px;
  min-height: 114px;
  min-width: 200px;
`;

const ButtonWrapper = styled.div`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;

const ItemCountContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  color: rgba(0, 0, 0, 0.16);
`;

const AgGridWrapper = styled.div`
  height: 200px;
`;

export default AddEvidence;
