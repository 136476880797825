import React, { useState, useEffect, } from 'react';
import { useHistory } from 'react-router-dom';
// import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { useUserActions } from '../../hooks/useActions';
import { useQuery } from 'hooks/useQuery';
import { TransparentTextInput } from 'components';
import RoundedButton from 'components/Buttons/RoundedButton';

const schema = {
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  password2: {
    presence: { allowEmpty: false, message: 'is required' },
    equality: 'password',
    length: {
      maximum: 128
    }
  },
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    color: 'white',
    marginTop: theme.spacing(3),
    borderBottom: '2px solid #fecc3d',
  },
}));

const ResetPassword = () => {
  const userActions = useUserActions([]);
  const history = useHistory();
  const query = useQuery();
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSetPassword = async event => {
    event.preventDefault();
    try {
      await userActions.setPassword(
        query.get('key'), 
        formState.values.password,
      )
      history.push('/home');
    } catch (err) {
      console.log(err);
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.content}>
      <div className={classes.contentBody}>
        <form
          className={classes.form}
          onSubmit={handleSetPassword}
        >
          <Typography
            className={classes.title}
            variant="h2"
            style={{ maxWidth: 240 }}
          >
            Reset Password
          </Typography>

          <span style={{ fontSize: '1.1em', opacity: 0.7 }}><br/>
            Registered Email:{' '}
            <span style={{ fontWeight: 'bold' }}>{query.get('email')}</span>
          </span>
          <TransparentTextInput
            fullWidth
            placeholder="Password"
            error={hasError('password')}
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            label="Password"
            name="password"
            onChange={handleChange}
            type="password"
            value={formState.values.password || ''}
          />
          <TransparentTextInput
            fullWidth
            placeholder="Re-enter Password"
            error={hasError('password2')}
            helperText={
              hasError('password2') ? formState.errors.password2[0] : null
            }
            label="Re-enter Password"
            name="password2"
            onChange={handleChange}
            type="password"
            value={formState.values.password2 || ''}
          />
          <RoundedButton
            color="info"
            fullWidth
            size="large"
            type="submit"
          >
            RESET PASSWORD
          </RoundedButton>

        </form>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;
