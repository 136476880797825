import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    background: 'white'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SimpleSelect = ({
  label,
  value,
  options,
  handleChange,
  includeEmptyOption = false,
  // style,
  // variant,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      {...rest}
    >
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={value}
        onChange={handleChange}
        label={label}
      >
        {[
          ...(includeEmptyOption ? [{ label: 'None', value: '' } ] : []),
          ...options,
        ].map(o => (
          <MenuItem
            key={o.value}
            value={o.value}
          >
            {o.label}
          </MenuItem>
        ))
        }
      </Select>
    </FormControl>
  );
};

SimpleSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  includeEmptyOption: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string
  // style: PropTypes.object,
  // variant: PropTypes.string,
}

export default SimpleSelect;
