import React from 'react';
import { makeStyles } from '@material-ui/styles';
import MainImage from 'assets/images/sorry-we\'re-closed.png';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center'
  },
}));

const Notification = () => {
  const classes = useStyles();
  const sessionConfig = localStorage.getItem('SessionConfig') ? JSON.parse(localStorage.getItem('SessionConfig')) : null;
  const message = (sessionConfig ? sessionConfig.siteDisabledText : '').split('\n');
  return (
    <div className={classes.root}>
      <Message>{message.map((m, i) => (
        <c key={i}>
          <span>{m}</span><br/><br/>
        </c>
      ))}</Message>
      <img src={MainImage} />
    </div>
  );
};

const Message = styled.div`
  font-size: 25px;
  padding: 0 50px;
  text-align: left;
`;

export default Notification;
