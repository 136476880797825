import _ from 'lodash'
import * as actions from '../actions/global'
import { START, SUCCESS, ERROR } from '../actions/global'
import { fetchingState } from '../../constants'

const initialState = {
  config: { state: null },
  officeLookup: null,
  maintenance: {
    data: {},
    state: null,
  },
  sessionConfig: { data: {}, state: null },
  codeFiles: { data: {}, state: null },
  protestStatusList: { data: [], state: null },
  agentStatusList: { data: [], state: null },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_OFFICE[SUCCESS]:
      return {
        ...state,
        officeLookup: _.get(action, 'data.results.office', {}),
      }
    case actions.FETCH_CONFIG[START]:
      return {
        ...state,
        sessionConfig: {
          ...state.sessionConfig,
          state: fetchingState.FETCHING,
        },
      }
    case actions.FETCH_CONFIG[SUCCESS]:
      return {
        ...state,
        sessionConfig: {
          data: _.get(action, 'data.results', {}),
          state: fetchingState.LOADED,
        },
      }
    case actions.FETCH_CONFIG[ERROR]:
      return {
        ...state,
        sessionConfig: {
          ...state.sessionConfig,
          state: fetchingState.LOADED,
        },
      }

    case actions.FETCH_MAINTENANCE_SCHEDULE[START]:
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          state: fetchingState.FETCHING,
        },
      }
    case actions.FETCH_MAINTENANCE_SCHEDULE[SUCCESS]:
      return {
        ...state,
        maintenance: {
          data: _.get(action, 'data.results', {}),
          state: fetchingState.LOADED,
        },
      }
    case actions.FETCH_MAINTENANCE_SCHEDULE[ERROR]:
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          state: fetchingState.LOADED,
        },
      }

    case actions.FETCH_CODE_FILE[SUCCESS]:
      return {
        ...state,
        codeFiles: {
          state: fetchingState.LOADED,
          data: {
            ..._.get(state, 'codeFiles.data', {}),
            [action.name]: action.data ? action.data.results : [],
          },
        },
      }

    case actions.FETCH_PROTEST_STATUS_LIST[SUCCESS]:
      return {
        ...state,
        protestStatusList: {
          data: _.get(action, 'data.results', []),
          state: fetchingState.LOADED,
        },
      }

    case actions.FETCH_AGENT_STATUS_LIST[SUCCESS]:
      return {
        ...state,
        agentStatusList: {
          data: _.get(action, 'data.results', []),
          state: fetchingState.LOADED,
        },
      }

    default:
      return state
  }
}
