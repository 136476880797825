import * as api from '../api/properties';
import { createRequestTypes, START, SUCCESS, ERROR } from './global';
import { makeApiActionCreator } from './utils';

const prefix = 'PUBLIC_PORTAL/PROPERTIES';

export const GET_USER_PROPERTIES = createRequestTypes(`${prefix}/GET_USER_PROPERTIES`);

export const getUserProperties = makeApiActionCreator(
  api.getUserProperties,
  GET_USER_PROPERTIES[START],
  GET_USER_PROPERTIES[SUCCESS],
  GET_USER_PROPERTIES[ERROR],
);

export const GET_USER_PROPERTY = createRequestTypes(`${prefix}/GET_USER_PROPERTY`);

export const getUserProperty = makeApiActionCreator(
  api.getUserProperty,
  GET_USER_PROPERTY[START],
  GET_USER_PROPERTY[SUCCESS],
  GET_USER_PROPERTY[ERROR],
);

export const GET_USER_PROPERTY_HISTORY = createRequestTypes(`${prefix}/GET_USER_PROPERTY_HISTORY`);

export const getUserPropertyHistory = makeApiActionCreator(
  api.getUserPropertyHistory,
  GET_USER_PROPERTY_HISTORY[START],
  GET_USER_PROPERTY_HISTORY[SUCCESS],
  GET_USER_PROPERTY_HISTORY[ERROR],
);

export const INSERT_USER_PROPERTY = createRequestTypes(`${prefix}/INSERT_USER_PROPERTY`);

export const insertUserProperty = makeApiActionCreator(
  api.insertUserProperty,
  INSERT_USER_PROPERTY[START],
  INSERT_USER_PROPERTY[SUCCESS],
  INSERT_USER_PROPERTY[ERROR],
);

export const UPDATE_USER_PROPERTY_PIN = createRequestTypes(`${prefix}/UPDATE_USER_PROPERTY_PIN`);

export const updateUserPropertyPin = makeApiActionCreator(
  api.updateUserPropertyPin,
  UPDATE_USER_PROPERTY_PIN[START],
  UPDATE_USER_PROPERTY_PIN[SUCCESS],
  UPDATE_USER_PROPERTY_PIN[ERROR],
);

export const DELETE_USER_PROPERTY = createRequestTypes(`${prefix}/DELETE_USER_PROPERTY`);

export const deleteUserProperty = makeApiActionCreator(
  api.deleteUserProperty,
  DELETE_USER_PROPERTY[START],
  DELETE_USER_PROPERTY[SUCCESS],
  DELETE_USER_PROPERTY[ERROR],
);

export const GET_AGENT_PROPERTIES = createRequestTypes(`${prefix}/GET_AGENT_PROPERTIES`);

export const getAgentProperties = makeApiActionCreator(
  api.getAgentProperties,
  GET_AGENT_PROPERTIES[START],
  GET_AGENT_PROPERTIES[SUCCESS],
  GET_AGENT_PROPERTIES[ERROR],
);

export const GET_AGENT_PROPERTY = createRequestTypes(`${prefix}/GET_AGENT_PROPERTY`);

export const getAgentProperty = makeApiActionCreator(
  api.getAgentProperty,
  GET_AGENT_PROPERTY[START],
  GET_AGENT_PROPERTY[SUCCESS],
  GET_AGENT_PROPERTY[ERROR],
);

export const GET_AGENT_PROPERTY_HISTORY = createRequestTypes(
  `${prefix}/GET_AGENT_PROPERTY_HISTORY`,
);

export const getAgentPropertyHistory = makeApiActionCreator(
  api.getAgentPropertyHistory,
  GET_AGENT_PROPERTY_HISTORY[START],
  GET_AGENT_PROPERTY_HISTORY[SUCCESS],
  GET_AGENT_PROPERTY_HISTORY[ERROR],
);

export const SEARCH_PROPERTIES = createRequestTypes(
  `${prefix}/SEARCH_PROPERTIES`,
);

export const searchProperties = makeApiActionCreator(
  api.searchProperties,
  SEARCH_PROPERTIES[START],
  SEARCH_PROPERTIES[SUCCESS],
  SEARCH_PROPERTIES[ERROR],
);

export const FETCH_PROPERTY_SEARCH_CONFIG = createRequestTypes(
  `${prefix}/FETCH_PROPERTY_SEARCH_CONFIG`,
);

export const fetchPropertySearchConfig = makeApiActionCreator(
  api.fetchPropertySearchConfig,
  FETCH_PROPERTY_SEARCH_CONFIG[START],
  FETCH_PROPERTY_SEARCH_CONFIG[SUCCESS],
  FETCH_PROPERTY_SEARCH_CONFIG[ERROR],
);

export const DOWNLOAD_NOTICE = createRequestTypes(
  `${prefix}/DOWNLOAD_NOTICE`,
);

export const downloadNotice = makeApiActionCreator(
  api.downloadNotice,
  DOWNLOAD_NOTICE[START],
  DOWNLOAD_NOTICE[SUCCESS],
  DOWNLOAD_NOTICE[ERROR],
);
