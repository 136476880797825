import * as api from '../api/appeals';
import { createRequestTypes, START, SUCCESS, ERROR } from './global';
import { makeApiActionCreator } from './utils';

const prefix = 'PUBLIC_PORTAL/APPEALS';

export const INSERT_APPEAL = createRequestTypes(`${prefix}/INSERT_APPEAL`);

export const insertAppeal = makeApiActionCreator(
  api.insertAppeal,
  INSERT_APPEAL[START],
  INSERT_APPEAL[SUCCESS],
  INSERT_APPEAL[ERROR],
);

export const FETCH_FIRST_AVAILABLE = createRequestTypes(`${prefix}/FETCH_FIRST_AVAILABLE`);

export const fetchFirstAvailableDocketTime = makeApiActionCreator(
  api.fetchFirstAvailableDocketTime,
  FETCH_FIRST_AVAILABLE[START],
  FETCH_FIRST_AVAILABLE[SUCCESS],
  FETCH_FIRST_AVAILABLE[ERROR],
);

export const SCHEDULE_APPEAL = createRequestTypes(`${prefix}/SCHEDULE_APPEAL`);

export const scheduleAppeal = makeApiActionCreator(
  api.scheduleAppeal,
  SCHEDULE_APPEAL[START],
  SCHEDULE_APPEAL[SUCCESS],
  SCHEDULE_APPEAL[ERROR],
);

export const FETCH_TIME_SLOTS = createRequestTypes(`${prefix}/FETCH_TIME_SLOTS`);

export const fetchAppealDocketTimeSlots = makeApiActionCreator(
  api.fetchAppealDocketTimeSlots,
  FETCH_TIME_SLOTS[START],
  FETCH_TIME_SLOTS[SUCCESS],
  FETCH_TIME_SLOTS[ERROR],
);

export const FETCH_APPEAL_CLAIMANT = createRequestTypes(`${prefix}/FETCH_APPEAL_CLAIMANT`);

export const fetchAppealClaimant = makeApiActionCreator(
  api.fetchAppealClaimant,
  FETCH_APPEAL_CLAIMANT[START],
  FETCH_APPEAL_CLAIMANT[SUCCESS],
  FETCH_APPEAL_CLAIMANT[ERROR],
);

export const INSERT_CLAIMANT_FILE = createRequestTypes(`${prefix}/INSERT_CLAIMANT_FILE`);

export const insertClaimantFile = makeApiActionCreator(
  api.insertClaimantFile,
  INSERT_CLAIMANT_FILE[START],
  INSERT_CLAIMANT_FILE[SUCCESS],
  INSERT_CLAIMANT_FILE[ERROR],
);

export const DOWNLOAD_CLAIMANT_FILE = createRequestTypes(`${prefix}/DOWNLOAD_CLAIMANT_FILE`);

export const downloadClaimantFile = makeApiActionCreator(
  api.downloadClaimantFile,
  DOWNLOAD_CLAIMANT_FILE[START],
  DOWNLOAD_CLAIMANT_FILE[SUCCESS],
  DOWNLOAD_CLAIMANT_FILE[ERROR],
);

export const DELETE_CLAIMANT_FILE = createRequestTypes(`${prefix}/DELETE_CLAIMANT_FILE`);

export const deleteClaimantFile = makeApiActionCreator(
  api.deleteClaimantFile,
  DELETE_CLAIMANT_FILE[START],
  DELETE_CLAIMANT_FILE[SUCCESS],
  DELETE_CLAIMANT_FILE[ERROR],
);

export const UPDATE_CLAIMANT_FILE_DESCRIPTION = createRequestTypes(
  `${prefix}/UPDATE_CLAIMANT_FILE_DESCRIPTION`
);

export const updateClaimantFileDescription = makeApiActionCreator(
  api.updateClaimantFileDescription,
  UPDATE_CLAIMANT_FILE_DESCRIPTION[START],
  UPDATE_CLAIMANT_FILE_DESCRIPTION[SUCCESS],
  UPDATE_CLAIMANT_FILE_DESCRIPTION[ERROR],
);

export const WITHDRAW_APPEAL = createRequestTypes(
  `${prefix}/WITHDRAW_APPEAL`
);

export const withdrawAppeal = makeApiActionCreator(
  api.withdrawAppeal,
  WITHDRAW_APPEAL[START],
  WITHDRAW_APPEAL[SUCCESS],
  WITHDRAW_APPEAL[ERROR],
);

export const DOWNLOAD_CAD_PRESENTATION = createRequestTypes(
  `${prefix}/DOWNLOAD_CAD_PRESENTATION`
);

export const downloadCadPresentation = makeApiActionCreator(
  api.downloadCadPresentation,
  DOWNLOAD_CAD_PRESENTATION[START],
  DOWNLOAD_CAD_PRESENTATION[SUCCESS],
  DOWNLOAD_CAD_PRESENTATION[ERROR],
);

export const FETCH_APPEAL_RECOMMENDATIONS = createRequestTypes(
  `${prefix}/FETCH_APPEAL_RECOMMENDATIONS`,
);

export const fetchAppealRecommendation = makeApiActionCreator(
  api.fetchAppealRecommendation,
  FETCH_APPEAL_RECOMMENDATIONS[START],
  FETCH_APPEAL_RECOMMENDATIONS[SUCCESS],
  FETCH_APPEAL_RECOMMENDATIONS[ERROR],
);

export const ACCEPT_APPEAL_RECOMMENDATION = createRequestTypes(
  `${prefix}/ACCEPT_APPEAL_RECOMMENDATION`,
);

export const acceptAppealRecommendation = makeApiActionCreator(
  api.acceptAppealRecommendation,
  ACCEPT_APPEAL_RECOMMENDATION[START],
  ACCEPT_APPEAL_RECOMMENDATION[SUCCESS],
  ACCEPT_APPEAL_RECOMMENDATION[ERROR],
);

export const REJECT_APPEAL_RECOMMENDATION = createRequestTypes(
  `${prefix}/REJECT_APPEAL_RECOMMENDATION`,
);

export const rejectAppealRecommendation = makeApiActionCreator(
  api.rejectAppealRecommendation,
  REJECT_APPEAL_RECOMMENDATION[START],
  REJECT_APPEAL_RECOMMENDATION[SUCCESS],
  REJECT_APPEAL_RECOMMENDATION[ERROR],
);

export const SET_RECOMMENDATION_PORTAL_VIEW = createRequestTypes(
  `${prefix}/SET_RECOMMENDATION_PORTAL_VIEW`,
);

export const setRecommendationPortalView = makeApiActionCreator(
  api.setRecommendationPortalView,
  SET_RECOMMENDATION_PORTAL_VIEW[START],
  SET_RECOMMENDATION_PORTAL_VIEW[SUCCESS],
  SET_RECOMMENDATION_PORTAL_VIEW[ERROR],
);

export const DOWNLOAD_APPEAL_RECOMMENDATION = createRequestTypes(
  `${prefix}/DOWNLOAD_APPEAL_RECOMMENDATION`,
);

export const downloadAppealRecommendation = makeApiActionCreator(
  api.downloadAppealRecommendation,
  DOWNLOAD_APPEAL_RECOMMENDATION[START],
  DOWNLOAD_APPEAL_RECOMMENDATION[SUCCESS],
  DOWNLOAD_APPEAL_RECOMMENDATION[ERROR],
);

export const MASS_CREATE_AGENT_PROTEST = createRequestTypes(
  `${prefix}/MASS_CREATE_AGENT_PROTEST`,
);

export const massCreateAgentProtest = makeApiActionCreator(
  api.massCreateAgentProtest,
  MASS_CREATE_AGENT_PROTEST[START],
  MASS_CREATE_AGENT_PROTEST[SUCCESS],
  MASS_CREATE_AGENT_PROTEST[ERROR],
);

export const AGENT_SELECT_PROPERTIES = 'AGENT_SELECT_PROPERTIES';

export const agentSelectProperties = properties => ({
  type: AGENT_SELECT_PROPERTIES,
  payload: properties,
});

export const VALIDATE_PID_LIST = createRequestTypes(
  `${prefix}/VALIDATE_PID_LIST`,
);

export const validatePidList = makeApiActionCreator(
  api.validatePidList,
  VALIDATE_PID_LIST[START],
  VALIDATE_PID_LIST[SUCCESS],
  VALIDATE_PID_LIST[ERROR],
);

export const UPLOAD_AGENT_FILES = createRequestTypes(
  `${prefix}/UPLOAD_AGENT_FILES`,
);

export const uploadAgentFiles = makeApiActionCreator(
  api.uploadAgentFiles,
  UPLOAD_AGENT_FILES[START],
  UPLOAD_AGENT_FILES[SUCCESS],
  UPLOAD_AGENT_FILES[ERROR],
)

export const PROCESS_AGENT_AOA = createRequestTypes(
  `${prefix}/PROCESS_AGENT_AOA`,
);

export const processAgentAoa = makeApiActionCreator(
  api.processAgentAoa,
  PROCESS_AGENT_AOA[START],
  PROCESS_AGENT_AOA[SUCCESS],
  PROCESS_AGENT_AOA[ERROR],
)

export const REVOKE_AGENT_AOA = createRequestTypes(
  `${prefix}/REVOKE_AGENT_AOA`,
);

export const revokeAgentAoa = makeApiActionCreator(
  api.revokeAgentAoa,
  REVOKE_AGENT_AOA[START],
  REVOKE_AGENT_AOA[SUCCESS],
  REVOKE_AGENT_AOA[ERROR],
)

export const GET_MASS_AOA_STATUS = createRequestTypes(
  `${prefix}/GET_MASS_AOA_STATUS`,
);

export const getMassAoaStatus = makeApiActionCreator(
  api.getMassAoaStatus,
  GET_MASS_AOA_STATUS[START],
  GET_MASS_AOA_STATUS[SUCCESS],
  GET_MASS_AOA_STATUS[ERROR],
)

export const GET_PORTAL_ACTIVITY = createRequestTypes(
  `${prefix}/GET_PORTAL_ACTIVITY`,
);

export const getPortalActivity = makeApiActionCreator(
  api.getPortalActivity,
  GET_PORTAL_ACTIVITY[START],
  GET_PORTAL_ACTIVITY[SUCCESS],
  GET_PORTAL_ACTIVITY[ERROR],
)

export const DOWNLOAD_PORTAL_ACTIVITY_FILE = createRequestTypes(
  `${prefix}/DOWNLOAD_PORTAL_ACTIVITY_FILE`,
);

export const downloadPortalActivityFile = makeApiActionCreator(
  api.downloadPortalActivityFile,
  DOWNLOAD_PORTAL_ACTIVITY_FILE[START],
  DOWNLOAD_PORTAL_ACTIVITY_FILE[SUCCESS],
  DOWNLOAD_PORTAL_ACTIVITY_FILE[ERROR],
)

export const ACCEPT_TOPLINE = createRequestTypes(
  `${prefix}/ACCEPT_TOPLINE`,
);

export const acceptTopline = makeApiActionCreator(
  api.acceptTopline,
  ACCEPT_TOPLINE[START],
  ACCEPT_TOPLINE[SUCCESS],
  ACCEPT_TOPLINE[ERROR],
)

export const MASS_WITHDRAW = createRequestTypes(
  `${prefix}/MASS_WITHDRAW`,
);

export const massWithdraw = makeApiActionCreator(
  api.massWithdraw,
  MASS_WITHDRAW[START],
  MASS_WITHDRAW[SUCCESS],
  MASS_WITHDRAW[ERROR],
);

export const MASS_ACCEPT = createRequestTypes(
  `${prefix}/MASS_ACCEPT`,
);

export const massAccept = makeApiActionCreator(
  api.massAccept,
  MASS_ACCEPT[START],
  MASS_ACCEPT[SUCCESS],
  MASS_ACCEPT[ERROR],
);

export const MASS_REJECT = createRequestTypes(
  `${prefix}/MASS_REJECT`,
);

export const massReject = makeApiActionCreator(
  api.massReject,
  MASS_REJECT[START],
  MASS_REJECT[SUCCESS],
  MASS_REJECT[ERROR],
);

export const MASS_ACCEPT_TOP = createRequestTypes(
  `${prefix}/MASS_ACCEPT_TOP`,
);

export const massAcceptTop = makeApiActionCreator(
  api.massAcceptTop,
  MASS_ACCEPT_TOP[START],
  MASS_ACCEPT_TOP[SUCCESS],
  MASS_ACCEPT_TOP[ERROR],
);
