import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) =>
  <Route 
    {...rest}
    render={props => {
      if (localStorage.getItem('TPToken') !== null) {
        const sessionConfig = localStorage.getItem('SessionConfig') ? JSON.parse(localStorage.getItem('SessionConfig')) : null;
        if (sessionConfig && !sessionConfig.siteEnabled) {
          return (
            <Redirect
              to={{
                pathname: '/notification',
                state: { from: props.location }
              }}
            />
          )
        }
        return (
          <Component {...props} />
        )
      }
      return (
        <Redirect
          to={{
            pathname: '/sign-in',
            state: { from: props.location }
          }}
        />
      )
    }
    }
  />

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
}
