import React from 'react';
import PropTypes from 'prop-types';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import styled from 'styled-components';

import ProtestImage from 'assets/images/ProtestHeaderImage.svg';

const WalkthroughHeader = ({ title = '', steps, activeStep, onClose }) => (
  <Header>
    <StepperWrapper>
      <img src={ProtestImage} style={{ width: 75 }} alt="header"/>
      <br />
      <br />
      <span style={{ fontWeight: 'bold', fontSize: 16 }}>CREATE A PROTEST</span>
      <CustomStepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <CustomStepLabel completed={false} isActive={index === activeStep}>
              {label}
            </CustomStepLabel>
          </Step>
        ))}
      </CustomStepper>
    </StepperWrapper>
  </Header>
);

WalkthroughHeader.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number.isRequired,
  onClose: PropTypes.func,
};

const CustomStepper = styled(Stepper)`
  background-color: #f8f9ff !important;
  padding: 19px 10px 9px 10px !important;

  > div {
    background-color: #f8f9ff !important;
    width: 100%;
  }
`;

const StepperWrapper = styled.div`
  background-color: #f8f9ff;
  padding-top: 10px;

  > div {
    background-color: #f8f9ff !important;
    width: 50%;
    min-width: 400px;
  }
`;

const Header = styled.header`
  width: 100%;

  > div:first-child {
    padding: 17px 26px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
`;

// eslint-disable-next-line
const CustomStepLabel = styled(({ isActive, ...props }) => (
  <StepLabel {...props} />
))`
  > span > span {
    color: ${({ isActive }) => (isActive ? '#2f3e9e' : '#6d6d6d')} !important;
  }
`;

export default WalkthroughHeader;
