import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

const RevokeAoaModal = ({ open, pids, handleClose, revokeAoa, user }) => {
  const [email, setEmail] = useState('');

  const onSubmit = () => revokeAoa({
    pIDList: pids,
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
        Revoke AOA to the following properties?
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <StyledDialogContent>
        <PidContainer>
          <b>Selected PIDs:</b><br/>
          {pids.map(pid => <span>{pid}</span>)}
        </PidContainer>
        <span>
          Please enter your email address to confirm you want to revoke your 
          AOA on the selected properties
        </span>
        <TextField
          variant="outlined"
          onChange={e => setEmail(e.target.value)}
          style={{ width: '60%', marginTop: 10 }}
        />
      </StyledDialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={email !== user.email}
          onClick={onSubmit}
        >
          Revoke AOA(s)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RevokeAoaModal.propTypes = {
  appealsActions: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  pids: PropTypes.array,
  revokeAoa: PropTypes.func.isRequired,
  user: PropTypes.object,
}

const PidContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  max-height: 75vh;
  max-width: 40%;
  width: 100%;
  overflow: scroll;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    text-align: center;
  }
`;

export default RevokeAoaModal