import queryString from 'query-string';
import { get, put, post, del } from './request';

export const getUserProperties = async (
  { apiUrl },
  token,
  { userId, includeAppeals = false },
) => {
  let url = `${apiUrl}/public/user/${userId}/properties`;
  if (includeAppeals) {
    url = `${url}?includeAppeals=1`;
  }
  const { body } = await get(token, url);
  return body;
}

export const getUserProperty = async (
  { apiUrl },
  token,
  { publicUserPropertyId },
) => {
  let url = `${apiUrl}/public/user/property/${publicUserPropertyId}`
  const { body } = await get(token, url);
  return body;
}

export const getUserPropertyHistory = async (
  { apiUrl },
  token,
  { publicUserPropertyId },
) => {
  let url = `${apiUrl}/public/user/property/${publicUserPropertyId}/history`
  const { body } = await get(token, url);
  return body;
}

export const updateUserPropertyPin = async (
  { apiUrl },
  token,
  { publicUserPropertyId, payload },
) => {
  const { body } = await put(
    token, 
    `${apiUrl}/public/user/property/${publicUserPropertyId}/pin`,
    payload,
  );
  return body;
}

export const insertUserProperty = async (
  { apiUrl },
  token,
  { userId, payload },
) => {
  const { body } = await post(
    token, 
    `${apiUrl}/public/user/${userId}/properties`,
    payload,
  );
  return body;
}

export const deleteUserProperty = async (
  { apiUrl },
  token,
  { publicUserPropertyId },
) => {
  const { body } = await del(
    token, 
    `${apiUrl}/public/user/property/${publicUserPropertyId}`,
  );
  return body;
}

export const getAgentProperties = async (
  { apiUrl },
  token,
  { agentId, includeAppeals = false, propertyStatus, protestStatus },
) => {
  let url = `${apiUrl}/public/agent/${agentId}/properties`;
  let query = queryString.stringify({ includeAppeals, propertyStatus, protestStatus });
  const { body } = await get(token, `${url}?${query}`);
  return body;
}


export const getAgentProperty = async (
  { apiUrl },
  token,
  { agentId, propertyId },
) => {
  let url = `${apiUrl}/public/agent/${agentId}/property/${propertyId}`;
  const { body } = await get(token, url);
  return body;
}

export const getAgentPropertyHistory = async (
  { apiUrl },
  token,
  { propertyId },
) => {
  let url = `${apiUrl}/public/agent/property/${propertyId}/history`
  const { body } = await get(token, url);
  return body;
}

export const searchProperties = async (
  { apiUrl },
  token,
  payload,
) => {
  const { body } = await post(token, `${apiUrl}/public/property/search`, payload);
  return body;
}

export const fetchPropertySearchConfig = async (
  { apiUrl },
  token,
) => {
  const { body } = await get(token, `${apiUrl}/public/config/propertysearchresults`);
  return body;
}

export const downloadNotice = async (
  { apiUrl },
  token,
  { propertyId },
) => {
  const { body } = await get(token, `${apiUrl}/public/property/${propertyId}/noticedownload`, { outputType: 'bytearray' });
  return body;
}
