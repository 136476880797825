import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'

import MaintenanceHeader from 'components/MaintenanceHeader'
import MaintenanceScreen from 'components/MaintenanceScreen'
import { useMaintenance } from 'hooks/useMaintenance'
import { Sidebar, Topbar } from './components'
import { useUserActions } from 'hooks/useActions'
import { userSelector } from 'data/selectors/user'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 28,
    },
  },
  shiftContent: {
    paddingLeft: 214,
  },
  content: {
    minHeight: '100%',
    background: '#fff',
  },
}))

const Main = props => {
  const { children } = props
  const [openSidebar, setOpenSidebar] = useState(false)
  const userActions = useUserActions([])
  const user = useSelector(userSelector)
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  const {
    maintenance,
    showMaintenanceProgress,
    showMaintenanceWarning,
  } = useMaintenance()
  const userType = user.agentID !== 0 ? 'agent' : 'owner'
  const shouldOpenSidebar = isDesktop ? true : openSidebar

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  return (
    <>
      {showMaintenanceWarning && (
        <MaintenanceHeader maintenance={maintenance} />
      )}

      {showMaintenanceProgress ? (
        <MaintenanceScreen maintenance={maintenance} />
      ) : (
        <>
          <Topbar
            onSidebarOpen={handleSidebarOpen}
            logout={userActions.logout}
            userType={userType}
          />
          <div
            className={clsx({
              [classes.root]: true,
              [classes.shiftContent]: isDesktop,
            })}
          >
            <>
              <Sidebar
                onClose={handleSidebarClose}
                open={shouldOpenSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
                userType={userType}
              />
              <main className={classes.content}>{children}</main>
            </>
          </div>
        </>
      )}
    </>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
