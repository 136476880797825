import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

import maintenanceImage from 'assets/images/under-development.png'

const fontStyle = {
  fontFamily: 'arial',
  fontSize: 24,
  lineHeight: 1.7,
  textAlign: 'center',
}

const MaintenanceScreen = ({ maintenance }) => (
  <Container>
    <Typography
      style={fontStyle}
      dangerouslySetInnerHTML={{ __html: maintenance.inProcessMessage }}
    ></Typography>

    <img src={maintenanceImage} style={{ width: '50%' }} />
  </Container>
)

MaintenanceScreen.propTypes = {
  children: PropTypes.object,
  title: PropTypes.any,
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  background: white;
  padding-top: 30px;
`

export default MaintenanceScreen
