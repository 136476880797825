/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import TrashIcon from '@material-ui/icons/Delete';

const iconData = {
  trash: <TrashIcon style={{ color: '#ff5f5d' }} />,
};

const ActionCellRenderer = ({ data, icons = [], value, api, rowIndex }) => {

  const renderIcon = (content, tooltip) => tooltip
    ? <Tooltip title={tooltip}>{content}</Tooltip>
    : content;

  return (
    <Wrapper>
      {icons.map(
        ({ icon, onClick, isActive, isVisible, tooltip, type, title, renderComponent }, idx) =>
          ((isVisible && isVisible(data)) || !isVisible) && (
            <React.Fragment key={idx}>
              { type === 'component' && (
                renderComponent({ data })
              )}
              { type === 'link' && (
                <Link
                  to="#"
                  style={{ color: '#142bad', marginRight: 5 }}
                  onClick={e => {
                    e.preventDefault();
                    onClick.bind(null, value, data, api, rowIndex)();
                  }}
                >
                  {typeof title === 'string' ? title : title(value, data)}
                </Link>
              )}
              {typeof icon === 'string' && (
                renderIcon(
                  <Icon
                    isActive={isActive && isActive(data)}
                    onClick={onClick.bind(null, value, data, api, rowIndex)}
                    style={{
                      color: icon === 'trash' ? '#ff5f5d' : '',
                      marginLeft: icon === 'trash' ? 7 : 0,
                    }}
                  >
                    {iconData[icon]}
                  </Icon>,
                  tooltip,
                )
              )}
              {typeof icon === 'function' && (
                renderIcon(
                  <Image
                    src={icon()}
                    onClick={onClick.bind(null, value, data, api, rowIndex)}
                  />,
                  tooltip,
                )
              )}
            </React.Fragment>
          ),
      )}
    </Wrapper>
  );
}

ActionCellRenderer.propTypes = {
  api: PropTypes.object,
  data: PropTypes.objectOf(PropTypes.any),
  icons: PropTypes.arrayOf(PropTypes.object),
  rowIndex: PropTypes.number.isRequired,
  value: PropTypes.string,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Icon = styled.i`
  color: ${props => (props.isActive ? '#36449d' : '#b0b0b0')};
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    color: #36449d;
  }

  & + & {
    margin-left: 15px;
  }
`;

const Image = styled.img`
  height: 16px;
  cursor: pointer;
  filter: grayscale(1) brightness(2);
  margin: 3px 5px 5px;

  &:hover {
    filter: none;
  }

  & + & {
    margin-left: 15px;
  }
`;

export default ActionCellRenderer;
