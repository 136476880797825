import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { useSelector } from 'react-redux';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from '@ag-grid-enterprise/all-modules'

// import { userDataSelector } from '@selectors/global';

// const mapStateToProps = createStructuredSelector({
//   userData: userDataSelector,
// });

const AgGrid = ({
  fetchPreference,
  userData,
  disablePreferenceSave,
  autoResize,
  autoResizeToFit,
  enableSortingSave,
  name,
  columnDefs,
  persistPreference,
  preference,
  onGridReady,
  onRowDataChanged,
  onColumnResized,
  onColumnVisible,
  onDragStopped,
  onSortChanged,
  ...otherProps
}) => {
  const AgGridApiRef = useRef()
  const AgGridColumnApiRef = useRef()
  const timer = useRef()

  useEffect(() => {
    window.addEventListener('resize', onResize)
    if (!disablePreferenceSave) {
      fetchPreference({
        userId: userData.get('id'),
      })
    }
  }, [])

  const autoSizeAll = () => {
    if (autoResizeToFit) {
      return AgGridApiRef.current.sizeColumnsToFit()
    }

    if (autoResize) {
      const allColumnIds = []

      if (
        !AgGridColumnApiRef.current ||
        !AgGridColumnApiRef.current.getAllColumns()
      ) {
        return
      }

      AgGridColumnApiRef.current.getAllColumns().forEach(column => {
        allColumnIds.push(column.colId)
      })

      AgGridColumnApiRef.current.autoSizeColumns(allColumnIds)
    }
  }

  const saveSortModel = (sortModel, name) => {
    if (window.localStorage) {
      const sortModels = window.localStorage.getItem('sortModels')
      let sortObject = {}

      if (sortModels) {
        sortObject = JSON.parse(sortModels)
      }

      sortObject[name] = sortModel
      window.localStorage.setItem('sortModels', JSON.stringify(sortObject))
    }
  }

  const applySortModel = () => {
    if (window.localStorage && enableSortingSave) {
      const sortModels = window.localStorage.getItem('sortModels')
      let sortObject = {}

      if (sortModels) {
        sortObject = JSON.parse(sortModels)
      }

      if (sortObject[name] && sortObject[name].length > 0) {
        return columnDefs.map(def => {
          if (def.field === sortObject[name][0].colId) {
            return {
              ...def,
              sort: sortObject[name][0].sort,
            }
          }

          return def
        })
      }
    }
    return columnDefs
  }

  const onSavePreference = params => {
    if (disablePreferenceSave || typeof persistPreference !== 'function') {
      return
    }

    clearTimeout(timer.current)

    timer.current = setTimeout(() => {
      const columnsWidth = {}

      params.columnApi.getColumnState().forEach(column => {
        columnsWidth[column.colId] = column.width
      })

      const movedColumns = params.columnApi
        .getColumnState()
        .map(column => column.colId)
      const visibleColumns = params.columnApi
        .getAllDisplayedColumns()
        .map(column => column.colId)
      const preferenceToPersist = preference.get('data').toJS()
      preferenceToPersist[name] = {
        ...preferenceToPersist[name],
        columnsWidth,
        movedColumns,
        visibleColumns,
      }
      if ('select' in preferenceToPersist[name].columnsWidth) {
        delete preferenceToPersist[name].columnsWidth['select']
      }
      for (let x = 0; x < preferenceToPersist[name].movedColumns.length; x++) {
        if (preferenceToPersist[name].movedColumns[x] === 'select') {
          preferenceToPersist[name].movedColumns.splice(x, 1)
        }
      }
      for (
        let x = 0;
        x < preferenceToPersist[name].visibleColumns.length;
        x++
      ) {
        if (preferenceToPersist[name].visibleColumns[x] === 'select') {
          preferenceToPersist[name].visibleColumns.splice(x, 1)
        }
      }

      persistPreference({
        userId: userData.get('id'),
        data: { preference: preferenceToPersist },
      })
    }, 500)
  }

  const onGridReadyHandler = params => {
    AgGridApiRef.current = params.api
    AgGridColumnApiRef.current = params.columnApi

    if (onGridReady) {
      onGridReady(params)
    }

    if (autoResize || autoResizeToFit) {
      setTimeout(autoSizeAll, 10)
    }
  }

  const onRowDataChangedHandler = params => {
    AgGridApiRef.current = params.api
    AgGridColumnApiRef.current = params.columnApi

    if (autoResize || autoResizeToFit) {
      setTimeout(autoSizeAll, 10)
    }

    if (onRowDataChanged) {
      onRowDataChanged(params)
    }
  }

  const onResize = () => {
    if ((autoResize || autoResizeToFit) && AgGridApiRef.current) {
      setTimeout(autoSizeAll, 10)
    }
  }

  const onColumnResizedHandler = value => {
    if (onColumnResized) {
      onColumnResized(value)
    }
    onSavePreference(value)
  }

  const onColumnVisibleHandler = value => {
    if (onColumnVisible) {
      onColumnVisible(value)
    }
    onSavePreference(value)
  }

  const onDragStoppedHandler = value => {
    if (onDragStopped) {
      onDragStopped(value)
    }
    onSavePreference(value)
  }

  const onSortChangedHandler = value => {
    if (onSortChanged) {
      onSortChanged(value)
    }

    if (enableSortingSave) {
      saveSortModel(value.api.getSortModel(), name)
    }
  }

  // if (!disablePreferenceSave && !preference.get('data')) {
  //   return null;
  // }

  return (
    <AgGridReact
      {...otherProps}
      columnDefs={applySortModel()}
      modules={AllModules}
      onColumnResized={onColumnResizedHandler}
      onColumnVisible={onColumnVisibleHandler}
      onDragStopped={onDragStoppedHandler}
      onGridReady={onGridReadyHandler}
      onRowDataChanged={onRowDataChangedHandler}
      onSortChanged={onSortChangedHandler}
    />
  )
}

AgGrid.propTypes = {
  autoResize: PropTypes.bool,
  autoResizeToFit: PropTypes.bool,
  columnDefs: PropTypes.any,
  disablePreferenceSave: PropTypes.bool,
  enableSortingSave: PropTypes.bool,
  fetchPreference: PropTypes.func,
  name: PropTypes.string.isRequired,
  onColumnResized: PropTypes.func,
  onColumnVisible: PropTypes.func,
  onDragStopped: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowDataChanged: PropTypes.func,
  onSortChanged: PropTypes.func,
  persistPreference: PropTypes.func,
  preference: PropTypes.object,
  userData: PropTypes.object,
}

export default AgGrid
