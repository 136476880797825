import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components'

import BlueTextInput from 'components/TextInputs/BlueTextInput';
import RoundedButton from 'components/Buttons/RoundedButton';
import { PopperWindow } from 'components/HoverContent';
import AppraisalNotice from 'assets/images/pid.png';

const AddPropertyDialog = ({
  isOpen,
  handleClose,
  onAdd,
}) => {
  const [pid, setPid] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const saveRecord = async () => {
    await onAdd(pid);
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <StyledDialogTitle>
        Add A Property
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </StyledDialogTitle>
      <DialogContent>
        <Wrapper>
          <span>TYPE IN PROPERTY ID</span>
          <BlueTextInput
            autoFocus
            value={pid}
            onChange={setPid}
            style={{ maxWidth: 150, marginBottom: 10 }}
            inputProps={{ style: { fontSize: 20, padding: 12 } }}
            onKeyPress={({ key }) => {
              if (key === 'Enter') saveRecord()
            }}
          />
          <Link
            to="#"
            onClick={e => {
              e.preventDefault();
              setAnchorEl(anchorEl ? null : e.currentTarget);
            }}
            style={{ fontSize: 12, position: 'relative' }}
          >
            Where can I find my Property ID?
          </Link>
          <PopperWindow anchorEl={anchorEl}>
            <img src={AppraisalNotice} style={{ width: 'calc(30vw)' }} alt="appraisal-notice" />
            <div style={{ width: 'calc(30vw)', paddingTop: 10 }}>
              The PROPERTY ID is printed in the upper left hand corner of the appraisal 
              notice that was mailed to you. Type this number in the purple box above to 
              add a property.
            </div>
          </PopperWindow>
          <RoundedButton color="info" onClick={saveRecord} style={{ minWidth: '66%' }}>
            ADD PROPERTY
          </RoundedButton>
        </Wrapper>
      </DialogContent>
    </Dialog>
  );
};

AddPropertyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const StyledDialogTitle = styled(DialogTitle)`
  background: white;
  text-transform: uppercase;

  > h2 {
    font-family: arial, sans-serif;
    font-weight: bold;
    color: black;
    font-size: 16px;
  }

  > h6 {
    font-family: arial, sans-serif;
    font-weight: bold;
    color: black;
  }
`;

const CloseButton = styled(IconButton)`
  &&& {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #888;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;

  > span {
    font-size: 15px;
    font-weight: bold;
    color: #142bad;
  }
`;

export default AddPropertyDialog;
