import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';

const DEFAULT_OPTIONS = {
  description: 'Are you sure you want to remove this',
  noAction: 'No',
  title: 'Confirm Delete',
  yesAction: 'Yes, delete',
  showCloseIcon: false,
  yesButtonStyle: {},
};

const getOption = (options, optionKey) =>
  ({ ...DEFAULT_OPTIONS, ...options }[optionKey]);

const ConfirmModal = ({
  confirmation,
  dismiss,
  options,
  proceed,
  show,
}) => (
  <Dialog
    aria-describedby="alert-dialog-description"
    aria-labelledby="alert-dialog-title"
    open={show}
    onClose={dismiss}
  >
    <DialogTitle id="alert-dialog-title">
      {getOption(options, 'title')}
      {getOption(options, 'showCloseIcon') && (
        <IconButton
          aria-label="close"
          onClick={dismiss}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {typeof getOption(options, 'description') === 'function'
          ? getOption(options, 'description')()
          : getOption(options, 'description')
        }
        {' '}
        {!options.description && `${confirmation}?`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {getOption(options, 'noAction') !== false && 
        <Button color="primary" onClick={dismiss}>
          {getOption(options, 'noAction')}
        </Button>
      }
      <Button
        autoFocus
        color="primary"
        onClick={proceed}
        style={getOption(options, 'yesButtonStyle')}
      >
        {getOption(options, 'yesAction')} {confirmation}
      </Button>
    </DialogActions>
  </Dialog>
);

const ConfirmDialog = confirmable(ConfirmModal);
const triggerConfirmation = createConfirmation(ConfirmDialog);

export const confirm = (value, options = {}) =>
  triggerConfirmation({ confirmation: value, options });

export default ConfirmDialog;
