import React from 'react';
import { makeStyles } from '@material-ui/styles';
import MainImage from 'assets/images/404pagenotfound.png';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center'
  },
}));

const Notification = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Message>404: Oops, the page you are looking for isn’t here.</Message>
      <ImageWrapper src={MainImage} />
    </div>
  );
};

const Message = styled.div`
  font-size: 25px;
  margin-bottom: 80px;
  margin-top: 50px;
`;

const ImageWrapper = styled.img`
  width: 100%;
`;

export default Notification;
