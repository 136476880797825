import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import DocketScheduleIcon from 'assets/images/docketScheduler.svg';

const PleaseWaitOverlay = ({ message }) =>
  <Overlay>
    <img
      src={DocketScheduleIcon}
      style={{ width: 150, marginBottom: 20 }}
      alt="icon"
    />
    <div>Please wait just a little bitty minute...</div>
    <div>{message}</div>
    <LinearProgress style={{ marginTop: 10, width: '50%' }} />
  </Overlay>

PleaseWaitOverlay.propTypes = {
  message: PropTypes.string,
};

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  opacity: 0.85;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10000;
  color: white;
  font-size: 20px;

  .MuiLinearProgress-barColorPrimary {
    background-color: #d0f400;
  }
`;

export default PleaseWaitOverlay;