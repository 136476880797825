import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import moment from 'moment';

import AgGrid, { ActionCellRenderer } from 'components/AgGrid';
import { FlexRow, FileUpload, confirm } from 'components';

const MAX_UPLOAD_SIZE_MB = 50;

const SubmitAoaModal = ({
  open,
  pids,
  user,
  handleClose,
  submitAoa,
  appealsActions,
}) => {
  const today = moment().format('YYYY-MM-DD');
  const expDate = moment().add(1, 'Year').format('YYYY-MM-DD');
  const [applicationDate, setApplicationDate] = useState(today);
  const [effectiveDate, setEffectiveDate] = useState(today);
  const [expirationDate, setExpirationDate] = useState(expDate);
  const [agentComment, setAgentComment] = useState('');
  const [allComms, setAllComms] = useState({
    chiefAppraiser: false,
    arb: false,
    taxingUnits: false,
    authToProtest: false,
    authResolveTax: false,
    authConfInfo: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const uploadedFilesRef = useRef();
  uploadedFilesRef.current = uploadedFiles;

  const onUpload = async files => {
    const formData = new FormData();
    files.forEach(file => {
      const ext = file.name.split('.').pop();
      if (
        ['jpeg', 'jpg', 'png', 'gif', 'pdf'].includes(ext) &&
        file.size < MAX_UPLOAD_SIZE_MB * 1024 * 1024
      ) {
        formData.append('uploadfiles', file);
      }
    });
    try {
      const resp = await appealsActions.uploadAgentFiles({
        payload: formData,
      });
      setUploadedFiles(resp.results);
    } catch (err) {
      console.log(err);
    }
  }

  const columnDefs = [
    {
      headerName: 'File',
      field: 'description',
    },
    {
      headerName: 'Delete',
      field: '',
      maxWidth: 100,
      cellRendererFramework: ActionCellRenderer,
      cellRendererParams: {
        icons: [
          {
            icon: 'trash',
            tooltip: 'Delete',
            isVisible: () => true,
            onClick: (_, record) =>
              confirm('file')
                .then(() => 
                  setUploadedFiles(
                    uploadedFilesRef.current.filter(f => f.s3ID !== record.s3ID)
                  ),
                )
                .catch(err => console.log(err))
          },
        ],
      },
    },
  ];

  const onSubmit = () => submitAoa({
    pIDList: pids,
    fileAttachments: uploadedFiles,
    applicationDt: applicationDate,
    effectiveDt: effectiveDate,
    expirationDt: expirationDate,
    mailingsARB: Number(allComms.arb),
    mailingsCAD: Number(allComms.chiefAppraiser),
    mailingsTaxingUnit: Number(allComms.taxingUnits),
    authorityProtest: Number(allComms.authToProtest),
    authorityResolveTaxMatters: Number(allComms.authResolveTax),
    authorityConfidential: Number(allComms.authConfInfo),
    agentComment: agentComment,
  });

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
        AGENT | {user.agentID} | {user.companyName}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FlexRow style={{ alignItems: 'stretch' }}>
          <PidContainer>
            <b>Selected PIDs:</b><br/>
            {pids.map(pid => <span key={pid}>{pid}</span>)}
          </PidContainer>
          <RightColumn>
            <FlexRow style={{ alignItems: 'stretch' }}>
              <RightColumn>
                <b>Application</b>
                <TextField
                  type="date"
                  label="Application Date"
                  value={applicationDate}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setApplicationDate(e.target.value)}
                  style={{ maxWidth: 200, marginTop: 10 }}
                />
                <TextField
                  type="date"
                  label="Effective Date"
                  value={effectiveDate}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setEffectiveDate(e.target.value)}
                  style={{ maxWidth: 200 }}
                />
                <TextField
                  type="date"
                  label="Expiration Date"
                  value={expirationDate}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setExpirationDate(e.target.value)}
                  style={{ maxWidth: 200 }}
                />
              </RightColumn>
              <CheckboxContainer>
                <b>Mailings</b>
                <FormControl component="fieldset" style={{ marginTop: 10 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allComms.chiefAppraiser}
                          onChange={e => setAllComms({
                            ...allComms,
                            chiefAppraiser: e.target.checked,
                          })}
                          name="Chief Appraiser"
                          style={{ padding: 5 }}
                        />}
                      label="All communications from the Chief Appraiser"
                      style={{ fontSize: 12 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allComms.arb}
                          onChange={e => setAllComms({
                            ...allComms,
                            arb: e.target.checked,
                          })}
                          name="arb"
                          style={{ padding: 5 }}
                        />}
                      label="All communications from the ARB"
                      style={{ fontSize: 12 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allComms.taxingUnits}
                          onChange={e => setAllComms({
                            ...allComms,
                            taxingUnits: e.target.checked,
                          })}
                          name="taxingUnits"
                          style={{ padding: 5 }}
                        />}
                      label="All communications from the taxing units"
                      style={{ fontSize: 12 }}
                    />
                  </FormGroup>
                </FormControl>
              </CheckboxContainer>
              <CheckboxContainer>
                <b>Authority</b>
                <FormControl component="fieldset" style={{ marginTop: 10 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allComms.authToProtest}
                          onChange={e => setAllComms({
                            ...allComms,
                            authToProtest: e.target.checked,
                          })}
                          name="authToProtest"
                          style={{ padding: 5 }}
                        />}
                      label="Authority to protest"
                      style={{ fontSize: 12 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allComms.authResolveTax}
                          onChange={e => setAllComms({
                            ...allComms,
                            authResolveTax: e.target.checked,
                          })}
                          name="authResolveTax"
                          style={{ padding: 5 }}
                        />}
                      label="Authority to resolve tax matters"
                      style={{ fontSize: 12 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allComms.authConfInfo}
                          onChange={e => setAllComms({
                            ...allComms,
                            authConfInfo: e.target.checked,
                          })}
                          name="authConfInfo"
                          style={{ padding: 5 }}
                        />}
                      label="Authority to receive confidential information"
                      style={{ fontSize: 12 }}
                    />
                  </FormGroup>
                </FormControl>
              </CheckboxContainer>
            </FlexRow>
            <TextField
              fullWidth
              label="Comment"
              value={agentComment}
              onChange={e => setAgentComment(e.target.value)}
              style={{ marginLeft: 10, marginRight: 10 }}
            />
            <UploadWrapper>
              <FileUpload multiple onChange={onUpload}/>
              <div style={{ height: '100%', flex: 3 }} className="ag-theme-custom" >
                <AgGrid
                  disablePreferenceSave
                  autoResizeToFit
                  defaultColDef={{ resizable: true, sortable: true }}
                  columnDefs={columnDefs}
                  name="files"
                  rowData={uploadedFiles}
                />
              </div>
            </UploadWrapper>
          </RightColumn>
        </FlexRow>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
          style={{ marginRight: 20 }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={!uploadedFiles.length}
        >
          Upload AOA
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SubmitAoaModal.propTypes = {
  appealsActions: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  pids: PropTypes.array,
  submitAoa: PropTypes.func.isRequired,
  user: PropTypes.object,
}

const CheckboxContainer = styled.div`
  display: flex;
  flex-Direction: column;
  margin-top: 10px;
  justify-content: flex-start;

  .MuiFormControlLabel-label {
    font-size: 12px;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 10px;
`;

const PidContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  max-height: 45vh;
  max-width: 20%;
  width: 100%;
  overflow: scroll;
`;

const UploadWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 10px;

  > div {
    height: 100%;
    margin-right: 20px;
    flex: 2;
  }
`;

export default SubmitAoaModal