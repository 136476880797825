const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

const conditionalPresence = (value, options, key, attributes) => {
  if (attributes[options.field] && !value)
    return options.message;
}

export default {
  checked,
  conditionalPresence,
};
