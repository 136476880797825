/* eslint-disable */
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import * as userActions from '../data/actions/user';
import * as globalActions from '../data/actions/global';
import * as propertiesActions from '../data/actions/properties';
import * as appealsActions from '../data/actions/appeals';

const useActions = actions => deps => {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      Object.keys(actions).reduce(
        (acc, key) => ({
          ...acc,
          [key]:
            typeof actions[key] !== 'string'
              ? bindActionCreators(actions[key], dispatch)
              : null,
        }),
        {},
      ),
    deps ? [dispatch, ...deps] : deps,
  );
}

export const useUserActions = useActions(userActions);
export const useGlobalActions = useActions(globalActions);
export const usePropertiesActions = useActions(propertiesActions);
export const useAppealsActions = useActions(appealsActions);
