import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
// import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button'

import AgGrid from 'components/AgGrid'
import { StyledCard, FlexRow } from 'components/Styled/Common'

const AppointmentSearch = ({ search, timeSlots, setSelectedTimeslot }) => {
  const [startDt, setStartDt] = useState('')
  const [endDt, setEndDt] = useState('')
  // const [virtualAppraiserChecked, setVirtualAppraiserChecked] = useState(false);
  // const [virtualArbChecked, setvirtualArbChecked] = useState(false);

  const handleSearch = () => search(startDt, endDt)

  const columnDefs = [
    {
      headerName: 'Select',
      field: '',
      checkboxSelection: true,
      maxWidth: 90,
      minWidth: 90,
      width: 90,
      lockPosition: true,
      suppressMovable: true,
    },
    {
      headerName: 'Date',
      field: 'startDt',
    },
    {
      headerName: 'Location',
      field: 'locationName',
    },
    {
      headerName: 'Slots Available',
      field: 'hearingCapacity',
    },
  ]

  const handleSelectionChanged = event => {
    setSelectedTimeslot(event.api.getSelectedRows()[0].docketTimeSlotID)
  }

  return (
    <StyledCard lightBorder style={{ flex: 1, padding: '5px 10px' }}>
      <FlexRow style={{ fontSize: 12, marginBottom: 5 }}>
        Search for available dates:
        <TextField
          type="date"
          value={startDt}
          onChange={e => setStartDt(e.target.value)}
          style={{ marginLeft: 10, marginRight: 10 }}
        />{' '}
        to
        <TextField
          type="date"
          value={endDt}
          onChange={e => setEndDt(e.target.value)}
          style={{ marginLeft: 10, marginRight: 10 }}
        />
        {/* <CheckboxContainer>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={virtualAppraiserChecked}
                    onChange={e => setVirtualAppraiserChecked(e.target.checked)}
                    name="virtualAppraiser"
                  />}
                label="Virtual Appraiser"
                style={{ fontSize: 12 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={virtualArbChecked}
                    onChange={e => setvirtualArbChecked(e.target.checked)}
                    name="virtualArb"
                  />}
                label="Virtual ARB"
                style={{ fontSize: 12 }}
              />
            </FormGroup>
          </FormControl>
        </CheckboxContainer> */}
        {startDt && endDt && (
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        )}
      </FlexRow>
      <StyledCard style={{ flex: 1, marginTop: 0, boxShadow: 'none' }}>
        <AgGridWrapper className="ag-theme-custom">
          <AgGrid
            disablePreferenceSave
            autoResizeToFit
            columnDefs={columnDefs}
            name="apptSearch"
            rowData={timeSlots}
            onSelectionChanged={handleSelectionChanged}
            rowSelection="single"
          />
        </AgGridWrapper>
      </StyledCard>
    </StyledCard>
  )
}

AppointmentSearch.propTypes = {
  setSelectedTimeslot: PropTypes.func.isRequired,
}

// const CheckboxContainer = styled.div`
//   display: flex;
//   flex-Direction: column;

//   .MuiFormControlLabel-label {
//     font-size: 12px;
//   }
// `;

const AgGridWrapper = styled.div`
  height: 200px;
`

export default AppointmentSearch
