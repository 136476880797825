import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { useAppealsActions } from 'hooks/useActions'
import { FlexRow } from 'components/Styled/Common'
import RoundedButton from 'components/Buttons/RoundedButton'

const ScheduleHearingDialog = ({
  open,
  appealId,
  handleClose,
  selectManual,
  onWithdraw,
}) => {
  const [firstAvailSelected, setFirstAvailSelected] = useState(false)
  const [firstTimeslot, setFirstTimeslot] = useState()
  const [skipList, setSkipList] = useState([])
  const appealsActions = useAppealsActions([])

  const fetchFirstAvailable = async e => {
    e.preventDefault()
    setFirstAvailSelected(true)
    const resp = await appealsActions.fetchFirstAvailableDocketTime(skipList)
    if (resp) {
      setFirstTimeslot(resp.results[0])
      setSkipList([...skipList, resp.results[0].docketTimeSlotID])
    }
  }

  const handleConfirm = async () => {
    const resp = await appealsActions.scheduleAppeal({
      payload: {
        timeSlotID: firstTimeslot.docketTimeSlotID,
        appealID: appealId,
        protesterInitiated: 1,
      },
    })
    if (resp.results.scheduled) {
      handleClose('confirm')
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography id="alert-dialog-title">
        SCHEDULE HEARING DATE
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IntroText>
          Would you like have the system select the first available hearing date
          automatically or select the hearing date manually?
        </IntroText>
        <FlexRow style={{ width: '100%' }}>
          <RoundedButton
            color={firstAvailSelected ? 'secondary' : 'info'}
            style={{ flex: 1, marginRight: 20 }}
            onClick={fetchFirstAvailable}
          >
            SELECT FIRST AVAILABLE
          </RoundedButton>
          <RoundedButton
            color="info"
            style={{ flex: 1 }}
            onClick={selectManual}
          >
            SELECT MANUALLY
          </RoundedButton>
        </FlexRow>
        {firstAvailSelected && (
          <>
            <IntroText>The first available time slot is:</IntroText>
            <FlexRow style={{ justifyContent: 'center', width: '100%' }}>
              <div
                style={{
                  borderRight: '1px solid black',
                  flex: 1,
                  minHeight: 90,
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Link to="#" onClick={fetchFirstAvailable}>
                  Show Me Another Time Slot
                </Link>
              </div>
              {firstTimeslot && (
                <div style={{ flex: 1 }}>
                  <TimeslotData>
                    <label>Date:</label>
                    <span>
                      {moment(
                        firstTimeslot.startDt,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('ll')}
                    </span>
                  </TimeslotData>
                  <TimeslotData>
                    <label>Time:</label>
                    <span>
                      {moment(
                        firstTimeslot.startDt,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('h:mma')}
                    </span>
                  </TimeslotData>
                  <TimeslotData>
                    <label>Location:</label>
                    <span>{firstTimeslot.locationName}</span>
                  </TimeslotData>
                </div>
              )}
            </FlexRow>
            <RoundedButton
              onClick={handleConfirm}
              color="success"
              style={{ marginBottom: 0 }}
            >
              Confirm
            </RoundedButton>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onWithdraw} color="primary">
          Withdraw Protest
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ScheduleHearingDialog.propTypes = {
  appealId: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectManual: PropTypes.func.isRequired,
}

const IntroText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const TimeslotData = styled.div`
  display: flex;
  flex: 1;
  text-align: left;

  label {
    min-width: 100px;
  }

  span {
    color: #142bad;
    font-weight: bold;
  }
`

export default ScheduleHearingDialog
