
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

import { Header } from './components';
import {
  getUserProperties,
  getAgentProperties,
  getUserPropertyHistory,
  getAgentPropertyHistory,
} from 'data/selectors/properties';
import { useAppealsActions, usePropertiesActions } from 'hooks/useActions';
import { ContentHeader } from 'components';
import { FlexRow } from 'components/Styled/Common';
import { PropertyCard } from 'components';
import { AppointmentSearch } from './components';
import RoundedButton from 'components/Buttons/RoundedButton';

const ManualProtestSchedule = ({ history, match, user, isAgent }) => {
  const { appealId } = match.params;
  const [date, setDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState();
  const appealsActions = useAppealsActions([]);
  const propertyActions = usePropertiesActions([]);
  const userProperties = useSelector(getUserProperties);
  const agentProperties = useSelector(getAgentProperties);
  const properties = isAgent ? agentProperties : userProperties;
  const property = properties.data.find(p => p.appealID === Number(appealId));
  const userPropertyHistory = useSelector(getUserPropertyHistory);
  const agentPropertyHistory = useSelector(getAgentPropertyHistory);
  const propertyHistory = isAgent ? agentPropertyHistory : userPropertyHistory;

  useEffect(() => {
    if (isAgent && (!properties || !properties.length)) {
      propertyActions.getAgentProperties({ agentId: user.agentID })
    }
    if (!isAgent && (!properties || !properties.length)) {
      propertyActions.getUserProperties({ userId: user.id, includeAppeals: true })
    }
  }, []);

  useEffect(() => {
    if (
      !isAgent &&
      property &&
      property.publicUserPropertyID !== propertyHistory.data.publicUserPropertyID
    ) {
      propertyActions.getUserPropertyHistory({
        publicUserPropertyId: property.publicUserPropertyID,
      })
    }
    if (
      isAgent &&
      property &&
      property.pID !== propertyHistory.data.pID
    ) {
      propertyActions.getAgentPropertyHistory({
        propertyId: property.pID,
      })
    }
  }, [property]);

  const handleDateChange = date => {
    const beginDt = date.format('YYYY-MM-DD');
    const endDt = beginDt;
    setDate(beginDt);
    search(beginDt, endDt);
  }

  const search = async (beginDt, endDt) => {
    const resp = await appealsActions.fetchAppealDocketTimeSlots({ beginDt, endDt });
    if (resp && resp.results) {
      setTimeSlots(resp.results);
    } else {
      setTimeSlots([]);
    }
  }

  const handleSchedule = async () => {
    const resp = await appealsActions.scheduleAppeal({
      payload: {
        timeSlotID: selectedTimeslot, 
        appealID: appealId,
        protesterInitiated: 1,
      },
    })
    if (resp.results.scheduled) {
      history.push(isAgent ? '/my-properties' : '/protest')
    }
  }

  return (
    <div>
      <ContentHeader title="SCHEDULE APPRAISAL REVIEW BOARD" />
      <Header />
      <Container>
        <div style={{ paddingRight: 50, paddingLeft: 50, marginBottom: 20 }}>
          Search for available dates or pick from the calendar.
        </div>
        <FlexRow style={{ alignItems: 'flex-start' }}>
          <ColumnLeft>
            <PropertyCard
              property={propertyHistory.data}
              style={{ marginRight: 20 }}
            />
            <AppointmentSearch
              search={search}
              timeSlots={timeSlots}
              setSelectedTimeslot={setSelectedTimeslot}
            />
            <RoundedButton
              color="info" 
              onClick={handleSchedule}
              style={{ width: 200 }}
              disabled={!selectedTimeslot}
            >
              SCHEDULE
            </RoundedButton>
          </ColumnLeft>
          <ColumnRight>
            <DatePicker
              autoOk
              disablePast
              format="YYYY-MM-DD"
              fullWidth
              disableToolbar
              variant="static"
              openTo="date"
              value={date}
              onChange={handleDateChange}
              style={{ width: '50%' }}
            />
          </ColumnRight>
        </FlexRow>
      </Container>
    </div>
  );
};

ManualProtestSchedule.propTypes = {
  history: PropTypes.object,
  isAgent: PropTypes.bool,
  match: PropTypes.object,
  user: PropTypes.object,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  text-align: center;
`;

const ColumnLeft = styled.div`
  display: flex;
  flex: 1;
  width: 50%;
  flex-direction: column;
  align-items: center;
`;

const ColumnRight = styled.div`
  display: flex;
  flex: 1;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > div {
    transform: scale(1.5) translateY(60px);
  }
`;

export default ManualProtestSchedule;
