import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { usePropertiesActions, useGlobalActions } from 'hooks/useActions';
import { ContentHeader, ContentSection, FlexRow } from 'components';
import { userSelector } from 'data/selectors/user';
import { getProperties } from 'data/selectors/properties';
import { getGlobalUserMessage, getSessionConfig } from 'data/selectors/global';
import { actionCards } from './actionCardData';
import AgGrid from 'components/AgGrid';
import { getCommaFormatted } from 'lib/utils';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

const Home = ({ history }) => {
  const classes = useStyles();
  const [agentSummary, setAgentSummary] = useState();
  const userMessage = useSelector(getGlobalUserMessage);
  const user = useSelector(userSelector);
  const properties = useSelector(getProperties)
  const config = useSelector(getSessionConfig);
  const propertiesActions = usePropertiesActions([]);
  const globalActions = useGlobalActions([]);

  const userType = user.agentID === 0 ? 'Owner' : 'Agent'
  const isAgent = user.agentID !== 0;

  useEffect(() => {
    if (isAgent) {
      globalActions.fetchAgentSummary({ agentId: user.agentID })
        .then(({ results }) => setAgentSummary(results[0]))
      propertiesActions.getAgentProperties({ agentId: user.agentID });
    } else {
      propertiesActions.getUserProperties({ userId: user.id })
    }
  }, []);

  const columnDefs = [
    {
      headerName: 'Prop ID',
      field: 'pID',
      maxWidth: 100,
    },
    {
      headerName: 'Type',
      field: 'propType',
      maxWidth: 100,
    },
    {
      headerName: 'Owner Name',
      field: 'name',
    },
    {
      headerName: 'Legal Description',
      field: 'legalDescription',
    },
    {
      headerName: 'Situs Address',
      field: 'streetPrimary',
    },
    {
      headerName: 'DBA',
      field: 'dba',
    },
    {
      headerName: 'Protest Deadline',
      field: 'protestDeadlineDt',
      valueFormatter: ({ value }) => value &&
        moment(value, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY h:mma')
    },
  ];

  return (
    <div className={classes.root}>
      <ContentHeader title={`Welcome to the ${config.cadName} ${userType} Portal`}>
        {/* <Link
          to="#"
          style={{ fontSize: 12 }}
        >
          YOU HAVE NEW MESSAGES
        </Link> */}
      </ContentHeader>
      <ContentSection style={{ minHeight: 200 }}>
        <h2>{user.name} | {user.id} | {user.email}</h2><br />
        {userMessage}
      </ContentSection>
      {isAgent && (
        <ContentSection style={{ minHeight: 100 }}>
          <h2>SUMMARY</h2><br />
          {agentSummary ? (
            <SummaryRow>
              <div>
                Active Properties:{' '}
                <Link to="/my-properties">
                  {getCommaFormatted(agentSummary.activeProperties)}
                </Link>
              </div>
              <div>
                Needs AOA/Expired Properties:{' '}
                {getCommaFormatted(agentSummary.expiredProperties)}
              </div>
              <div>
                Open Protest{' '}
                {getCommaFormatted(agentSummary.openAppeals)}
              </div>
              <div>
                Closed Protest:{' '}
                {getCommaFormatted(agentSummary.closedAppeals)}
              </div>
              <div>
                Agent ID:{' '}
                {user.agentID}
              </div>
            </SummaryRow>
          ) : <CircularProgress />}
        </ContentSection>
      )}
      
      <ContentSection style={{ minHeight: 100 }}>
        <h2>MY PROPERTIES</h2><br />
        <FlexRow>
          <span>
            {!isAgent && 'Some actions require a Prop ID & PIN.  '}Click{' '}
            <Link to="/my-properties">here</Link> to Manage Properties.{' '}
          </span>
        </FlexRow>
        {!isAgent && (
          <AgGridWrapper className="ag-theme-custom" fixedHeight={isAgent}>
            <AgGrid
              disablePreferenceSave
              autoResizeToFit
              columnDefs={columnDefs}
              defaultColDef={{ resizable: true, sortable: true }}
              name="userProperties"
              rowData={properties.data}
              domLayout={!isAgent ? 'autoHeight' : null}
            />
          </AgGridWrapper>
        )}
      </ContentSection>
      <ContentSection style={{ minHeight: 200 }}>
        <h2>ACTIONS</h2><br />
        On this Portal you can do the following...
        <ActionCardListing>
          {actionCards
            .filter(i => i.userTypes.includes(userType.toLowerCase()))
            .map(({ title, content, link }) => (
              <StyledCard
                key={link}
                onClick={() => history.push(link)}
              >
                <CardContent>
                  {content}
                </CardContent>
                <StyledCardActions>
                  {title}
                </StyledCardActions>
              </StyledCard>
            ))
          }
        </ActionCardListing>
      </ContentSection>
    </div>
  );
};

Home.propTypes = {
  history: PropTypes.object,
}

const ActionCardListing = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 120px;
  margin: 8px 16px 8px 0;
  font-size: 12px;
  transition: transform .2s;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
  }
`;

const StyledCardActions = styled(CardActions)`
  justify-content: center;
  width: 100%;
  background: #142bad;
  color: white;
  font-weight: bold;
`;

const SummaryRow = styled.div`
  display: flex;
  
  > div {
    width: 300px;
  }
`;

const AgGridWrapper = styled.div`
  margin-top: 10px;
  height: ${({ fixedHeight }) => fixedHeight ? '40vh' : '100%'};
`;

export default Home;
