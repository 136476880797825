import { get } from 'lodash'

export const urlsSelector = state => get(state, ['global', 'config', 'urls'])

export const officeSelector = state => get(state, ['global', 'office'])

export const officeLookupSelector = state =>
  get(state, ['global', 'officeLookup'])

export const maintenanceSelector = state =>
  get(state, ['global', 'maintenance', 'data'])

export const authenticationTokenSelector = state =>
  get(state, ['user', 'user', 'token'])

export const getGlobalUserMessage = state =>
  get(state, ['global', 'sessionConfig', 'data', 'globalUserMessage'])

export const getMaxCommentLength = state =>
  get(state, ['global', 'sessionConfig', 'data', 'maxCommentLength'])

export const getSessionConfig = state =>
  get(state, ['global', 'sessionConfig', 'data'])

export const codeFileSelector = state =>
  get(state, ['global', 'codeFiles', 'data'])

export const getProtestStatusList = state =>
  get(state, ['global', 'protestStatusList', 'data'])

export const getAgentStatusList = state =>
  get(state, ['global', 'agentStatusList', 'data'])
