import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(2),
  },
}));

const BlueTextInput = props => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <StyledTextField
      className={classes.textField}
      variant="outlined"
      theme={theme}
      color="info"
      {...props}
    />
  );
};

BlueTextInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 3px;
    background: transparent;
    color: white !important;

    input: {
      color: white !important;
      background: transparent !important;
    }

    fieldset {
      border-color: white !important;
      border-width: 2px;

      && .Mui-focused {
        border-color: ${({ theme }) => theme.palette.info.main} !important;
      }
    }

    &.Mui-focused {
      .MuiInputLabel-root {
        color: ${({ theme }) => theme.palette.info.main};
      }
      fieldset {
        border-color: ${({ theme }) => theme.palette.info.main} !important;
        legend {
          color: ${({ theme }) => theme.palette.info.main} !important;
        }
      }
    }
  }

  .MuiInputLabel-root {
    color: white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export default BlueTextInput;
