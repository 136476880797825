
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FlexRow } from 'components/Styled/Common';
import { PropertyCard } from 'components';
import { PopperWindow, EfilePinHelpPopper } from 'components/HoverContent';
import BlueTextInput from 'components/TextInputs/BlueTextInput';
import RoundedButton from 'components/Buttons/RoundedButton';
import AppraisalNotice from 'assets/images/pid.png';
import { onlyNumbersNormalizer } from 'lib/utils';

const EnterPropertyPin = ({
  propertyId,
  pin,
  property,
  insertProperty,
  updatePin,
  goToNext,
}) => {
  const [pid, setPid] = useState('');
  const [pinInput, setPinInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <WalkthruContent>
      {propertyId 
        ? `An E-File PIN is required in order to file a protest of value. 
          Please enter it in the space provided below.`
        : `Please enter in your Property ID and E-FILE PIN to add a property and 
          initiate a new protest.`
      }
      <FlexRow style={{ margin: '20px 50px', width: '100%', alignItems: 'flex-start' }}>
        {!propertyId
          ? (   
            <Wrapper>
              <span>TYPE IN PROPERTY ID</span>
              <BlueTextInput
                autoFocus={!propertyId}
                value={pid}
                onChange={setPid}
                normalize={onlyNumbersNormalizer}
                style={{ maxWidth: 150, marginBottom: 10 }}
                inputProps={{ style: { fontSize: 20, padding: 12 } }}
              />
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  setAnchorEl(anchorEl ? null : e.currentTarget);
                }}
                style={{ fontSize: 12, position: 'relative' }}
              >
                Where can I find my Property ID?
              </Link>
              <PopperWindow anchorEl={anchorEl} close={() => setAnchorEl(null)}>
                <img
                  src={AppraisalNotice}
                  alt="appraisal-notice"
                  style={{ width: 'calc(30vw)' }} 
                />
                <div style={{ width: 'calc(30vw)', paddingTop: 10 }}>
                  The PROPERTY ID is printed in the upper left hand corner of the
                  appraisal notice that was mailed to you. Type this number in the
                  purple box above to add a property.
                </div>
              </PopperWindow>
            </Wrapper>
          ) : (
            <PropertyCard
              property={property}
              blurImage={!pin}
              blurMessage={
                'The property photo will display after E-FILE PIN is ' +
                'successfully submitted.'
              }
              style={{ marginRight: 20 }}
            />
          )}
        <Wrapper>
          {pin && property.pinStatus !== 'View Only' ? (
            <>
              <span>PIN ON FILE</span><br/><br/>
              <h3>{pin}</h3>
              <RoundedButton
                color="info" 
                onClick={goToNext}
                style={{ width: 200 }}
              >
                NEXT
              </RoundedButton>
            </>
          ) : (
            <>
              <span>TYPE IN E-FILE PIN</span>
              <BlueTextInput
                autoFocus={!!propertyId}
                value={pinInput}
                onChange={setPinInput}
                normalize={onlyNumbersNormalizer}
                style={{ maxWidth: 150, marginBottom: 10 }}
                inputProps={{ style: { fontSize: 20, padding: 12 } }}
              />
              {propertyId && (
                <RoundedButton
                  color="info"
                  onClick={() => updatePin(pinInput)}
                  style={{ width: 200 }}
                  disabled={!pinInput}
                >
                  SUBMIT E-FILE PIN
                </RoundedButton>
              )}
            </>
          )}
          <EfilePinHelpPopper />
          {/* <Link
            to="#"
            onClick={e => {
              e.preventDefault();
            }}
            style={{ fontSize: 12, marginTop: 10 }}
          >
            Click here if you do not have an E-FILE PIN
            and would like to request one from the appraisal office.
          </Link> */}
        </Wrapper>
      </FlexRow>
      {!propertyId && (
        <RoundedButton
          color="info"
          onClick={() => insertProperty(pid, pinInput)}
          style={{ minWidth: '66%' }}
          disabled={!pid || !pinInput}
        >
          ADD PROPERTY and Initiate a new protest
        </RoundedButton>
      )}
    </WalkthruContent>
  )
}

EnterPropertyPin.propTypes = {
  goToNext: PropTypes.func.isRequired,
  insertProperty: PropTypes.func.isRequired,
  pin: PropTypes.string,
  property: PropTypes.object,
  propertyId: PropTypes.string,
  updatePin: PropTypes.func.isRequired,
}

const WalkthruContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  align-items: center;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;

  > span {
    font-size: 15px;
    font-weight: bold;
    color: #142bad;
  }
`;

export default EnterPropertyPin;
