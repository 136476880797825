import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import { get } from 'lodash';

import { Alert } from 'components';
import { userSelector } from 'data/selectors/user';
import { usePropertiesActions, useAppealsActions } from 'hooks/useActions';
import { getProperties } from 'data/selectors/properties';
import { ContentHeader, ContentSection } from 'components';
import { ScheduleHearingDialog } from 'components/HoverContent';
import { FlexRow } from 'components/Styled/Common';
import { PropertyCard } from './components';
import useWithdrawAppeal from 'hooks/useWithdrawAppeal';

const Protest = ({ history }) => {
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [error, setError] = useState();
  const [errorOpen, setErrorOpen] = useState(false);
  const propertyActions = usePropertiesActions([]);
  const appealsActions = useAppealsActions([]);
  const user = useSelector(userSelector);
  const properties = useSelector(getProperties);
  const withdrawAppeal = useWithdrawAppeal()

  useEffect(() => {
    loadPropertyList();
  }, []);

  const loadPropertyList = () =>
    propertyActions.getUserProperties({ userId: user.id, includeAppeals: true })

  const openRecommendation = async (recommendationId, publicUserPropertyId) => {
    await appealsActions.setRecommendationPortalView({ recommendationId });
    await appealsActions.fetchAppealRecommendation({
      recommendationId,
    });
    history.push(
      `/protest-review-recommendation?publicUserPropertyId=${publicUserPropertyId}`,
    );
  }

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return
    setErrorOpen(false);
  };

  return (
    <div>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          {error}
        </Alert>
      </Snackbar>
      {!!scheduleDialogOpen &&
        <ScheduleHearingDialog
          open={!!scheduleDialogOpen}
          appealId={scheduleDialogOpen}
          handleClose={() => {
            loadPropertyList();
            setScheduleDialogOpen(false);
          }}
          selectManual={() =>
            history.push(`/protest-manual-schedule/${scheduleDialogOpen}`)
          }
          onWithdraw={() => {
            setScheduleDialogOpen(false);
            withdrawAppeal(scheduleDialogOpen)
              .catch(err => {
                setError(get(err, 'body.warning', 'There was an issue'))
                setErrorOpen(true)
              });
          }}
        />
      }
      <ContentHeader title="PROTEST SUMMARY" />
      <ContentSection style={{ minHeight: 200 }}>
        <div style={{ paddingRight: 50, marginBottom: 20 }}>
          If you wish to file a protest on a property (also called an appeal), 
          feel free to add the property here. You will be asked for an E-FILE PIN. 
          The PIN can be found on the Appraisal Notice form that was mailed to you.
          <br /><br />
          If you do not have an E-FILE PIN, please email or contact the appraisal 
          office and one will be provided for you.
        </div>
        <FlexRow>
          <h2>MY PROPERTIES</h2>
          <FlexRow>
            <em>Click + to add a property and initiate a protest.</em>
            <Fab
              color="primary"
              size="small"
              onClick={() => history.push('/protest-walkthru')}
              style={{ marginLeft: 10 }}
            >
              <AddIcon />
            </Fab>
          </FlexRow>
        </FlexRow>
        <ProtestListWrapper>
          {!isEmpty(properties.data) && properties.data.map((property, index) =>
            <PropertyCard
              key={`${property.pID}-${property.appealID}`}
              index={index}
              history={history}
              property={property}
              openScheduleDialog={() => setScheduleDialogOpen(property.appealID)}
              openRecommendation={() => openRecommendation(
                property.recommendationID,
                property.publicUserPropertyID,
              )}
              onWithdraw={() =>
                withdrawAppeal(property.appealID)
                  .catch(err => {
                    setError(get(err, 'body.warning', 'There was an issue'))
                    setErrorOpen(true)
                  })
              }
            />
          )}
        </ProtestListWrapper>
      </ContentSection>
    </div>
  );
};

Protest.propTypes = {
  history: PropTypes.object,
}

const ProtestListWrapper = styled.div`
  width: 100%;

  .MuiPaper-root:nth-child(odd) {
    background: #f8f9ff;
  }
`;

export default Protest;
