
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import validate from 'validate.js';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Snackbar from '@material-ui/core/Snackbar';
import { get } from 'lodash';

import AgGrid, { ActionCellRenderer } from 'components/AgGrid';
import useCodefiles from 'hooks/useCodefiles';
import { useAppealsActions, useGlobalActions } from 'hooks/useActions';
import { getSessionConfig } from 'data/selectors/global'
import { userSelector } from 'data/selectors/user';
import { FlexRow, RedAsterisk } from 'components/Styled/Common';
import {
  BlueCheckboxGroup,
  PleaseWaitDialog,
  ContentHeader,
  PidImportModal,
  Alert,
} from 'components';
import { BasicDialog } from 'components/HoverContent';
import { StyledCard } from 'components/Styled/Common';
import BlueTextInput from 'components/TextInputs/BlueTextInput';
import RoundedButton from 'components/Buttons/RoundedButton';

const schema = {
  reasons: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const AgentCreateProtest = ({ history }) => {
  const [comments, setComments] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorOpen, setErrorOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [propId, setPropId] = useState('');
  const [propIds, setPropIds] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [properties, setProperties] = useState([]);
  const appealsActions = useAppealsActions([]);
  const globalActions = useGlobalActions([]);
  const getOptions = useCodefiles();
  const user = useSelector(userSelector)
  const config = useSelector(getSessionConfig);
  const propIdsRef = useRef();
  const propsRef = useRef();
  propIdsRef.current = propIds;
  propsRef.current = properties;
  const allValid =
    properties && 
    properties.length &&
    properties.every(p => p.propertyState === 'valid')

  useEffect(() => {
    globalActions.fetchCodeFile({ type: 'Appeals', name: 'Reasons' })
  }, []);

  useEffect(() => {
    (async () => {
      if (propIds && propIds.length) {
        try {
          const newProps = await appealsActions.validatePidList({ pIdList: propIds });
          setProperties([...properties, ...newProps.results]);
        } catch (err) {
          console.log(err.body);
          setErrMsg(get(err, 'body.error.error', 'Error validating PID list'));
          setErrorOpen(true);
        }
      }
    })();
  }, [propIds])

  useEffect(() => {
    const errors = validate({ reasons }, schema);
    setIsValid(errors ? false : true )
    setErrors(errors || {});
  }, [reasons]);

  const handleSubmit = async () => {
    setSaving(true);
    await appealsActions.massCreateAgentProtest({
      agentId: user.agentID,
      payload: {
        pIDList: properties.map(p => p.pID),
        reasonCodes: reasons,
        claimantComments: comments,
      },
    })
    setTimeout(() => history.push('/my-properties'), 5000);
  }

  const columnDefs = [
    {
      headerName: 'PID',
      field: 'pID',
      maxWidth: 90,
    },
    {
      headerName: 'Owner Name',
      field: 'name',
    },
    {
      headerName: 'Status',
      field: 'propertyState',
    },
    {
      headerName: '',
      field: 'actions',
      maxWidth: 90,
      cellRendererFramework: ActionCellRenderer,
      cellRendererParams: {
        icons: [
          {
            icon: 'trash',
            tooltip: 'Delete',
            isVisible: () => true,
            onClick: (_, record) =>
              setProperties(propsRef.current.filter(p => p.pID !== record.pID)),
          },
        ],
      },
    }
  ];

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return
    setErrorOpen(false);
  };

  return (
    <Container>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          {errMsg}
        </Alert>
      </Snackbar>
      <PidImportModal
        open={importModalOpen}
        handleClose={(pids = []) => {
          setPropIds([...new Set([...pids])].filter(i => !!i));
          setImportModalOpen(false);
        }}
      />
      <ContentHeader title="MASS PROTEST (by list)" />
      <div style={{ width: '100%', fontWeight: 'bold', padding: 10 }}>
        Properties Selected:{' '}
        <span style={{ color: '#142bad' }}>
          {properties && properties.length}
        </span>
      </div>
      <FlexRow style={{ width: '100%', alignItems: 'stretch', padding: 10 }}>
        <ImportContainer>
          <FlexRow style={{ alignItems: 'flex-end' }}>
            <TextField
              label="Prop ID"
              placeholder="Enter Prop ID"
              value={propId}
              onChange={v => setPropId(v.target.value)}
              style={{ margin: 10 }}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  setPropIds([propId]);
                  setPropId('');
                }
              }}
            />
            <Button color="primary" onClick={() => setImportModalOpen(true)}>
              Import
            </Button>
          </FlexRow>
          <PropCard>
            <StyledCardContent>
              <AgGridWrapper className="ag-theme-custom">
                <AgGrid
                  disablePreferenceSave
                  autoResizeToFit
                  columnDefs={columnDefs}
                  defaultColDef={{ resizable: true, sortable: true }}
                  name="agentProperties"
                  rowData={properties}
                />
              </AgGridWrapper>
              {!!properties && !!properties.length && !allValid && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ maxWidth: 180, marginTop: 5 }}
                  onClick={() =>
                    setProperties(propsRef.current.filter(p => p.propertyState === 'valid'))
                  }
                >
                  Remove all invalid
                </Button>
              )}
            </StyledCardContent>
          </PropCard>
        </ImportContainer>
        <div style={{ flex: 1 }}>
          <StyledCard
            lightBorder
            style={{ flex: 1, padding: '10px', margin: 0, marginRight: 30, textAlign: 'left' }}
          >
            <em>
              Record your reasons for filing a protest on your property. <br/>
              You may select multiple reasons.
            </em><br/>
            <FlexRow 
              style={{ alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: 10 }}
            >
              <h4 style={{ marginRight: 10 }}>REASONS<RedAsterisk /></h4>
              <BlueCheckboxGroup
                disabled={!allValid}
                options={getOptions('Reasons')}
                setValues={setReasons}
                values={reasons}
              />
            </FlexRow>
          </StyledCard>
          <StyledCard
            lightBorder
            style={{ flex: 1, padding: '10px', textAlign: 'left', margin: '20px 0 0' }}
          >
            <h4 style={{ width: '100%', textAlign: 'left' }}>
              COMMENTS (OPTIONAL)
            </h4>
            Please record any comments you have regarding your property’s value. 
            You will have the opportunity to attach documents and evidence.
            <BlueTextInput
              multiline
              maxRows={4}
              rows={4}
              value={comments}
              disabled={!allValid}
              onChange={value => {
                if (value.length === config.maxCommentLength) {
                  return setAlertOpen(true);
                }
                setComments(value);
              }}
              style={{ width: '100%' }}
              inputProps={{ style: { fontSize: 16, padding: 2 } }}
            />
            <CharacterCounter>
              {comments.length} of {config.maxCommentLength} 
            </CharacterCounter>  
          </StyledCard>
        </div>
      </FlexRow>
      <SubmitContainer>
        <RoundedButton
          onClick={handleSubmit}
          color="info"
          style={{ width: '15%', margin: 0 }}
          disabled={saving || !isValid}
        >
          SUBMIT PROTEST
        </RoundedButton>
      </SubmitContainer>
      <BasicDialog
        open={alertOpen}
        handleClose={() => setAlertOpen(false)}
        title="YOU REACHED YOUR CHARACTER LIMIT"
        message={
          'No worries, additional comments can be documented '+
          'and attached later'
        }
        titleStyle={{ color: '#c64545' }}
      />

      <PleaseWaitDialog 
        message={
          'Protests are being created. This could take several minutes. ' +
          'You can see the status of the protest in your My Properties list.'
        } 
        open={saving}
      />
    </Container>
  )
}

AgentCreateProtest.propTypes = {
  history: PropTypes.object,
}

const Container = styled.div`
`;

const CharacterCounter = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: 0.54;
  margin-top: 5px;
`;

const ImportContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
`;

const PropCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledCardContent = styled(CardContent)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const AgGridWrapper = styled.div`
  min-height: 200px;
  max-height: 50vh;
  width: 100%;
  flex: 1;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  > label {
    position: absolute;
    top: 25%;
    right: 0;
    width: 38%;
  }
`;

export default AgentCreateProtest;
