import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import RunIcon from 'assets/images/running-duotone.svg';
import ScalesIcon from 'assets/images/Scales.svg';
import ContractIcon from 'assets/images/Contract.svg';
// import ScheduleIcon from 'assets/images/Schedule.svg';
import LedgerIcon from 'assets/images/Ledger.svg';
import SheetIcon from 'assets/images/Sheet.svg';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const RowActionMenu = ({
  data,
  history,
  // setScheduleDialogOpen,
  openRecommendation,
  withdrawAppeal,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!data) return;

  return (
    <div>
      <Tooltip title="Click me to view actions">
        <StyledRunIcon src={RunIcon} onClick={handleClick} enabled={Boolean(anchorEl)}/>
      </Tooltip>
      <StyledMenu
        id="action-menu"
        disableAutoFocusItem
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <StyledMenuItem
          disabled={data.agentStatus !== 'Active' || data.protestState !== 'Not Filed'}
          onClick={() => {
            handleClose();
          }}
        >
          <StyledListItemIcon>
            <StyledIcon alt="Protest Property" src={ScalesIcon} onClick={()=>{}} />
          </StyledListItemIcon>
          <ListItemText primary="Protest Property" />
        </StyledMenuItem> */}
        {/* <StyledMenuItem
          disabled={
            data.canSchedule !== 'Yes' ||
            data.agentStatus !== 'Active'
          }
          onClick={() => {
            handleClose();
            setScheduleDialogOpen(data.appealID);
          }}
        >
          <StyledListItemIcon>
            <StyledIcon alt="Schedule Hearing" src={ScheduleIcon} />
          </StyledListItemIcon>
          <ListItemText primary="Schedule Hearing" />
        </StyledMenuItem> */}
        
        <StyledMenuItem
          disabled={
            data.protestState !== 'Protest Filed' ||
            data.agentStatus !== 'Active'
          }
          onClick={() => {
            handleClose();
            history.push(`/protest-add-evidence/${data.pID}`);
          }}
        >
          <StyledListItemIcon>
            <StyledIcon alt="View Taxpayer Evidence" src={ContractIcon} />
          </StyledListItemIcon>
          <ListItemText primary="View Taxpayer Evidence" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={
            data.protestState === 'Protest Complete' ||
            data.agentStatus !== 'Active' || 
            data.hasRecommendation !== 'Yes'
          }
          onClick={() => {
            openRecommendation(data.recommendationID, data.pID);
            handleClose();
          }}
        >
          <StyledListItemIcon>
            <StyledIcon alt="Review Recommendation" src={LedgerIcon} />
          </StyledListItemIcon>
          <ListItemText primary="Review Recommendation" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={
            data.protestState !== 'Protest Filed' ||
            data.agentStatus !== 'Active'
          }
          onClick={() => {
            withdrawAppeal(data.appealID, data.pID);
            handleClose();
          }}
        >
          <StyledListItemIcon>
            <StyledIcon alt="Withdraw Protest" src={ScalesIcon} />
          </StyledListItemIcon>
          <ListItemText primary="Withdraw Protest" />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={
            data.presentationPublished !== 'Yes' ||
            data.agentStatus !== 'Active'
          }
          onClick={() => 
            history.push(`/protest-cad-evidence?propertyId=${data.pID}&appealId=${data.appealID}`)
          }
        >
          <StyledListItemIcon>
            <StyledIcon alt="View CAD Evidence" src={SheetIcon} />
          </StyledListItemIcon>
          <ListItemText primary="View CAD Evidence" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => 
            history.push(`/protest-case-activity/${data.pID}/${data.appealID}`)
          }
        >
          <StyledListItemIcon>
            <StyledIcon alt="Case Activity" src={SheetIcon} />
          </StyledListItemIcon>
          <ListItemText primary="Case Activity" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

RowActionMenu.propTypes = {
  appealsActions: PropTypes.object,
  data: PropTypes.object,
  history: PropTypes.object,
  openRecommendation: PropTypes.func.isRequired,
  setScheduleDialogOpen: PropTypes.func.isRequired,
  withdrawAppeal: PropTypes.func.isRequired,
};

const StyledRunIcon = styled.img`
  filter: ${({ enabled }) => (enabled
    ? 'sepia(75%) hue-rotate(181deg) saturate(472%)'
    : 'none'
  )};
  cursor: pointer;
  z-index: 51;
  margin-top: 3px;

  :hover {
    filter: sepia(75%) hue-rotate(181deg) saturate(472%)
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 45px;
`;

const StyledIcon = styled.img`
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.2s;

  :hover {
    transform: scale(1.5);
  }
`;

export default RowActionMenu;
