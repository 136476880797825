import { useSelector } from 'react-redux';
import { codeFileSelector } from '../data/selectors/global';

const useCodefiles = () => {
  const codeFiles = useSelector(codeFileSelector);

  const getOptions = name =>
    (codeFiles &&
    codeFiles[name] &&
    codeFiles[name].map(m => ({
      label: `${m.codeName} (${m.codeDescription})`,
      value: m.codeName,
    }))) || [];

  return getOptions;
};

export default useCodefiles;
