import { useSelector } from 'react-redux';
import { usePropertiesActions, useAppealsActions } from './useActions';
import { userSelector } from 'data/selectors/user';
import { confirm } from 'components';

const useWithdrawAppeal = () => {
  const propertyActions = usePropertiesActions([]);
  const appealsActions = useAppealsActions([]);
  const user = useSelector(userSelector);
  const isAgent = user.agentID !== 0;

  const loadPropertyList = () =>
    propertyActions.getUserProperties({ userId: user.id, includeAppeals: true });

  const withdrawAppeal = (appealId, propertyId, preSubmitCall) =>
    confirm(
      'protest',
      {
        title: 'Are you sure you wish to withdraw your protest?',
        description:
          'By clicking the withdrawal button, you acknowledge that ' +
          'you are withdrawing your protest and waive all rights to ' +
          'any further proceedings on the above-referenced property ' +
          'for the tax year in question, including without limitation, ' +
          'all available administrative and legal remedies. Once submitting ' +
          'this withdrawal you will not be able to file another protest ' +
          'on this property for this year.',
        yesAction: 'Withdraw My ',
        noAction: false,
        showCloseIcon: true,
        yesButtonStyle: { color: '#ff5f5d' }
      }
    ).then(() => {
      preSubmitCall && preSubmitCall();
      return appealsActions.withdrawAppeal({ appealId })
        .then(() => isAgent
          ? propertyActions.getAgentProperty({ agentId: user.agentID, propertyId }) 
          : loadPropertyList()
        )
    })

  return withdrawAppeal;
}

export default useWithdrawAppeal;
