import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

import iconSrc from 'assets/images/exclamation-circle-light.svg'

const MaintenanceHeader = ({ maintenance }) => (
  <Wrapper>
    <Icon src={iconSrc} />
    <Container>
      <Typography
        sx={{ fontFamily: 'arial', color: '#393327' }}
        dangerouslySetInnerHTML={{ __html: maintenance.warningMessage }}
      ></Typography>
    </Container>
  </Wrapper>
)

MaintenanceHeader.propTypes = {
  children: PropTypes.object,
  title: PropTypes.any,
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #efa432;
  background: #ffe9c3;
  padding-left: 10px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  height: 55px;
  padding: 10px;
  text-align: center;
`

const Icon = styled.img`
  left: 25px;
`

export default MaintenanceHeader
