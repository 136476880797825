import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { get } from 'lodash';

import { confirm } from 'components';
import AgGrid, { ActionCellRenderer } from 'components/AgGrid';
import { AgGridWrapper } from './MyPropertiesList';
import { FlexRow } from 'components/Styled/Common';
import PageIcon from 'assets/images/PageIcon.svg';
import AddPropertyDialog from './components/AddPropertyDialog';
import AddPinDialog from './components/AddPinDialog';

const UserContent = ({
  properties, 
  propertiesActions,
  setApiError,
  user,
  setErrorOpen,
}) => {
  const [propertyDialogOpen, setPropertyDialogOpen] = useState(false);
  const [pinDialogOpen, setPinDialogOpen] = useState(false);
  const [publicUserPropertyId, setPublicUserPropertyId] = useState();
  const agGridApi = useRef();

  const downloadNotice = async (propertyId) => {
    const result = await propertiesActions.downloadNotice({
      propertyId,
    });
    if (result) {
      const blob = new Blob([ result ], { type: 'application/pdf' });
      const fileName = `${propertyId}_notice.pdf`;
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
    
  }

  const columnDefs = [
    {
      headerName: 'Prop ID',
      field: 'pID',
      maxWidth: 100,
    },
    {
      headerName: 'Type',
      field: 'propType',
      maxWidth: 80,
    },
    {
      headerName: 'Owner Name',
      field: 'name',
    },
    {
      headerName: 'Legal Description',
      field: 'legalDescription',
    },
    {
      headerName: 'Situs Address',
      field: 'streetPrimary',
    },
    {
      headerName: 'DBA',
      field: 'dba',
    },
    {
      headerName: 'Protest Deadline',
      field: 'protestDeadlineDt',
      valueFormatter: ({ value }) => value
        ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY h:mma')
        : ''
    },
    {
      headerName: 'PIN',
      field: 'pin',
      valueFormatter: ({ value, data }) => `${value} (${data.pinStatus})`,
      minWidth: 120,
      cellStyle: { 'text-align': 'left' }
    },
    {
      headerName: 'Actions',
      field: 'actions',
      minWidth: 140,
      cellRendererFramework: ActionCellRenderer,
      cellRendererParams: {
        icons: [
          {
            type: 'link',
            title: (value, record) => record.pinStatus !== 'Valid PIN' && 'Add PIN',
            onClick: (_, record) => {
              setPinDialogOpen(true);
              setPublicUserPropertyId(record.publicUserPropertyID);
            },
          },
          {
            icon: () => PageIcon,
            tooltip: 'View Appraisal Notice',
            isVisible: (record) => record.hasNotice === 'Yes',
            onClick: (_, record) => downloadNotice(record.pID)
          },
          {
            icon: 'trash',
            tooltip: 'Delete',
            isVisible: () => true,
            onClick: (_, record) =>
              confirm('property')
                .then(() => propertiesActions.deleteUserProperty({
                  publicUserPropertyId: record.publicUserPropertyID,
                }))
                .catch(err => console.log(err))
          },
        ],
      },
    }
  ];

  return (
    <>
      <FlexRow>
        This area is for you to maintain a list of all properties associated with your login
        <FlexRow style={{ flex: 1, justifyContent: 'flex-end' }}>
          <em>Click + to add a property to the list.</em>
          <Fab
            color="primary"
            size="small"
            onClick={() => setPropertyDialogOpen(true)}
            style={{ marginLeft: 10 }}
          >
            <AddIcon />
          </Fab>
        </FlexRow>
      </FlexRow>

      <AgGridWrapper className="ag-theme-custom">
        <AgGrid
          disablePreferenceSave
          autoResizeToFit
          defaultColDef={{ resizable: true, sortable: true }}
          columnDefs={columnDefs}
          name="userProperties"
          rowData={properties.data}
          onGridReady={params => (agGridApi.current = params.api)}
          domLayout="autoHeight"
        />
      </AgGridWrapper>
      {propertyDialogOpen && (
        <AddPropertyDialog 
          isOpen={propertyDialogOpen}
          handleClose={() => setPropertyDialogOpen(false)}
          onAdd={pID => propertiesActions.insertUserProperty({
            userId: user.id,
            payload: {
              pID,
            }
          }).catch(err => {
            setApiError(get(err, 'body.warning'));
            setErrorOpen(true);
          })}
        /> 
      )}
      {pinDialogOpen && (
        <AddPinDialog 
          isOpen={pinDialogOpen}
          handleClose={() => setPinDialogOpen(false)}
          onAdd={pin => propertiesActions.updateUserPropertyPin({
            publicUserPropertyId,
            payload: {
              pin,
            }
          }).catch(err => {
            setApiError(get(err, 'body.warning'));
            setErrorOpen(true);
          })}
        /> 
      )}
    </>
  )
}

UserContent.propTypes = {
  properties: PropTypes.array,
  propertiesActions: PropTypes.object,
  setApiError: PropTypes.func.isRequired,
  setErrorOpen: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default UserContent;