import { urlsSelector, authenticationTokenSelector } from '../selectors/global';

export const makeApiActionCreator = (
  _apiCall,
  startActionType,
  successActionType,
  errorActionType,
) => {
  return params => {
    return async (dispatch, getState) => {
      const state = getState();
      const urls = urlsSelector(state);
      const authenticationToken = authenticationTokenSelector(state);
      dispatch({ ...params, type: startActionType });
      try {
        const data = await _apiCall(
          urls,
          authenticationToken,
          params,
        );
        dispatch({ ...params, data, type: successActionType });
        return data;
      } catch (error) {
        console.log(error);
        if (error.statusCode === 409) {
          dispatch({ ...params, error, type: errorActionType });
        } else if (error.statusCode === 403) {
          dispatch({ ...params, error, type: errorActionType });
        } else if (error.statusCode === 401) {
          dispatch({ ...params, error, type: errorActionType });
          localStorage.removeItem('TPToken');
          window.location.href = '/';
        } else {
          dispatch({ ...params, error, type: errorActionType });
        }
        throw error;
      }
    };
  };
}
