
import React from 'react';
import { makeStyles } from '@material-ui/styles';
// import { useSelector } from 'react-redux';
// import styled from 'styled-components';

// import { userSelector } from 'data/selectors/user';
// import { usePropertiesActions } from 'hooks/useActions';
// import { getUserProperties } from 'data/selectors/properties';
import { ContentHeader, ContentSection } from 'components';
// import { FlexRow } from 'components/Styled/Common';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const CadCommunication = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <ContentHeader title="CAD COMMUNICATION" />
      <ContentSection style={{ minHeight: 200 }}>
        
      </ContentSection>
    </div>
  );
};

export default CadCommunication;
