/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import AgGrid, { ActionCellRenderer } from 'components/AgGrid';
import { AgGridWrapper } from './MyPropertiesList';
import { FlexRow } from 'components/Styled/Common';
import useWithdrawAppeal from 'hooks/useWithdrawAppeal';
import { ScheduleHearingDialog } from 'components/HoverContent';
import {
  SelectInput, 
  ActionMenu,
  RowActionMenu,
  SubmitAoaModal,
  RevokeAoaModal,
  PleaseWaitOverlay,
  MassWithdrawModal,
  MassAcceptModal,
  MassAcceptTopModal,
  MassRejectModal,
  BackProcessDialog,
} from 'components';
import { getUsdFormat } from 'lib/utils';
import { TopLineFilter } from '../../constants';
import DownloadIcon from 'assets/images/download-duotone.svg';
import UploadIcon from 'assets/images/upload-duotone.svg';

const AgentContent = ({
  properties,
  history,
  protestStatuses,
  agentStatuses,
  appealsActions,
  propertiesActions,
  refreshAgentProperties,
  user,
}) => {
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [processingProperties, setProcessingProperties] = useState([]);
  const [submitAoaModalOpen, setSubmitAoaModalOpen] = useState(false);
  const [revokeAoaModalOpen, setRevokeAoaModalOpen] = useState(false);
  const [massWithdrawModalOpen, setMassWithdrawModalOpen] = useState(false);
  const [massAcceptModalOpen, setMassAcceptModalOpen] = useState(false);
  const [massRejectModalOpen, setMassRejectModalOpen] = useState(false);
  const [massAcceptTopModalOpen, setMassAcceptTopModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    agentStatus: 'All',
    protestStatus: 'All',
  });
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [search, setSearch] = useState('');
  const [pleaseWaitMessage, setPleaseWaitMessage] = useState(''); // for please wait overlay
  const [waitMessage, setWaitMessage] = useState(null); // for please wait dialog
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [displayedRows, setDisplayedRows] = useState();
  const agGridApi = useRef();
  const timer = useRef();
  const withdrawAppeal = useWithdrawAppeal()

  useEffect(() => {
    const { agentStatus, protestStatus } = filters;
    const data = properties.data.filter(property => {
      if (agentStatus !== 'All' && property.propertyStatus !== agentStatus) {
        return false;
      }
      if (protestStatus !== 'All' && protestStatus !== TopLineFilter && property.protestState !== protestStatus) {
        return false;
      }
      if (protestStatus === TopLineFilter && property.topLineEligible !== 'Yes') {
        return false;
      }
      return true;
    });
    setFilteredProperties(data);
    refreshRowCount();
  }, [filters, properties]);

  const columnDefs = [
    {
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 80,
    },
    {
      headerName: 'Prop ID',
      field: 'pID',
      maxWidth: 110,
    },
    {
      headerName: 'Type',
      field: 'propType',
      maxWidth: 90,
    },
    {
      headerName: 'Owner Name',
      field: 'name',
    },
    {
      headerName: 'Legal Description',
      field: 'legalDescription',
    },
    {
      headerName: 'Address',
      field: 'streetPrimary',
    },
    {
      headerName: 'DBA',
      field: 'dba',
    },
    {
      headerName: 'Notice Value',
      field: 'noticeMarketValue',
      valueFormatter: ({ value }) => value
        ? getUsdFormat(value, 0)
        : '',
      cellStyle: { 'text-align': 'right' }
    },
    {
      headerName: 'Protest Deadline',
      field: 'protestDeadlineDt',
      valueFormatter: ({ value }) => value
        ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY h:mma')
        : ''
    },
    {
      headerName: 'Protest State',
      field: 'protestState',
    },
    {
      headerName: 'Opinion of Value',
      field: 'claimantOpinionOfValue',
      valueFormatter: ({ value }) => value
        ? getUsdFormat(value, 0)
        : '',
      cellStyle: { 'text-align': 'right' }
    },
    {
      headerName: 'Recommendation',
      field: 'recommendationValue',
      cellRendererFramework: (info) => {
        const value = isNaN(info.value) ? info.value
          : getUsdFormat(info.value, 0);
        return info.data.recommendationStatus === 'Rejected' ? (
          <Tooltip title="Recommendation Rejected" placement="right">
            <p>{value}</p>
          </Tooltip>
        ) : value;
      },
      cellStyle: (info) => {
        return info.data.recommendationStatus === 'Rejected' ? {backgroundColor: 'rgba(255, 121, 121, 0.25)'} : {};
      }
    },
    {
      headerName: 'Topline Eligible',
      field: 'topLineEligible',
    },
    {
      headerName: 'Hearing Dt',
      field: 'docketStartTime',
    },
    {
      headerName: 'Final Value',
      field: 'appealFinalValue',
      valueFormatter: ({ value }) => isNaN(value)
        ? value
        : getUsdFormat(value, 0)
    },
    {
      headerName: 'Evidence Published',
      field: 'presentationPublished',
    },
    {
      headerName: 'Agent Status',
      field: 'agentStatus',
    },
    {
      headerName: 'Action',
      field: '',
      maxWidth: 140,
      cellStyle: { 'width': '55px' },
      cellRendererFramework: ActionCellRenderer,
      cellRendererParams: {
        icons: [
          {
            type: 'component',
            renderComponent: props => 
              <RowActionMenu
                {...props}
                appealsActions={appealsActions}
                setScheduleDialogOpen={setScheduleDialogOpen}
                withdrawAppeal={withdrawAppeal}
                openRecommendation={openRecommendation}
                history={history}
              />
          },
        ],
      },
    }
  ];

  const refreshRowCount = () => setTimeout(() => {
    setDisplayedRows(agGridApi.current.getDisplayedRowCount())
  }, 200);

  const onSelectionChanged = event => {
    setSelectedProperties(event.api.getSelectedNodes().map(r => r.data))
  };

  const submitAoa = async payload => {
    setSubmitAoaModalOpen(false);
    setPleaseWaitMessage('We are processing your AOAs');
    const { results } = await appealsActions.processAgentAoa({
      agentId: user.agentID,
      payload,
    });
    timer.current = setInterval(async () => {
      const resp = await appealsActions.getMassAoaStatus({ massId: results.massID })
      if (['Complete', 'Error'].includes(resp.results[0].massStatus)) {
        clearInterval(timer.current);
        setPleaseWaitMessage('');
        refreshAgentProperties();
        setFilters({
          agentStatus: 'All',
          protestStatus: 'All',
        });
      }
    }, 1000);
  }

  const revokeAoa = async payload => {
    setRevokeAoaModalOpen(false);
    setPleaseWaitMessage('We are processing your AOAs');
    const { results } = await appealsActions.revokeAgentAoa({
      agentId: user.agentID,
      payload,
    });
    timer.current = setInterval(async () => {
      const resp = await appealsActions.getMassAoaStatus({ massId: results.massID })
      if (['Complete', 'Error'].includes(resp.results[0].massStatus)) {
        clearInterval(timer.current);
        setPleaseWaitMessage('');
        refreshAgentProperties();
        setFilters({
          agentStatus: 'All',
          protestStatus: 'All',
        });
      }
    }, 1000);
  }

  const massHandlerBefore = () => {
    setProcessingProperties(selectedProperties.map(p => p.pID));
  }

  const massHandleAfter = (results) => {
    setTimeout(() => {
      setProcessingProperties([]);
      if (results && results.massCreateID) {
        refreshAgentProperties({ noNeedRecordState: true });
        setFilters({
          agentStatus: 'All',
          protestStatus: 'All',
        });
      }
    }, 1000);
  }

  const massWithdraw = async () => {
    massHandlerBefore();
    setMassWithdrawModalOpen(false);
    setWaitMessage({
      title: 'MASS WITHDRAW PROTESTS',
      message: `A background process has been started to mass process all\n 
        Protests on this list. This could take several minutes.\n\n\n\n 
        You can monitor progress by checking the Protest State.`
    });
    const { results } = await appealsActions.massWithdraw({
      agentId: user.agentID,
      appealList: selectedProperties.map((r) => r.appealID),
    });
    massHandleAfter(results);
  }

  const massAccept = async () => {
    massHandlerBefore();
    setMassAcceptModalOpen(false);
    setWaitMessage({
      title: 'MASS ACCEPT RECOMMENDATIONS OF VALUE',
      message: `A background process has been started to mass process all\n
        Protests on this list. This could take several minutes.\n\n\n\n
        You can monitor progress by checking the Protest State.`
    });
    const { results } = await appealsActions.massAccept({
      agentId: user.agentID,
      appealRecommendationList: selectedProperties.map((r) => ({ appealID: r.appealID, recommendationID: r.recommendationID })),
    });
    massHandleAfter(results);
  }

  const massAcceptTop = async () => {
    massHandlerBefore();
    setMassAcceptTopModalOpen(false);
    setWaitMessage({
      title: 'MASS TOP-LINE AGREEMENT',
      message: `A background process has been started to mass process all\n
        Protests on this list. This could take several minutes.\n\n\n\n
        You can monitor progress by checking the Protest State.`
    });
    const { results } = await appealsActions.massAcceptTop({
      agentId: user.agentID,
      appealRecommendationList: selectedProperties.map((r) => ({ appealID: r.appealID, recommendationID: r.recommendationID })),
    });
    massHandleAfter(results);
  }

  const massReject = async () => {
    massHandlerBefore();
    setMassRejectModalOpen(false);
    setWaitMessage({
      title: 'MASS REJECT RECOMMENDATIONS OF VALUE',
      message: `A background process has been started to mass process all\n
        Protests on this list. This could take several minutes.\n\n\n\n
        You can monitor progress by checking the Protest State.`
    });
    const { results } = await appealsActions.massReject({
      agentId: user.agentID,
      appealRecommendationList: selectedProperties.map((r) => ({ appealID: r.appealID, recommendationID: r.recommendationID })),
    });
    massHandleAfter(results);
  }

  const openRecommendation = async (recommendationId, propertyId) => {
    await appealsActions.setRecommendationPortalView({ recommendationId });
    await appealsActions.fetchAppealRecommendation({ recommendationId });
    history.push(`/protest-review-recommendation?propertyId=${propertyId}`);
  }

  const closeBackProcessDialog = () => {
    setWaitMessage(null);
  };

  const getPropertiesData = () => {
    if (!waitMessage || processingProperties.length === 0) {
      return filteredProperties;
    }
    return filteredProperties.map(f => {
      if (processingProperties.indexOf(f.pID) !== -1) {
        return { ...f, protestState: 'Processing'};
      }
      return f;
    })
  }

  return (
    <>
      <FlexRow style={{ marginBottom: 10 }}>
        <TextField
          autoFocus
          variant="outlined"
          label="Search"
          placeholder="Search by PID, Owner, Address, Agent"
          value={search}
          onChange={v => {
            setSearch(v.target.value);
            if (agGridApi.current) {
              agGridApi.current.setQuickFilter(v.target.value);
              refreshRowCount();
            }
          }}
          style={{ flex: 1 }}
        />

        <FlexRow style={{ flex: 1, justifyContent: 'flex-end' }}>
          <SelectInput
            label="Agent Status"
            value={filters.agentStatus}
            handleChange={e => setFilters({ ...filters, agentStatus: e.target.value })}
            options={
              agentStatuses 
                ? agentStatuses.map(i => ({ label: i, value: i }))
                : []
            }
            style={{ marginLeft: 20, minWidth: 200 }}
          />
          <SelectInput
            label="Protest State"
            value={filters.protestStatus}
            handleChange={e => setFilters({ ...filters, protestStatus: e.target.value })}
            options={
              protestStatuses
                ? protestStatuses.concat([TopLineFilter]).map(i => ({ label: i, value: i }))
                : []
            }
            style={{ marginLeft: 20, minWidth: 200 }}
          />
        </FlexRow>
      </FlexRow>
      <FlexRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <FlexRow style={{ fontSize: '0.9em' }}>
          <span style={{ marginRight: 20 }}>
            Total Properties: {properties.data.length}
          </span>
          <span style={{ marginRight: 20 }}>
            Displayed Properties: {displayedRows}
          </span>
          <span>Selected Properties: {selectedProperties.length}</span>
        </FlexRow>
        <FlexRow style={{ transform: 'translateY(10px)' }}>
          <Tooltip title="Upload Data">
            <IconButton onClick={() => history.push('/efile-protest-agent')}>
              <img src={UploadIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download All Property Data">
            <IconButton onClick={() => agGridApi.current.exportDataAsCsv()}>
              <img src={DownloadIcon} />
            </IconButton>
          </Tooltip>
          <ActionMenu
            selectedProperties={selectedProperties}
            history={history}
            appealsActions={appealsActions}
            openSubmitAoaModal={() => setSubmitAoaModalOpen(true)}
            openRevokeAoaModal={() => setRevokeAoaModalOpen(true)}
            openMassWithdrawModal={() => setMassWithdrawModalOpen(true)}
            openMassAcceptModal={() => setMassAcceptModalOpen(true)}
            openMassAcceptTopModal={() => setMassAcceptTopModalOpen(true)}
            openMassRejectModal={() => setMassRejectModalOpen(true)}
          />
        </FlexRow>
      </FlexRow>

      <AgGridWrapper className="ag-theme-custom" fixedHeight>
        <AgGrid
          disablePreferenceSave
          autoResizeToFit
          defaultColDef={{ resizable: true, sortable: true }}
          rowSelection="multiple"
          columnDefs={columnDefs}
          name="userProperties"
          rowData={getPropertiesData()}
          onGridReady={params => (agGridApi.current = params.api)}
          onSelectionChanged={onSelectionChanged}
        />
      </AgGridWrapper>
      {submitAoaModalOpen &&
        <SubmitAoaModal
          open={submitAoaModalOpen}
          handleClose={() => setSubmitAoaModalOpen(false)}
          pids={selectedProperties.map(p => p.pID)}
          appealsActions={appealsActions}
          submitAoa={submitAoa}
          user={user}
        />
      }
      {revokeAoaModalOpen &&
        <RevokeAoaModal
          open={revokeAoaModalOpen}
          handleClose={() => setRevokeAoaModalOpen(false)}
          pids={selectedProperties.map(p => p.pID)}
          appealsActions={appealsActions}
          revokeAoa={revokeAoa}
          user={user}
        />
      }
      {massWithdrawModalOpen &&
        <MassWithdrawModal
          open={massWithdrawModalOpen}
          handleClose={() => setMassWithdrawModalOpen(false)}
          pids={selectedProperties.map(p => p.pID)}
          massWithdraw={massWithdraw}
          user={user}
        />
      }
      {massAcceptModalOpen &&
        <MassAcceptModal
          open={massAcceptModalOpen}
          handleClose={() => setMassAcceptModalOpen(false)}
          pids={selectedProperties.map(p => p.pID)}
          massAccept={massAccept}
          user={user}
        />
      }
      {massAcceptTopModalOpen &&
        <MassAcceptTopModal
          open={massAcceptTopModalOpen}
          handleClose={() => setMassAcceptTopModalOpen(false)}
          pids={selectedProperties.map(p => p.pID)}
          massAcceptTop={massAcceptTop}
          user={user}
        />
      }
      {massRejectModalOpen &&
        <MassRejectModal
          open={massRejectModalOpen}
          handleClose={() => setMassRejectModalOpen(false)}
          pids={selectedProperties.map(p => p.pID)}
          massReject={massReject}
          user={user}
        />
      }
      {pleaseWaitMessage && <PleaseWaitOverlay message={pleaseWaitMessage} />}
      {waitMessage && <BackProcessDialog open={!!waitMessage} message={waitMessage.message} title={waitMessage.title} onClose={closeBackProcessDialog}/>}
      {!!scheduleDialogOpen &&
        <ScheduleHearingDialog
          open={!!scheduleDialogOpen}
          appealId={scheduleDialogOpen}
          handleClose={() => {
            const property = properties.data.find(p => p.appealID === scheduleDialogOpen)
            propertiesActions.getAgentProperty({
              agentId: user.agentID,
              propertyId: property.pID,
            })
            setScheduleDialogOpen(false);
          }}
          selectManual={() =>
            history.push(`/protest-manual-schedule/${scheduleDialogOpen}`)
          }
          onWithdraw={() => {
            setScheduleDialogOpen(false);
            withdrawAppeal(scheduleDialogOpen);
          }}
        />
      }
    </>
  )
}

AgentContent.propTypes = {
  agentStatuses: PropTypes.array,
  appealsActions: PropTypes.object,
  history: PropTypes.object,
  properties: PropTypes.object,
  propertiesActions: PropTypes.object,
  protestStatuses: PropTypes.array,
  refreshAgentProperties: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default AgentContent;