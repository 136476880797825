import React, { useState, useEffect } from 'react';
// import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
  FormControlLabel,
  FormHelperText,
  Checkbox,
  TextField,
  Typography
} from '@material-ui/core';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';

import { EfilePinHelpPopper, Alert } from 'components';
import { useUserActions } from 'hooks/useActions';
import { FlexRow } from 'components/Styled/Common';
import { TransparentTextInput } from 'components';
import RoundedButton from 'components/Buttons/RoundedButton';
// import FacebookIcon from 'assets/images/facebook-f-brands.svg';
// import GoogleIcon from 'assets/images/google-brands.svg';

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  password2: {
    presence: { allowEmpty: false, message: 'is required' },
    equality: 'password',
    length: {
      maximum: 128
    }
  },
  agentCode: {
    length: {
      maximum: 30,
    }
  },
  propertyId: {
    conditionalPresence: { field: 'agentCode', message: 'is required for agents' },
  },
  agreeTerms: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  },
};

const useStyles = makeStyles(theme => ({
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  formLeft: {
    flex: 2,
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingBottom: '6%',
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  formRight: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    paddingRight: '5%',
    paddingBottom: 125,
    alignItems: 'center',
  },
  title: {
    color: 'white',
    marginTop: theme.spacing(4),
    borderBottom: '2px solid #fecc3d',
    maxWidth: 262,
  },
  terms: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    color: 'white',
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
    width: '60%',
  }
}));

const SignUp = () => {
  const userActions = useUserActions([]);
  const [formState, setFormState] = useState({
    isValid: true,
    values: {},
    touched: {},
    errors: {},
    legalStatement: '',
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [openAgentSection, setOpenAgentSection] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      const { results } = await userActions.fetchLegalStatement();
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          ...results,
        },
      });
    })();
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignUp = async event => {
    event.preventDefault();
    try {
      await userActions.signup({
        email: formState.values.email,
        fullName: `${formState.values.firstName} ${formState.values.lastName}`,
        password: formState.values.password,
        cellphone: formState.values.cell,
        agentCode: formState.values.agentCode || null,
        agentPID: formState.values.propertyId || null,
        receiveText: 1,
      });
      setSignupSuccess(true);
    } catch (err) {
      console.log(err);
      setApiError(get(err, 'body.warning'));
      setAlertOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlertOpen(false);
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {apiError}
        </Alert>
      </Snackbar>
      <div className={classes.content}>
        {signupSuccess
          ? (
            <SuccessContainer>
              <span style={{ color: theme.palette.info.main }}>
              Your account has been successfully created.
              </span><br/><br/>
            To confirm your account, please click the "Confirm Account" button<br/>
            in the email we just sent you.
            </SuccessContainer>
          ) : (
            <form
              className={classes.contentBody}
              onSubmit={handleSignUp}
            >
              <div className={classes.formLeft}>
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Create New Account
                </Typography>

                <FlexRow>
                  <TransparentTextInput
                    fullWidth
                    error={hasError('firstName')}
                    helperText={
                      hasError('firstName') ? formState.errors.firstName[0] : null
                    }
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.firstName || ''}
                    style={{ marginRight: 16}}
                  />
                  <TransparentTextInput
                    fullWidth
                    error={hasError('lastName')}
                    helperText={
                      hasError('lastName') ? formState.errors.lastName[0] : null
                    }
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.lastName || ''}
                  />
                </FlexRow>
                <TransparentTextInput
                  error={hasError('cell')}
                  fullWidth
                  helperText={
                    hasError('cell') ? formState.errors.cell[0] : null
                  }
                  label="Cell Phone Number"
                  name="cell"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.cell || ''}
                />
                <TransparentTextInput
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                />
                <TransparentTextInput
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                />
                <TransparentTextInput
                  error={hasError('password2')}
                  fullWidth
                  helperText={
                    hasError('password2') ? formState.errors.password2[0] : null
                  }
                  label="Verify Password"
                  name="password2"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password2 || ''}
                  style={{ marginBottom: 16 }}
                />
                <span style={{ textDecoration: 'underline' }}>
                  (Password must contain a minimum of 8 characters)
                </span>
                <br/><br/><br/>
                <Link
                  to="#"
                  onClick={() => setOpenAgentSection(prev => !prev)}
                  style={{ color: '#FECC3D', fontSize: 18 }}
                >
                  { openAgentSection ? 'Not an Agent?' : 'Are you an Agent?'}
                </Link>
                {openAgentSection && (
                  <>
                    <Typography
                      className={classes.title}
                      style={{ maxWidth: 160 }}
                      variant="h2"
                    >
                      Agents Only
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <TransparentTextInput
                        fullWidth
                        error={hasError('propertyId')}
                        helperText={
                          hasError('propertyId') ? formState.errors.propertyId[0] : null
                        }
                        label="Enter a property ID that you represent"
                        name="propertyId"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.propertyId || ''}
                        style={{ marginBottom: 5 }}
                      />
                      <FlexRow style={{ justifyContent: 'flex-start' }}>
                        <TransparentTextInput
                          fullWidth
                          error={hasError('agentCode')}
                          helperText={
                            hasError('agentCode') ? formState.errors.agentCode[0] : null
                          }
                          label="Agent E-File PIN"
                          name="agentCode"
                          onChange={handleChange}
                          type="text"
                          value={formState.values.agentCode || ''}
                          style={{ marginRight: 10, maxWidth: 200 }}
                        />
                        <EfilePinHelpPopper style={{ color: 'white' }} />
                      </FlexRow>
                    </div>
                  </>
                )}
              </div>
              <div className={classes.formRight}>
                <TextField
                  fullWidth
                  multiline
                  rows={8}
                  color="primary"
                  variant="outlined"
                  name="legalAgreement"
                  value={formState.values.legalAgreement}
                  disabled
                  style={{ background: 'white', border: '1px solid #707070' }}
                  InputProps={{ style: { borderRadius: 0 }}}
                />
                <div className={classes.terms}>
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.agreeTerms || false}
                        onChange={handleChange}
                        name="agreeTerms"
                        color="primary"
                      />
                    }
                    label="I do agree"
                  />
                </div>
                {hasError('policy') && (
                  <FormHelperText error>
                    {formState.errors.policy[0]}
                  </FormHelperText>
                )}
                <RoundedButton
                  className={classes.signUpButton}
                  color="info"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
              Create New Login
                </RoundedButton>
                {/* <FlexRow>
                  <FlexRow>
                    <Image src={GoogleIcon}/>
                    <StyledRouterLink to="#">Login with Google</StyledRouterLink>  
                  </FlexRow>
                  <FlexRow>
                    <Image src={FacebookIcon}/>
                    <StyledRouterLink to="#">Login with Facebook</StyledRouterLink>  
                  </FlexRow>
                </FlexRow> */}
              </div>
            </form>
          )}
      </div>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

// const StyledRouterLink = styled(RouterLink)`
//   color: white;
//   text-decoration: underline;
// `;

// const Image = styled.img`
//   height: 22px;
//   margin: 15px;
// `;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiIconButton-label { 
    color: white;
    opacity: 0.54;
  }

  .MuiFormControlLabel-label {
    color: white;
  }
`;

const SuccessContainer = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.3em;
`;

export default SignUp;
