/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import {
  makeStyles,
} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import queryString from 'query-string';

import {
  getUserPropertyHistory,
  getUserProperties,
  getAgentPropertyHistory,
} from 'data/selectors/properties';
import {
  getAppealRecommendation,
  getAppealRecommendationState,
} from 'data/selectors/appeals';
import { ContentHeader, ContentSection, PleaseWaitDialog } from 'components';
import { ScheduleHearingDialog } from 'components/HoverContent';
import { FlexRow, StyledCard } from 'components/Styled/Common';
import RoundedButton from 'components/Buttons/RoundedButton';
import BlueTextInput from 'components/TextInputs/BlueTextInput';
import { usePropertiesActions, useAppealsActions } from 'hooks/useActions';
import useWithdrawAppeal from 'hooks/useWithdrawAppeal';
import { PropertyCard, Alert, PleaseWaitOverlay, confirm } from 'components';
import { onlyNumbersNormalizer } from 'lib/utils';
import { fetchingState } from '../../constants';

const useStyles = makeStyles(() => ({
  field: {
    '& .MuiInputBase-input': {
      color: '#142bad',
    }
  },
}));

const ReviewRecommendationView = ({ history, location, isAgent }) => {
  const theme = useTheme();
  const [alertOpen, setAlertOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const [pleaseWaitMessage, setPleaseWaitMessage] = useState('');
  const { publicUserPropertyId, propertyId } = queryString.parse(location.search);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const propertyActions = usePropertiesActions([]);
  const appealActions = useAppealsActions([]);
  const userProperties = useSelector(getUserProperties);
  const userPropertyHistory = useSelector(getUserPropertyHistory);
  const agentPropertyHistory = useSelector(getAgentPropertyHistory);
  const propertyHistory = isAgent ? agentPropertyHistory : userPropertyHistory;
  const recommendation = useSelector(getAppealRecommendation);
  const recommendationState = useSelector(getAppealRecommendationState);
  const withdrawAppeal = useWithdrawAppeal();

  const classes = useStyles();

  const property = userProperties && userProperties.data.find(p =>
    p.publicUserPropertyID === Number(publicUserPropertyId)
  );
  const {
    recommendationID: recommendationId,
    appealID: appealId,
    recommendationValueType,
    recommendationStatus = '',
    recommendationAcceptedDt,
    recommendationRejectedDt,
    recommendationReasonAdditional,
    recommendationExpireDt,
    recommendationValue,
    hasAttachments,
    showOpinionOfValueMessage,
    topLineEligible,
    updateDt: lastUpdate,
  } = recommendation;
  const activeRecommendation = ['M','E','T','O', 'NA'].includes(recommendationValueType);
  const acceptedOrRejected = ['accepted','rejected', 'topline accepted'].includes(
    recommendationStatus.toLowerCase()
  );
  if (!property && !isAgent) history.push('/protest');

  //const isNoChangeRecommendation = propertyHistory.data.marketValue === recommendationValue;

  useEffect(() => {
    if (isAgent) {
      propertyActions.getAgentPropertyHistory({ propertyId });
    } else {
      propertyActions.getUserPropertyHistory({ publicUserPropertyId });
    }
  }, [])
  
  const acceptRecommendation = async () => {
    try {
      await appealActions.acceptAppealRecommendation({
        recommendationId,
        lastUpdate: recommendation.updateDt,
      });
    } catch (err) {
      console.log(err);
      setApiError(get(err, 'body.warning'));
      setAlertOpen(true);
    }  
  }

  const rejectRecommendation = () => 
    appealActions.rejectAppealRecommendation({ 
      recommendationId,
      lastUpdate: recommendation.updateDt,
    });
  
  const downloadRecommendation = () => 
    appealActions.downloadAppealRecommendation({ recommendationId })

  const renderBottomComment = () => {
    if (recommendationStatus === 'Accepted') {
      return 'Value recommendation accepted on ' +
        moment(recommendationAcceptedDt).format('M/DD/YYYY');
    } else if (recommendationStatus === 'Rejected') {
      return 'Value recommendation rejected on ' +
        moment(recommendationRejectedDt).format('M/DD/YYYY');
    } else if (activeRecommendation) {
      if (!recommendationExpireDt) return '';
      const date = moment(recommendationExpireDt).format('MM/DD/YYYY');
      const time = moment(recommendationExpireDt).format('h:mma')
      return `Value recommendation expires at ${time} on ${date}`;
    } else {
      return 'After sincere review of your protest, at this time it is \n' +
        'the district\'s opinion to remain at notice market value.'
    }
  }

  const returnWaiverText = () =>
    <p>
      The Formal ARB Hearing to approve this Top Line Agreement
      will be scheduled for the next available docket designated
      for Top Lines. It is possible that the hearing may occur within
      the next fifteen (15) days. The CAD is required to notify
      property owners of a hearing at least fourteen (14) days in
      advance, and make certain information and evidence available
      <br/><br/>
      By clicking below, you acknowledge that you are entitled to
      receive a hearing notice and evidence at least fourteen (14)
      days in advance, and YOU AGREE TO WAIVE YOUR RIGHTS to receive
      a notice and evidence at least fourteen (14) days in advance.
    </p>

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {apiError}
        </Alert>
      </Snackbar>
      <PleaseWaitDialog 
        message="We are submitting your response and closing your protest." 
        open={recommendationState === fetchingState.SAVING}
      />
      {pleaseWaitMessage && <PleaseWaitOverlay message={pleaseWaitMessage} />}
      {!!scheduleDialogOpen &&
        <ScheduleHearingDialog
          open={!!scheduleDialogOpen}
          appealId={appealId}
          handleClose={source => {
            setScheduleDialogOpen(false)
            if (source === 'confirm') {
              history.push(isAgent ? '/my-properties' : '/protest')
            }
          }}
          selectManual={() =>
            history.push(`/protest-manual-schedule/${appealId}`)
          }
          onWithdraw={() => {
            setScheduleDialogOpen(false);
            withdrawAppeal(scheduleDialogOpen);
          }}
        />
      }
      <ContentHeader title="SETTLEMENT VALUE" />
      <ContentSection style={{ border: 'none', alignItems: 'center' }}>
        You have received a settlement recommendation
        <FlexRow style={{ width: '100%', alignItems: 'stretch' }}>
          <PropertyCard
            property={{ ...propertyHistory.data, appealId }}
            style={{ marginRight: 20 }}
          />
          <StyledCard lightBorder style={{ flex: 1, padding: '10px 10px' }}>
            <TextField
              className={classes.field}
              fullWidth
              multiline
              rows={8}
              color="primary"
              variant="outlined"
              name="recommendationExplanation"
              value={
                `${recommendationReasonAdditional}\n\n` + 
                `${recommendationValueType === 'NA' ?
                  'After sincere review of your protest, at this time it is ' +
                  'the district\'s opinion to remain at notice market value.' 
                  : ''
                }`
              }
              disabled
              style={{ background: 'white', border: 'none', marginBottom: 15 }}
              InputProps={{ style: { borderRadius: 0 }}}
            />
            {!!hasAttachments &&
              <>
                Attachments:{' '}
                <Link to="#" onClick={downloadRecommendation}>
                  {get(recommendation, 'recommendationDocuments.description')}
                </Link>
              </>
            }
          </StyledCard>
        </FlexRow>
        <StyledCard
          lightBorder 
          style={{ 
            flex: 1,
            padding: '10px 10px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h4>
            {activeRecommendation
              ? 'RECOMMENDED VALUE AMOUNT'
              : 'NO CHANGE\nVALUE WILL REMAIN AT'
            }
          </h4>
          <FlexRow
            style={{
              margin: '20px 50px',
              marginBottom: acceptedOrRejected ? 20 : 50,
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {/* the activeRecommendation render condition was added to 
              temporarily hide the 'Schedule ARB Hearing' button */}
            {(!acceptedOrRejected && activeRecommendation) &&
              <div style={{ position: 'relative', flex: 1, maxWidth: '20%', marginRight: 20 }}>
                <RoundedButton
                  color={'error'}
                  disabled={
                    (!activeRecommendation && get(property, 'canSchedule') === 'No') ||
                    recommendationState === fetchingState.SAVING
                  }
                  style={{ width: '100%' }}
                  onClick={activeRecommendation
                    ? rejectRecommendation
                    : () => setScheduleDialogOpen(true)
                  }
                >
                  {activeRecommendation
                    ? 'REJECT VALUE'
                    : 'SCHEDULE ARB HEARING'
                  }
                </RoundedButton>
                {activeRecommendation &&
                  <WarningText style={{ color: theme.palette.error.main }}>
                    * A rejection will still allow you to 
                    have a hearing with the ARB or
                    withdraw your protest.
                  </WarningText>
                }
              </div>
            }
            <BlueTextInput
              autoFocus={!publicUserPropertyId}
              disabled
              value={`$${recommendationValue}`}
              onChange={() => {}}
              numberMask
              normalize={onlyNumbersNormalizer}
              style={{ maxWidth: 150, margin: '0 40px' }}
              inputProps={{ style: { fontSize: 20, padding: 12, textAlign: 'center' } }}
              helperText={showOpinionOfValueMessage === 'Yes' && 'Your Opinion of Value'}
              FormHelperTextProps={{
                style: {
                  color: theme.palette.success.main,
                  filter: 'brightness(0.8)',
                  paddingTop: 5,
                  paddingLeft: 5,
                  fontWeight: '500',
                },
              }}
            />
            {!acceptedOrRejected &&
              <div style={{ position: 'relative', flex: 1, maxWidth: '20%', marginRight: 20 }}>
                <RoundedButton
                  color={'success'}
                  onClick={activeRecommendation
                    ? acceptRecommendation
                    : () => {
                      withdrawAppeal(
                        appealId,
                        null,
                        () => setPleaseWaitMessage('Withdrawing Protest'),
                      )
                        .then(() => history.push(isAgent ? '/my-properties': '/protest'))
                        .catch(err => {
                          setApiError(get(err, 'body.warning'));
                          setAlertOpen(true);
                        })
                    }
                  }
                  style={{ width: '100%' }}
                  disabled={recommendationState === fetchingState.SAVING}
                >
                  {activeRecommendation
                    ? 'ACCEPT VALUE'
                    : 'WITHDRAW PROTEST'
                  }
                </RoundedButton>
                {activeRecommendation &&
                  <AcceptText>
                    Accepting the recommendation value will make the 
                    value final and close the case
                  </AcceptText>
                }
              </div>
            }
          </FlexRow>
          {!!topLineEligible && !acceptedOrRejected && (
            <FlexRow style={{ justifyContent: 'center', width: '100%' }}>
              <RoundedButton
                color={'info'}
                style={{ maxWidth: '20%' }}
                disabled={recommendationState === fetchingState.SAVING}
                onClick={() =>
                  confirm('', {
                    title: 'NOTICE AND WAIVER',
                    description: returnWaiverText,
                    noAction: 'I DO NOT AGREE',
                    yesAction: 'I AGREE',
                    showCloseIcon: false,
                    yesButtonStyle: {},
                  }).then(() =>
                    appealActions.acceptTopline({
                      recommendationId,
                      lastUpdate, 
                    })
                      .catch(err => {
                        setApiError(get(err, 'body.warning'));
                        setAlertOpen(true);
                      })
                  )}
              >
                Accept with Top-Line Agreement
              </RoundedButton>
            </FlexRow>
          )}
          <span style={{ color: theme.palette.primary.main}}>
            {renderBottomComment()}
          </span>
        </StyledCard>
      </ContentSection>
    </>
  );
};

ReviewRecommendationView.propTypes = {
  history: PropTypes.object,
  isAgent: PropTypes.bool,
  location: PropTypes.object,
}

const WarningText = styled.div`
  font-size: 0.8em;
  position: absolute;
  bottom: -45px;
  left: 0;
`;

const AcceptText = styled.div`
  font-size: 0.8em;
  position: absolute;
  bottom: -45px;
  left: 0;
`;

export default ReviewRecommendationView;
