import _ from 'lodash';
import { fetchingState } from '../../constants';

export const updateStateArrayItem = (state, action, arrayName, idFieldName) =>
  ({
    ...state,    
    [arrayName]: {
      state: fetchingState.LOADED,
      data: [
        ...state[arrayName].data.map(x =>
          x[idFieldName] === _.get(action, `data.results[0].${idFieldName}`)
            ? action.data.results[0]
            : x,
        ),
      ],
    }
  });

export const upsertStateArrayItem = (state, action, arrayName, idFieldName) => {

  const item = state[arrayName].data.find(x =>
    x[idFieldName] === _.get(action, `data.results[0].${idFieldName}`)
  );
  if (item) {
    return updateStateArrayItem(state, action, arrayName, idFieldName)
  } else {
    return {
      ...state,
      [arrayName]: {
        data: [
          ...state[arrayName].data,
          ..._.get(action, 'data.results', []),
        ],
        state: fetchingState.LOADED,
      }
    }
  }
}