import * as api from '../api/global'
import { makeApiActionCreator } from './utils'
import { urlsSelector } from '../selectors/global'

const prefix = 'PUBLIC_PORTAL/GLOBAL'

export const START = 'START'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'

export function createRequestTypes(base) {
  return [START, SUCCESS, ERROR].reduce((acc, type) => {
    acc[type] = `${base}_${type}`
    return acc
  }, {})
}

export const FETCH_OFFICE = createRequestTypes(`${prefix}/FETCH_OFFICE`)

export const fetchOffice = () => async (dispatch, getState) => {
  const state = getState()
  const urls = urlsSelector(state)
  try {
    const data = await api.lookupOffice(urls)
    dispatch({ data, type: FETCH_OFFICE[SUCCESS] })
    dispatch(fetchMaintenanceSchedule({ office: data.results.office }))
    return data
  } catch (error) {
    console.log(error)
    if (error.statusCode === 409) {
      dispatch({ error, type: FETCH_CONFIG[ERROR] })
    } else if (error.statusCode === 403) {
      dispatch({ error, type: FETCH_CONFIG[ERROR] })
    } else if (error.statusCode === 401) {
      dispatch({ error, type: FETCH_CONFIG[ERROR] })
      localStorage.removeItem('TPToken')
      window.location.href = '/'
    } else {
      dispatch({ error, type: FETCH_CONFIG[ERROR] })
    }
    throw error
  }
}

export const FETCH_CONFIG = createRequestTypes(`${prefix}/FETCH_CONFIG`)

export const fetchConfig = makeApiActionCreator(
  api.fetchConfig,
  FETCH_CONFIG[START],
  FETCH_CONFIG[SUCCESS],
  FETCH_CONFIG[ERROR]
)

export const FETCH_MAINTENANCE_SCHEDULE = createRequestTypes(
  `${prefix}/FETCH_MAINTENANCE_SCHEDULE`
)

export const fetchMaintenanceSchedule = makeApiActionCreator(
  api.fetchMaintenanceSchedule,
  FETCH_MAINTENANCE_SCHEDULE[START],
  FETCH_MAINTENANCE_SCHEDULE[SUCCESS],
  FETCH_MAINTENANCE_SCHEDULE[ERROR]
)

export const FETCH_CODE_FILE = createRequestTypes(`${prefix}/FETCH_CODE_FILE`)

export const fetchCodeFile = makeApiActionCreator(
  api.fetchCodeFile,
  FETCH_CODE_FILE[START],
  FETCH_CODE_FILE[SUCCESS],
  FETCH_CODE_FILE[ERROR]
)

export const FETCH_AGENT_SUMMARY = createRequestTypes(
  `${prefix}/FETCH_AGENT_SUMMARY`
)

export const fetchAgentSummary = makeApiActionCreator(
  api.fetchAgentSummary,
  FETCH_AGENT_SUMMARY[START],
  FETCH_AGENT_SUMMARY[SUCCESS],
  FETCH_AGENT_SUMMARY[ERROR]
)

export const FETCH_PROTEST_STATUS_LIST = createRequestTypes(
  `${prefix}/FETCH_PROTEST_STATUS_LIST`
)

export const fetchProtestStatusList = makeApiActionCreator(
  api.fetchProtestStatusList,
  FETCH_PROTEST_STATUS_LIST[START],
  FETCH_PROTEST_STATUS_LIST[SUCCESS],
  FETCH_PROTEST_STATUS_LIST[ERROR]
)

export const FETCH_AGENT_STATUS_LIST = createRequestTypes(
  `${prefix}/FETCH_AGENT_STATUS_LIST`
)

export const fetchAgentStatusList = makeApiActionCreator(
  api.fetchAgentStatus,
  FETCH_AGENT_STATUS_LIST[START],
  FETCH_AGENT_STATUS_LIST[SUCCESS],
  FETCH_AGENT_STATUS_LIST[ERROR]
)
