import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Drawer } from '@material-ui/core'

import HomeIcon from 'assets/images/HomeIcon.svg'
import MyProperties from 'assets/images/MyProperties.svg'
// import Applications from 'assets/images/Applications.svg';
import Protest from 'assets/images/Protest.svg'
// import AccountMaintenance from 'assets/images/AccountMaintenance.svg';
// import Renditions from 'assets/images/Renditions.svg';
// import CadCommunication from 'assets/images/CadCommunication.svg';
import { SidebarNav } from './components'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 215,
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      // height: 'calc(100% - 64px)'
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = props => {
  const { open, variant, onClose, className, userType, ...rest } = props

  const classes = useStyles()

  const pages = [
    {
      title: 'Home',
      href: '/home',
      icon: <img src={HomeIcon} alt="icon" />,
      userTypes: ['owner', 'agent'],
    },
    {
      title: 'My Properties',
      href: '/my-properties',
      icon: <img src={MyProperties} alt="icon" />,
      userTypes: ['owner', 'agent'],
    },
    // {
    //   title: 'Property Search',
    //   href: '/property-search',
    //   icon: <img src={MyProperties} alt="icon" />,
    //   userTypes: ['agent'],
    // },
    // {
    //   title: 'Applications',
    //   href: '/applications',
    //   icon: <img src={Applications} alt="icon" />
    // },
    {
      title: 'Protest',
      href: '/protest',
      icon: <img src={Protest} alt="icon" />,
      userTypes: ['owner'],
    },
    {
      title: 'E-File Protest',
      href: '/efile-protest-agent',
      icon: <img src={Protest} alt="icon" />,
      userTypes: ['agent'],
    },
    // {
    //   title: 'Account Maintenance',
    //   href: '/account-maintenance',
    //   icon: <img src={AccountMaintenance} alt="icon" />
    // },
    // {
    //   title: 'Renditions (BPP)',
    //   href: '/renditions',
    //   icon: <img src={Renditions} alt="icon" />
    // },
    // {
    //   title: 'CAD Communication',
    //   href: '/cad-communication',
    //   icon: <img src={CadCommunication} alt="icon" />
    // },
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        {/* <Divider className={classes.divider} /> */}
        <SidebarNav
          className={classes.nav}
          pages={pages.filter(p => p.userTypes.includes(userType))}
          allPages={pages}
        />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  userType: PropTypes.string,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
