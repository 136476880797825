/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0,
  },
  item: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    minHeight: 110,
    flexDirection: 'column',

    '& selected': {
      fontWeight: 'bold',
    },
  },
  itemActive: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRight: '10px solid #142bad',
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    cursor: 'pointer',
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
  },
  active: {
    marginLeft: 10,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const SidebarNav = ({ pages, allPages, className, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const activePage = allPages.find(p => location.pathname.startsWith(p.href))

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (
        <ListItem
          className={clsx(
            classes.item,
            activePage.title === page.title && classes.itemActive,
          )}
          disableGutters
          key={page.title}
        >
          <div
            className={activePage.title === page.title
              ? classes.active
              : classes.button
            }
            onClick={() => history.push(page.href)}
          >
            <div className={classes.icon}>{page.icon}</div>
            <div className={classes.icon}>{page.title}</div>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  allPages: PropTypes.array.isRequired,
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
