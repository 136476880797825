import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'

import MaintenanceHeader from 'components/MaintenanceHeader'
import MaintenanceScreen from 'components/MaintenanceScreen'
import { Topbar } from './components'
import { useMaintenance } from 'hooks/useMaintenance'
import Background from 'assets/images/login-background.png'
import Question from 'assets/images/question-circle-duotone.svg'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 28,
    height: '100%',
  },
}))

const Minimal = props => {
  const { children } = props
  const {
    maintenance,
    showMaintenanceProgress,
    showMaintenanceWarning,
  } = useMaintenance()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Topbar />
      {showMaintenanceWarning && (
        <MaintenanceHeader maintenance={maintenance} />
      )}
      {showMaintenanceProgress ? (
        <MaintenanceScreen maintenance={maintenance} />
      ) : (
        <Main>
          <TopSection>
            <div>
              <Title>Public Portal</Title>
              <br />
              <span>
                <SubTitle>For Owners</SubTitle>
                <sup>
                  <Tooltip
                    title={
                      <>
                        <p
                          style={{
                            color: '#fecc3d',
                            fontSize: 12,
                            textAlign: 'center',
                            letterSpacing: 0.4,
                          }}
                        >
                          Video Help
                        </p>
                        <p
                          style={{
                            fontSize: 12,
                            textAlign: 'center',
                            letterSpacing: 0.4,
                          }}
                        >
                          Click "?" icon to watch <br />
                          how to login as an Owner.
                        </p>
                      </>
                    }
                  >
                    <a
                      href="https://vimeo.com/473020598/eae329d2e3"
                      target="_blank"
                    >
                      <img src={Question} />
                    </a>
                  </Tooltip>
                </sup>
                <SubTitle> and Agents</SubTitle>
                <sup>
                  <Tooltip
                    title={
                      <>
                        <p
                          style={{
                            color: '#fecc3d',
                            fontSize: 12,
                            textAlign: 'center',
                            letterSpacing: 0.4,
                          }}
                        >
                          Video Help
                        </p>
                        <p
                          style={{
                            fontSize: 12,
                            textAlign: 'center',
                            letterSpacing: 0.4,
                          }}
                        >
                          Click "?" icon to watch <br />
                          how to login as an Agent.
                        </p>
                      </>
                    }
                  >
                    <a href="https://vimeo.com/474853025" target="_blank">
                      <img src={Question} />
                    </a>
                  </Tooltip>
                </sup>
              </span>
            </div>
            <SectionTitle>Online Services</SectionTitle>
          </TopSection>
          <Overlay />
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </Main>
      )}
    </div>
  )
}

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const Main = styled.main`
  position: relative;
  background: url(${Background});
  background-size: cover;
  min-height: 100%;
  backdrop-filter: blur(30px);
  color: white;
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    0deg,
    #87292f 1%,
    #6d307d 33%,
    #5c137b 53%,
    #2c004a 100%
  );
  opacity: 0.6;
  z-index: 0;
`

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 25%;
  padding: 3em 5em;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
`

const ChildrenWrapper = styled.div`
  position: relative;
`

const Title = styled.span`
  font-size: 40px;
  font-weight: bold;
`

const SubTitle = styled.span`
  font-size: 25px;
  opacity: 0.46;
`

const SectionTitle = styled.span`
  font-size: 25px;
  opacity: 0.46;
`

export default Minimal
