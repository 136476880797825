import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const PopperWindow = ({ anchorEl, close, children, placement = 'right' }) => {
  // const arrowRef = useRef();
  const open = Boolean(anchorEl);

  return (
    <Popper
      id={open ? 'popper' : undefined}
      placement={placement}
      open={open}
      transition
      anchorEl={anchorEl}
      style={{ zIndex: 10000, marginLeft: 20 }}
      // modifiers={{ 
      //   arrow: {
      //     enabled: true,
      //     element: arrowRef,
      //   }
      // }}
    >
      
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <ClickAwayListener onClickAway={close}>
            <PopperContent>
              {/* <PopperArrow ref={arrowRef}/> */}
              {children}
            </PopperContent>
          </ClickAwayListener>
        </Fade>
      )}
    </Popper>
  )
}

PopperWindow.propTypes = {
  anchorEl: PropTypes.any,
  children: PropTypes.any,
  close: PropTypes.func.isRequired,
  placement: PropTypes.string,
}

const PopperContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  background: #fff8d0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

// const PopperArrow = styled.div`
//   border-width: 0 5px 5px 5px;
//   border-color: transparent transparent #fff8d0 transparent;
//   top: -5px;
//   left: calc(50% - 5px);
//   margin-top: 0;
//   margin-bottom: 0;
// `;

export default PopperWindow;
