import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

const FileUpload = ({
  onChange,
  multiple = false,
  additionalMessage,
}) => (
  <Wrapper>
    <Dropzone multiple={multiple} onDrop={onChange}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <Container {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} />
          <UploadIconElement />
          <ActionMessage>Drag and drop files here</ActionMessage>
          {additionalMessage &&
            <ActionMessage>{additionalMessage}</ActionMessage>
          }
          <OrMessage>or</OrMessage>
          <Button color="primary" variant="contained">
            BROWSE FILES
          </Button>
        </Container>
      )}
    </Dropzone>
  </Wrapper>
);

FileUpload.propTypes = {
  additionalMessage: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #e5e9ff;
  cursor: pointer;
  border-radius: 5px;
  height: calc(100% - 40px);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: ${props => (props.isDragActive ? '#e5e9ff80' : '#e5e9ff')};
  border: 2px dashed ${props => (props.isDragActive ? '#142bad80' : '#142bad')};
  border-radius: 3px;
  transition: 0.3s;
  height: 100%;
`;

const ActionMessage = styled.p`
  margin: 8px 0 3px;
  color: #555a6b;
  font-size: 14px;
`;

const OrMessage = styled.p`
  margin: 0 0 10px;
  color: #555a6b;
  font-size: 12px;
`;

const UploadIconElement = styled(UploadIcon)`
  color: #4754a5;
  font-size: 30px !important;
`;

export default FileUpload;
