import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';
import moment from 'moment';

import { StyledCard } from 'components/Styled/Common';
import { ProtestActionMenu, FlexRow } from 'components';
import { getUsdFormat } from 'lib/utils';

const FILING_STATUS_COLORS = {
  // background, color, border
  'Protest Filed': ['#d3d9ff', '#142bad', '#142bad'],
  'Not Filed': ['#f2f2f2', '#000', '#969696'],
  'Protest Complete': ['#d6fdd6', '#000', '#87e887'],
  default: ['#f2f2f2', '#000', '#969696'],
}

const PropertyCard = ({
  property,
  history,
  index,
  // openScheduleDialog,
  openRecommendation,
  onWithdraw,
  style,
}) => {
  return (
    <StyledCard 
      lightBorder={index % 2}
      style={{ paddingTop: 5, ...style }}
    >
      <FlexRow style={{ paddingRight: 5 }}>
        <StatusContainer Status={property.protestState}>
          <label>Filing Status:</label>
          <span>{property.protestState}</span>
        </StatusContainer>
        <ProtestActionMenu
          data={property}
          history={history}
          openRecommendation={openRecommendation}
          withdrawAppeal={onWithdraw}
        />
      </FlexRow>
      <CardContent>
        <ContentWrapper>
          <Column>
            <div>
              <label>Prop ID</label>
              <span
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  fontSize: 'larger',
                }}
              >
                {property.pID}
              </span>
            </div>
            <div>
              <label>Type</label>
              <span>{property.propType}</span>
            </div>
            <div>
              <label>Owner Name</label>
              <span>{property.name}</span>
            </div>
            <div>
              <label>Legal Description</label>
              <span>{property.legalDescription}</span>
            </div>
            <div>
              <label>Situs Address</label>
              <span>{property.streetPrimary}</span>
            </div>
          </Column>
          <Column>
            <div>
              <label>Protest ID</label>
              <span>{property.appealID}</span>
            </div>
            <div>
              <label>DBA</label>
              <span>{property.dba}</span>
            </div>
            <div>
              <label>PIN</label>
              <span>{`${property.pin} - ${property.pinStatus}`}</span>
            </div>
            <div>
              <label>Status</label>
              <span>{property.appealStatus}</span>
            </div>
          </Column>
          <Column>
            <div>
              <label>Notice Value</label>
              <span>{getUsdFormat(property.noticeMarketValue, 0)}</span>
            </div>
            <div>
              <label>Opinion of Value</label>
              <span>{getUsdFormat(property.claimantOpinionOfValue, 0)}</span>
            </div>
            <div>
              <label>Comments</label>
              <span>{property.claimantComments}</span>
            </div>
            <div>
              <label>Hearing Date</label>
              <span style={{ fontWeight: 'bold' }}>
                {property.docketStartTime &&
                  moment(property.docketStartTime, 'YYYY-MM-DD HH:mm:ss')
                    .format('MM/DD/YYYY h:mma')
                }
              </span>
            </div>
            <div>
              <label>Protest Deadline</label>
              <span style={{ color: '#c44445' }}>
                {property.protestDeadlineDt &&
                  moment(property.protestDeadlineDt, 'YYYY-MM-DD HH:mm:ss')
                    .format('MM/DD/YYYY h:mma')
                }
              </span>
            </div>
          </Column>
          
        </ContentWrapper>
        {!!property.appealFinalValue &&
            property.appealFinalValue !== 'N/A' &&
            !isNaN(property.appealFinalValue) &&
            property.pinStatus === 'Valid PIN' && (
          <FlexRow style={{ justifyContent: 'flex-end' }}>
            FINAL VALUE: 
            <span 
              style={{ color: '#142bad', fontWeight: 'bold', marginLeft: 10, fontSize: 'larger' }}
            >
              {getUsdFormat(property.appealFinalValue, 0)}
            </span>
          </FlexRow>
        )}
      </CardContent>
    </StyledCard>
  );
}

PropertyCard.propTypes = {
  history: PropTypes.object,
  index: PropTypes.number,
  onWithdraw: PropTypes.func.isRequired,
  openRecommendation: PropTypes.func.isRequired,
  openScheduleDialog: PropTypes.func.isRequired,
  property: PropTypes.object,
  style: PropTypes.object,
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 35% 23% 35%;
  grid-gap: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > div {
    display: inline-flex;
    margin-bottom: 5px;

    > label {
      min-width: 150px;
    }
    > span {
      color: #142bad;
    }
  }
`;

const StatusContainer = styled.div`
  top: 10px;
  left: 0;
  border-left: 3px solid ${({ Status }) => FILING_STATUS_COLORS[Status]
    ? FILING_STATUS_COLORS[Status][2]
    : FILING_STATUS_COLORS.default[2]};
  background: ${({ Status }) => FILING_STATUS_COLORS[Status]
    ? FILING_STATUS_COLORS[Status][0]
    : FILING_STATUS_COLORS.default[0]};
  display: inline-flex;
  padding: 3px 10px 3px 3px;
  font-size: 0.8em;

  > label {
    min-width: 80px;
  }
  > span {
    color: ${({ Status }) => FILING_STATUS_COLORS[Status]
    ? FILING_STATUS_COLORS[Status][1]
    : FILING_STATUS_COLORS.default[1]};
  }
`;

export default PropertyCard;
