import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useRouter } from 'hooks/useRouter';
import { breadcrumbSpec } from './breadCrumbSpec';

const BreadCrumb = ({ userType }) => {
  const router = useRouter();

  return breadcrumbSpec[router.match.path]
    ? (
      <div style={{ fontFamily: 'OpenSans, arial, sans-serif', fontSize: 12 }}>
        {breadcrumbSpec[router.match.path].map(item =>
          <React.Fragment key={item.label}>
            {item.path 
              ? <>
                <Link
                  to={typeof item.path === 'string' ? item.path : item.path[userType]}
                  style={{ color: 'white' }}
                >
                  {typeof item.label === 'string' ? item.label : item.label[userType]}
                </Link>
                <span> > </span>
              </>
              : <span>{item.label}</span>
            }
          </React.Fragment>
        )}
      </div>
    ) : (
      <div/>
    )
}

BreadCrumb.propTypes = {
  userType: PropTypes.string,
}

export default BreadCrumb;