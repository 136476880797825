import { get } from './request'

export const lookupOffice = async ({ apiUrl }) => {
  const url = window.location.href.match(/^(?:.*:\/\/)?([^:\/]*).*$/)[1]
  const domain = url === 'localhost' ? 'dev.prodigycad.com' : url
  const { body } = await get(
    null,
    `${apiUrl}/trueprodigy/officelookup/${domain}`
  )
  return body
}

export const fetchConfig = async ({ apiUrl }, token) => {
  const { body } = await get(token, `${apiUrl}/public/config`)
  if (body && body.results) {
    localStorage.setItem('SessionConfig', JSON.stringify(body.results))
  }
  return body
}

export const fetchMaintenanceSchedule = async (
  { maintenanceApiUrl },
  _,
  { office }
) => {
  const { body } = await get(
    null,
    `${maintenanceApiUrl}/trueprodigy/maintenanceschedule/${office}`
  )
  return body
}

export async function fetchCodeFile({ apiUrl }, token, { year, type, name }) {
  let url = `${apiUrl}/public/codes?type=${type}&name=${name}`
  url += year ? `&year=${year}` : ''
  const { body } = await get(token, url)
  return body
}

export async function fetchAgentSummary({ apiUrl }, token, { agentId }) {
  let url = `${apiUrl}/public/agent/${agentId}/summary`
  const { body } = await get(token, url)
  return body
}

export const fetchProtestStatusList = async ({ apiUrl }, token) => {
  const url = `${apiUrl}/public/config/proteststatus`
  const { body } = await get(token, url)
  return body
}

export const fetchAgentStatus = async ({ apiUrl }, token) => {
  const url = `${apiUrl}/public/config/propertyagentstatus`
  const { body } = await get(token, url)
  return body
}
