import React from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar, Hidden, IconButton, Tooltip } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input'

import BreadCrumb from './BreadCrumb'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    boxShadow: 'none',
    background: '#2c3442',
    height: 28,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}))

const Topbar = ({ className, onSidebarOpen, logout, userType, ...rest }) => {
  const classes = useStyles()
  // const [notifications] = useState([]);
  const history = useHistory()

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar style={{ minHeight: 28 }}>
        {/* <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo--white.svg"
          />
        </RouterLink> */}
        <BreadCrumb userType={userType} />
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Tooltip title="Log Out">
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={() => {
                logout()
                history.push('/sign-in')
              }}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden mdUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  logout: PropTypes.func.isRequired,
  onSidebarOpen: PropTypes.func,
  userType: PropTypes.string,
}

export default Topbar
