import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContentSection = ({ style, children }) => (
  <Container style={style}>
    {children}
  </Container>
);

ContentSection.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  title: PropTypes.string,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  background: #fff;
  padding: 15px 32px;
  border-top: 6px solid #acbad3;

  h2 {
    font-size: 15px;
    font-weight: bold;
  }
`;

export default ContentSection;
