import * as api from '../api/user';
import { createRequestTypes, START, SUCCESS, ERROR } from './global';
import { urlsSelector, officeSelector } from '../selectors/global';
import { getUrlBase } from 'lib/utils';

const prefix = 'PUBLIC_PORTAL';

export const GET_TOKEN = createRequestTypes(`${prefix}/GET_TOKEN`);

export const getToken = () => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_TOKEN[START] });
    try {
      const state = getState();
      const { authApiUrl } = urlsSelector(state);
      const office = officeSelector(state);
      const data = await api.getToken(authApiUrl, { office });
      if (data.error) {
        throw new Error('getting token failed');
      }
      dispatch({ data, type: GET_TOKEN[SUCCESS] });
      if (localStorage) {
        localStorage.setItem('token', JSON.stringify(data));
      }
      return data;
    } catch (error) {
      dispatch({ error, type: GET_TOKEN[ERROR] });
      // throw error;
    }
  };
}

export const LOGIN = createRequestTypes(`${prefix}/LOGIN`);

export const login = ({ username, password }) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOGIN[START] });
    try {
      const state = getState();
      const { authApiUrl } = urlsSelector(state);
      const office = officeSelector(state);
      const data = await api.login(authApiUrl, { office, username, password });
      if (data.error) {
        throw new Error('Login failed');
      }
      if (data.user.agentID) {
        const { results } = await api.fetchAgentInfo(
          authApiUrl,
          data.user.token,
          data.user.agentID,
        );
        data.user.companyName = results[0].companyName;
      }
      dispatch({ data, type: LOGIN[SUCCESS] });
      if (localStorage) {
        localStorage.setItem('TPToken', JSON.stringify(data));
      }
      return data;
    } catch (error) {
      dispatch({ error, type: LOGIN[ERROR] });
      throw error;
    }
  };
}


export const LOGOUT = createRequestTypes(`${prefix}/LOGOUT`);

export const logout = () => {
  localStorage.removeItem('TPToken');
  localStorage.removeItem('SessionConfig');
  return { type: LOGOUT[SUCCESS] };
}

export const REGISTER = createRequestTypes(`${prefix}/REGISTER`);

export const signup = params => {
  return async (dispatch, getState) => {
    dispatch({ type: REGISTER[START] });
    try {
      const state = getState();
      const { authApiUrl } = urlsSelector(state);
      const officeName = officeSelector(state);
      const token = await dispatch(getToken())
      const url = getUrlBase();

      const data = await api.createPublicUser(
        authApiUrl, 
        token,
        { officeName, url, ...params },
      );
      if (data.error) throw new Error('Signup failed');
      dispatch({ data, type: REGISTER[SUCCESS] });
      if (localStorage) {
        localStorage.setItem('token', JSON.stringify(data));
      }
      return data;
    } catch (error) {
      dispatch({ error, type: REGISTER[ERROR] });
      throw error;
    }
  };
}

export const FETCH_LEGAL_STATEMENT = createRequestTypes(`${prefix}/FETCH_LEGAL_STATEMENT`);

export const fetchLegalStatement = () => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_LEGAL_STATEMENT[START] });
    try {
      const state = getState();
      const { apiUrl } = urlsSelector(state);
      const token = await dispatch(getToken())
      const data = await api.fetchLegalStatement(apiUrl, token);
      if (data.error) {
        throw new Error('getting statement failed');
      }
      dispatch({ data, type: FETCH_LEGAL_STATEMENT[SUCCESS] });
      return data;
    } catch (error) {
      dispatch({ error, type: FETCH_LEGAL_STATEMENT[ERROR] });
      throw error;
    }
  };
}

export const VALIDATE_PUBLIC_USER = createRequestTypes(`${prefix}/VALIDATE_PUBLIC_USER`);

export const vaildatePublicUser = token => {
  return async (dispatch, getState) => {
    dispatch({ type: VALIDATE_PUBLIC_USER[START] });
    try {
      const state = getState();
      const { authApiUrl } = urlsSelector(state);
      const data = await api.validatePublicUser(authApiUrl, token);
      if (data.error) {
        throw new Error('validating user failed');
      }
      dispatch({ data, type: VALIDATE_PUBLIC_USER[SUCCESS] });

      return data;
    } catch (error) {
      dispatch({ error, type: VALIDATE_PUBLIC_USER[ERROR] });
      throw error;
    }
  };
}

export const SEND_PASSWORD_RESET = createRequestTypes(`${prefix}/SEND_PASSWORD_RESET`);

export const sendPasswordReset = email => {
  return async (dispatch, getState) => {
    dispatch({ type: SEND_PASSWORD_RESET[START] });
    try {
      const state = getState();
      const { authApiUrl } = urlsSelector(state);
      const office = officeSelector(state);
      const url = `${getUrlBase()}/reset-password`;
      const data = await api.sendPasswordReset(authApiUrl, { email, url, office });
      if (data.error) {
        throw new Error('validating user failed');
      }
      dispatch({ data, type: SEND_PASSWORD_RESET[SUCCESS] });

      return data;
    } catch (error) {
      dispatch({ error, type: SEND_PASSWORD_RESET[ERROR] });
      throw error;
    }
  };
}

export const SET_PASSWORD = createRequestTypes(`${prefix}/SET_PASSWORD`);

export const setPassword = (token, password) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PASSWORD[START] });
    try {
      const state = getState();
      const { authApiUrl } = urlsSelector(state);
      const data = await api.setPassword(authApiUrl, token, password);
      if (data && data.error) {
        throw new Error('validating user failed');
      }
      dispatch({ data, type: SET_PASSWORD[SUCCESS] });

      return data;
    } catch (error) {
      dispatch({ error, type: SET_PASSWORD[ERROR] });
      throw error;
    }
  };
}