import React, { useState, useEffect } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
// import PropTypes from 'prop-types';
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'

import { FlexRow } from 'components/Styled/Common'
import { useUserActions, useGlobalActions } from '../../hooks/useActions'
import { useQuery } from 'hooks/useQuery'
import { TransparentTextInput } from 'components'
import RoundedButton from 'components/Buttons/RoundedButton'
import { Alert } from 'components'
// import FacebookIcon from 'assets/images/facebook-f-brands.svg';
// import GoogleIcon from 'assets/images/google-brands.svg';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
}

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    color: 'white',
    marginTop: theme.spacing(3),
    borderBottom: '2px solid #fecc3d',
  },
}))

const SignIn = () => {
  const [rememberMe, setRememberMe] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [pwdResetSuccess, setPwdResetSuccess] = useState(false)
  const userActions = useUserActions([])
  const globalActions = useGlobalActions([])
  const history = useHistory()
  const query = useQuery()
  const classes = useStyles()
  const theme = useTheme()

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  const setEmail = email =>
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        email,
      },
    })

  useEffect(() => {
    const ppuser = localStorage.getItem('ppuser')
    if (ppuser) {
      setEmail(ppuser)
      setRememberMe(true)
    }
  }, [])

  useEffect(() => {
    if (query.has('validated') && query.has('email') && !alertOpen) {
      setAlertOpen(true)
      setEmail(query.get('email'))
    }
  }, [query])

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSignIn = async event => {
    event.preventDefault()
    if (rememberMe) {
      localStorage.setItem('ppuser', formState.values.email)
    } else {
      localStorage.removeItem('ppuser')
    }
    try {
      if (!showForgotPassword) {
        await userActions.login({
          username: formState.values.email,
          password: formState.values.password,
        })
        await globalActions.fetchConfig()
        history.push('/home')
      } else {
        await userActions.sendPasswordReset(formState.values.email)
        setPwdResetSuccess(true)
      }
    } catch (err) {
      console.log(err)
      setErrorOpen(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlertOpen(false)
  }

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return
    setErrorOpen(false)
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <div className={classes.content}>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          User has been successfully validated. Please login.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert onClose={handleCloseError} severity="error">
          Invalid user name or password entered.
        </Alert>
      </Snackbar>
      <div className={classes.contentBody}>
        {!pwdResetSuccess ? (
          <form className={classes.form} onSubmit={handleSignIn}>
            <Typography
              className={classes.title}
              variant="h2"
              style={{ maxWidth: showForgotPassword ? 240 : 90 }}
            >
              {showForgotPassword ? 'Forgot Password?' : 'Login'}
            </Typography>
            {!showForgotPassword ? (
              <>
                <TransparentTextInput
                  fullWidth
                  error={hasError('email')}
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                />
                <TransparentTextInput
                  fullWidth
                  placeholder="Password"
                  error={hasError('password')}
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                />
                <FlexRow>
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={v => setRememberMe(v.target.checked)}
                        name="rememberMe"
                        color="primary"
                      />
                    }
                    label="Remember Me"
                  />
                  <StyledRouterLink
                    to="#"
                    onClick={() => setShowForgotPassword(true)}
                  >
                    Forgot password
                  </StyledRouterLink>
                </FlexRow>
                <RoundedButton
                  color="info"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                >
                  Login
                </RoundedButton>
                <FlexRow style={{ justifyContent: 'center' }}>
                  <StyledRouterLink
                    to="#"
                    onClick={() => history.push('/sign-up')}
                  >
                    Create New Login
                  </StyledRouterLink>
                </FlexRow>
              </>
            ) : (
              <>
                <span style={{ fontSize: '1.1em', opacity: 0.7 }}>
                  <br />
                  No worries. Enter the email address you used to register with
                  Public Portal and we will send you a reset password link.
                </span>
                <TransparentTextInput
                  fullWidth
                  error={hasError('email')}
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                />
                <RoundedButton
                  color="info"
                  fullWidth
                  size="large"
                  type="submit"
                >
                  Send
                </RoundedButton>
              </>
            )}
            {/* <FlexRow>
              <FlexRow>
                <Image src={GoogleIcon}/>
                <StyledRouterLink to="#">Login with Google</StyledRouterLink>  
              </FlexRow>
              <FlexRow>
                <Image src={FacebookIcon}/>
                <StyledRouterLink to="#">Login with Facebook</StyledRouterLink>  
              </FlexRow>
            </FlexRow> */}
          </form>
        ) : (
          <SuccessContainer>
            <span style={{ color: theme.palette.info.main }}>
              Account recovery email sent successfully.
            </span>
            <br />
            <br />
            Click the "Reset Password" button in the email we just sent you
            <br />
            <span style={{ fontSize: '0.8em' }}>
              Please check your Spam/Junk folder if you do not see the email in
              your inbox
            </span>
          </SuccessContainer>
        )}
      </div>
    </div>
  )
}

SignIn.propTypes = {}

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiIconButton-label {
    color: white;
    opacity: 0.54;
  }

  .MuiFormControlLabel-label {
    color: white;
  }
`

const StyledRouterLink = styled(RouterLink)`
  color: white;
  text-decoration: underline;
`

// const Image = styled.img`
//   height: 22px;
//   margin: 15px;
// `;

const SuccessContainer = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.3em;
`

export default SignIn
