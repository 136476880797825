import { get, post, put, upload } from './request';

export const insertAppeal = async (
  { appealApiUrl },
  token,
  { payload },
) => {
  const { body } = await post(
    token, 
    `${appealApiUrl}/prodigyappeals/appeals`,
    payload,
  );
  return body;
}

export const fetchFirstAvailableDocketTime = async (
  { appealApiUrl },
  token,
  docketTimeSlotList = [],
) => {
  const { body } = await post(
    token, 
    `${appealApiUrl}/prodigyappeals/appealdockettimeslots/firstavailable`,
    { allowOnlineScheduling: 1, docketTimeSlotList },
  );
  return body;
}

export const fetchAppealDocketTimeSlots = async (
  { appealApiUrl },
  token,
  { beginDt, endDt },
) => {
  const url =
  `${appealApiUrl}/prodigyappeals/appealdockettimeslots?` + 
  `beginDt=${beginDt}&endDt=${endDt}` + 
  '&allowOnlineScheduling=1&agentOnly=0&showCurr=1';
  const { body } = await get(token, url);
  return body;
}

export const scheduleAppeal = async (
  { appealApiUrl },
  token,
  { payload },
) => {
  const url = `${appealApiUrl}/prodigyappeals/appeal/scheduler/individual`;
  const { body } = await post(token, url, payload);
  return body;
}

export const fetchAppealClaimant = async (
  { appealApiUrl },
  token,
  { appealId },
) => {
  const url = `${appealApiUrl}/prodigyappeals/appeal/${appealId}/claimant`;
  const { body } = await get(token, url);
  return body;
}

export async function insertClaimantFile(
  { appealApiUrl },
  authenticationToken,
  { appealId, payload },
) {
  const url = `${appealApiUrl}/prodigyappeals/appeal/${appealId}/claimant/files`;
  const { body } = await upload(authenticationToken, url, payload);
  return body;
}

export const downloadClaimantFile = async (
  { appealApiUrl },
  authenticationToken,
  { appealId, s3Id },
) => {
  const url = `${appealApiUrl}/prodigyappeals/appeal/${appealId}/claimant/file/${s3Id}`;

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const headers = new Headers();
  headers.append('Authorization', authenticationToken);

  return await fetch(url, { headers })
    .then(response => response.blob())
    .then(blobby => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = s3Id;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
};

export async function deleteClaimantFile(
  { appealApiUrl },
  authenticationToken,
  { appealId, s3Id, payload },
) {
  const url = `${appealApiUrl}/prodigyappeals/appeal/${appealId}/claimant/file/${s3Id}`;
  const { body } = await put(authenticationToken, url, payload);
  return body;
}

export async function updateClaimantFileDescription(
  { appealApiUrl },
  authenticationToken,
  { appealId, s3Id, payload },
) {
  const url =
    `${appealApiUrl}/prodigyappeals/appeal/${appealId}/claimant/file/` +
    `${s3Id}/description`;
  const { body } = await put(authenticationToken, url, payload);
  return body;
}

export async function withdrawAppeal(
  { appealApiUrl },
  authenticationToken,
  { appealId },
) {
  const url = `${appealApiUrl}/prodigyappeals/appeal/${appealId}/withdraw`;
  const { body } = await put(authenticationToken, url);
  return body;
}

export const downloadCadPresentation = async (
  { appealApiUrl },
  authenticationToken,
  { appealId, displayInPage },
) => {
  const rand = [...Array(3)].map(() => ((Math.random() * 36) | 0).toString(36)).join``;
  const url = `${appealApiUrl}/prodigyappeals/appeal/${appealId}/presentation/file?${rand}`;
  if (displayInPage) {
    return {
      url,
      httpHeaders: {
        Authorization: authenticationToken,
      },
    }
  }
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  const headers = new Headers();
  headers.append('Authorization', authenticationToken);

  return fetch(url, { headers })
    .then(response => response.blob())
    .then(blobby => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = 'cad-evidence.pdf';
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
};

export const fetchAppealRecommendation = async (
  { appealApiUrl },
  token,
  { recommendationId },
) => {
  const url = `${appealApiUrl}/prodigyappeals/appeal/recommendation/${recommendationId}`;
  const { body } = await get(token, url);
  return body;
}

export const acceptAppealRecommendation = async (
  { appealApiUrl },
  token,
  { recommendationId, lastUpdate },
) => {
  const url =
    `${appealApiUrl}/prodigyappeals/appeal/`+
    `recommendation/${recommendationId}/portalaccept`;
  const { body } = await put(token, url, { lastUpdate });
  return body;
}

export const rejectAppealRecommendation = async (
  { appealApiUrl },
  token,
  { recommendationId, lastUpdate },
) => {
  const url =
    `${appealApiUrl}/prodigyappeals/appeal/`+
    `recommendation/${recommendationId}/reject`;
  const { body } = await put(token, url, { lastUpdate });
  return body;
}

export const setRecommendationPortalView = async (
  { appealApiUrl },
  token,
  { recommendationId },
) => {
  const url =
    `${appealApiUrl}/prodigyappeals/appeal/`+
    `recommendation/${recommendationId}/setportalview`;
  const { body } = await put(token, url);
  return body;
}

export const downloadAppealRecommendation = async (
  { appealApiUrl },
  authenticationToken,
  { recommendationId },
) => {
  const url = 
    `${appealApiUrl}/prodigyappeals/appeal/recommendation/` +
    `${recommendationId}/file`;

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const headers = new Headers();
  headers.append('Authorization', authenticationToken);

  return await fetch(url, { headers })
    .then(response => response.blob())
    .then(blobby => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = 'ProtestRecommendation.pdf';
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
};

export const massCreateAgentProtest = async (
  { appealApiUrl },
  token,
  { agentId, payload },
) => {
  const url =
    `${appealApiUrl}/prodigyappeals/appeal/`+
    `agent/${agentId}/masscreateprotest`;
  const { body } = await post(token, url, payload);
  return body;
}

export const validatePidList = async (
  { appealApiUrl },
  token,
  { pIdList },
) => {
  const url = `${appealApiUrl}/prodigyappeals/appeal/validatelist`;
  const { body } = await post(token, url, { pIDList: pIdList });
  return body;
}

export async function uploadAgentFiles(
  { appealApiUrl },
  authenticationToken,
  { payload },
) {
  const url = `${appealApiUrl}/prodigyappeals/agent/fileupload`;
  const { body } = await upload(authenticationToken, url, payload);
  return body;
}

export const processAgentAoa = async (
  { appealApiUrl },
  token,
  { agentId, payload },
) => {
  const url = `${appealApiUrl}/prodigyappeals/agent/${agentId}/aoa/submit`;
  const { body } = await post(token, url, payload);
  return body;
}

export const revokeAgentAoa = async (
  { appealApiUrl },
  token,
  { agentId, payload },
) => {
  const url = `${appealApiUrl}/prodigyappeals/agent/${agentId}/aoa/revoke`;
  const { body } = await post(token, url, payload);
  return body;
}

export const getMassAoaStatus = async (
  { appealApiUrl },
  token,
  { massId },
) => {
  const url = `${appealApiUrl}/prodigyappeals/agentmassaoa/${massId}/status`;
  const { body } = await get(token, url);
  return body;
}

export const getPortalActivity = async (
  { appealApiUrl },
  token,
  { appealId },
) => {
  const url = `${appealApiUrl}/prodigyappeals/appeal/${appealId}/portalactivity`;
  const { body } = await get(token, url);
  return body;
}

export const downloadPortalActivityFile = async (
  { appealApiUrl },
  authenticationToken,
  { activityId, displayInPage, s3Id },
) => {
  const url =
    `${appealApiUrl}/prodigyappeals/appealactivity/` +
    `${activityId}/file/${s3Id}`;
  if (displayInPage) {
    return {
      url,
      httpHeaders: {
        Authorization: authenticationToken,
      },
    }
  }
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  const headers = new Headers();
  headers.append('Authorization', authenticationToken);

  return fetch(url, { headers })
    .then(response => response.blob())
    .then(blobby => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = 'portal-activity.pdf';
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
};

export const acceptTopline = async (
  { appealApiUrl },
  token,
  { recommendationId, lastUpdate },
) => {
  const url =
    `${appealApiUrl}/prodigyappeals/appeal/`+
    `recommendation/${recommendationId}/portalaccepttopline`;
  const { body } = await put(token, url, { lastUpdate });
  return body;
}

export const massWithdraw = async (
  { appealApiUrl },
  token,
  { appealList, agentId },
) => {
  const { body } = await post(token, `${appealApiUrl}/prodigyappeals/appeal/agent/${agentId}/masswithdrawprotest`, { appealList });
  return body;
}

export const massAccept = async (
  { appealApiUrl },
  token,
  { appealRecommendationList, agentId },
) => {
  const { body } = await post(token, `${appealApiUrl}/prodigyappeals/appeal/agent/${agentId}/massacceptrecommendation`, { appealRecommendationList });
  return body;
}

export const massReject = async (
  { appealApiUrl },
  token,
  { appealRecommendationList, agentId },
) => {
  const { body } = await post(token, `${appealApiUrl}/prodigyappeals/appeal/agent/${agentId}/massrejectrecommendation`, { appealRecommendationList });
  return body;
}

export const massAcceptTop = async (
  { appealApiUrl },
  token,
  { appealRecommendationList, agentId },
) => {
  const { body } = await post(token, `${appealApiUrl}/prodigyappeals/appeal/agent/${agentId}/massaccepttoplinerecommendation`, { appealRecommendationList });
  return body;
}
