import { combineReducers } from 'redux';
import globalReducer from './global';
import userReducer from './user';
import propertiesReducer from './properties';
import appealsReducer from './appeals';

const rootReducer = combineReducers({
  global: globalReducer,
  user: userReducer,
  properties: propertiesReducer,
  appeals: appealsReducer,
})

export default rootReducer;
